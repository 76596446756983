export default {
    methods: {
        GetLibraryCategory: function() {
            this.onloader = "flex";
            this.$store
              .dispatch("libraryCategory/GetLibraryCategory")
              .then(resp => {
                this.LibraryCategoryItems = resp;
                this.onloader = "none";
              })
              .catch(err => {
                this.onloader = "none";
                this.checkresponse(err.response.data);
              });
          },
    },
    mounted () {
      this.GetLibraryCategory()
    }
  }