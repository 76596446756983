<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="pb-6">
        <div class="row">
          <div class="col-md-8">
            <h4 class="text-white">Welcome {{ userName }}!</h4>
            <div v-if="$store.state.auth.user_status_id != 8">
              <small class="text-white"
                ><i class="fa fa-phone"></i> {{ userPhoneNo }} ||
                <i class="fa fa-envelope"></i> {{ userEmailAddress }}</small
              >
              <h4 class="text-white pt-2" v-if="currentterm.SchSession">
                {{ currentterm.SchSession }} - {{ currentterm.SchTerm }}
              </h4>
              <h4 class="text-white">{{ currentterm.Org_Name }}</h4>
            </div>
            <div v-if="$store.state.auth.user_status_id == 8">
              <div class="input-group">
                <label class="input-group-text bg-dark">Your Child</label>
                <select
                  v-model="StudId"
                  class="form-control btn btn-dark"
                  @change="changestudent()"
                >
                  <option value="0"></option>
                  <option
                    v-for="item in studentitem"
                    :key="item.Id"
                    v-bind:value="item.Id"
                  >
                    {{ item.StudentName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div v-if="$store.state.auth.user_status_id == 2" class="col-md-4">
            <img v-if="schlogo" :src="schlogo" alt="" height="100" />
          </div>
        </div>
      </div>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <Studentmenu> </Studentmenu>
          </card>
        </div>

        <div class="col-xl-4">
          <card header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-uppercase text-muted ls-1 mb-1">Daily News</h6>
              </div>
            </div>
          </card>
        </div>
      </div>

      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import Studentmenu from "../Dashboardmenu/Studentmenu.vue";
export default {
  components: {
    Studentmenu,
  },
  props: {
    userName: String,
    userPhoneNo: String,
    userEmailAddress: String,
  },
  data() {
    return {
      currentterm: {},
      userinfo: {},
      StudId: "",
      studentitem: null,
      schlogo: "",
      ExpiredDate: "",
      onloader: "none",
    };
  },
  methods: {
    getcurrentterm: function () {
      if (
        this.$store.state.auth.user_status_id != 8 &&
        this.$store.state.auth.orgid != null
      ) {
        this.onloader = "flex";
        this.$store
          .dispatch("getcurrentterm")
          .then((resp) => {
            this.currentterm = resp;
            this.schlogo=resp
            
            this.onloader = "none";
          })
          .catch((err) => {
            console.log(err);
            this.onloader = "none";
            this.$store.dispatch("logout");
            this.$router.push("/login");
          });
      }
    },    
    getstudentinfo: function () {
      if (
        this.$store.state.auth.user_status_id == 2 &&
        this.$store.state.auth.orgid != null
      ) {
        this.onloader = "flex";
        this.$store
          .dispatch("getstudentbyuserid")
          .then((resp) => {
            if (resp != null) {
              this.StudId = resp.Id;
              localStorage.setItem("StudId", resp.Id);
            }
            this.onloader = "none";
          })
          .catch((err) => {
            console.log(err);
            this.onloader = "none";
          });
      } else if (this.$store.state.auth.user_status_id == 8) {
        this.onloader = "flex";
        this.$store
          .dispatch("getstudentsforguardian")
          .then((resp) => {
            this.studentitem = resp;
            if (resp != null) {
              this.$store
                .dispatch("getstudentsforguardiansingle")
                .then((respp) => {
                  if (respp != null) {
                    this.StudId = respp.Id;
                    localStorage.setItem("StudId", respp.Id);
                  }
                  this.onloader = "none";
                })
                .catch((err) => {
                  console.log(err);
                  this.onloader = "none";
                });
            }
            this.onloader = "none";
          })
          .catch((err) => {
            console.log(err);
            this.onloader = "none";
          });
      }
    },
    changestudent() {
      localStorage.setItem("StudId", this.StudId);
    },
  },
  mounted() {
    this.getcurrentterm();
    this.getstudentinfo();
  },
};
</script>

<style></style>
