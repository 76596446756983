<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 justify-content-center pb-3 text-center"></div>
      <div class="col-lg-6">
        <div class="py-5 text-white bg-dark">
          <div class="text-center text-muted my-3">
            <router-link to="/login" class="nav-link">
              <img src="img/brand/green.png" height="30"
            /></router-link>

            <p class="mt-3">Signup on SchoolDock here</p>
          </div>
          
           <div class="text-center">
            Already registered your school
            <router-link to="/login" class="text-primary"
              >Login here</router-link
            >
          </div>        
          
          <form role="form" @submit.prevent="submitdata" class="p-5">
             
            <div v-if="errmsg" class="alert alert-danger text-center">
              <p>{{ errmsg }}</p>
            </div>
            <div class="row">
              <div class="col-md-6">
                <base-input
                  :class="SchoolNamestyle"
                  placeholder="Enter School Name"
                  addon-left-icon="ni ni ni-single-02"
                  v-model="SchoolNameval"
                >
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input
                  :class="ContactPersonstyle"
                  placeholder="Enter Contact Person"
                  addon-left-icon="ni ni ni-single-02"
                  v-model="ContactPersonval"
                >
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input
                  :class="ContactPhoneNostyle"
                  placeholder="Enter Contact Phone No."
                  addon-left-icon="ni ni ni-mobile-button"
                  v-model="ContactPhoneNoval"
                >
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input
                  :class="ContactEmailstyle"
                  placeholder="Email Address"
                  addon-left-icon="ni ni ni-email-83"
                  v-model="ContactEmailval"
                >
                </base-input>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select
                    v-model="StateIdval"
                    :class="StateIdstyle"
                    @change="getlga()"
                  >
                    <option value="0" selected>- Select State -</option>
                    <option
                      v-for="item in stateitems"
                      :key="item.Id"
                      v-bind:value="item.Id"
                    >
                      {{ item.StateName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select v-model="LgaIdval" :class="LgaIdstyle">
                    <option value="0">- Select LGA -</option>
                    <option
                      v-for="item in lgaitems"
                      :key="item.Id"
                      v-bind:value="item.Id"
                    >
                      {{ item.Local_Govt_Name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <base-input
                    :class="Citystyle"
                    placeholder="Enter City"
                    addon-left-icon="ni ni-bold-right"
                    v-model="Cityval"
                  >
                  </base-input>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">  
                  <textarea v-model="Addressval"  :class="Addressstyle" class="form-control"  placeholder="Enter Address" rows="3"></textarea>       
                </div>
              </div>
            </div>
            <div class="text-center my-2">
              <p class="text-xs">
                Submittin this form means you have accepted our
                <router-link to="/privacy" class="text-xs"
                  >Privacy Policy</router-link
                >
              </p>
            </div>
            <div class="text-center">
              <button type="submit" class="btn btn-primary my-3">Submit <i class="fa fa-arrow-right"></i></button>
            </div>
           
          </form>
        
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import swal from "sweetalert";
export default {
  name: "login", 
  data() {
    return {
      SchoolNameval: "",
      ContactPersonval: "",
      ContactPhoneNoval: "",
      ContactEmailval: "",
      StateIdval: 0,
      LgaIdval: 0,
      Cityval: "",
      Addressval: "",
      lgaitems: null,
      SchoolNamestyle: "input-group-alternative mb-3",
      ContactPersonstyle: "input-group-alternative mb-3",
      ContactPhoneNostyle: "input-group-alternative mb-3",
      ContactEmailstyle: "input-group-alternative mb-3",
      StateIdstyle: "form-control mb-3",
      LgaIdstyle: "form-control mb-3",
      Citystyle: "input-group-alternative mb-3",
      Addressstyle: "input-group-alternative mb-3",
      errmsg: "",
      pwdmsg: "",
      onloader: "none",
    };
  },
  computed: {
    ...mapState({
      stateitems: (state) => state.addressinfo.stateitems,
    }),
  },
  methods: {
    submitdata: function () {
      let msg = "";
      let SchoolName = this.SchoolNameval;
      let ContactPerson = this.ContactPersonval;
      let ContactPhoneNo = this.ContactPhoneNoval;
      let ContactEmail = this.ContactEmailval;
      let StateId = this.StateIdval;
      let LgaId = this.LgaIdval;
      let City = this.Cityval;
      let Address = this.Addressval;
      this.SchoolNamestyle = "input-group-alternative mb-3";
      this.ContactPersonestyle = "input-group-alternative mb-3";
      this.ContactPhoneNostyle = "input-group-alternative mb-3";
      this.ContactEmailstyle = "input-group-alternative mb-3";
      this.StateIdstyle = "form-control mb-3";
      this.LgaIdstyle = "form-control mb-3";
      this.Citystyle = "input-group-alternative mb-3";
      this.Addressstyle = "input-group-alternative mb-3";
      let accesskey = this.$store.state.auth.accesskey;
      if (SchoolName == "" || SchoolName == null) {
        msg = "Enter School Name, ";
        this.SchoolNamestyle = "input-group-alternative mb-3 has-danger";
      }
      if (ContactPerson == "" || ContactPerson == null) {
        msg += "Enter Contact Person, ";
        this.ContactPersonstyle = "input-group-alternative mb-3 has-danger";
      }
      if (ContactPhoneNo == "" || ContactPhoneNo == null) {
        msg += "Enter Contact Phone Number, ";
        this.ContactPhoneNostyle = "input-group-alternative mb-3 has-danger";
      }
      if (ContactEmail == "" || ContactEmail == null) {
        msg += "Enter Contact Email Address, ";
        this.ContactEmailstyle = "input-group-alternative mb-3 has-danger";
      }
      if (StateId < 1) {
        msg += "Select State, ";
        this.StateIdstyle = "form-control mb-3 has-danger";
      }
      if (LgaId < 1) {
        msg += "Select Local Gorvernment, ";
        this.LgaIdstyle = "form-control mb-3 has-danger";
      }
      if (City == "" || City == null) {
        msg += "Enter City ";
        this.Citystyle = "input-group-alternative mb-3 has-danger";
      }
      if (Address == "" || Address == null) {
        msg += "Enter Address ";
        this.Addressstyle = "input-group-alternative mb-3 has-danger";
      }
      if (msg == "") {
        this.onloader = "flex";
        let userdata = {
          SchoolName,
          ContactPerson,
          ContactPhoneNo,
          ContactEmail,
          StateId,
          LgaId,
          City,
          Address,
          accesskey,
        };
        this.$store
          .dispatch("schoolsignup", userdata)
          .then(() => {
            this.ContactPersonval = "";
            this.ContactPhoneNoval = "";
            this.ContactEmailval = "";
            this.StateIdval = 0;
            this.LgaIdval = 0;
            this.Cityval = "";
            this.Addressval = "";
            this.lgaitems = null;
            this.onloader = "none";
            this.$router.push("/login");
            swal({
              title: "Success!",
              text: "Submitted Successfully, we will get back to you soon",
              icon: "success",
              button: "Ok",
            });
          })
          .catch((err) => {
            this.onloader = "none";
            swal({
              title: "Oops!",
              text: err.response.data,
              icon: "error",
              button: "Ok",
            });
          });
      } else {
        this.errmsg = msg;
        swal({
          title: "Oops!",
          text: "One or More Input is/are empty",
          icon: "error",
          button: "Ok",
        });
      }
    },
    getlga: function () {
      this.lgaitems = [];
      let StateId = this.StateIdval;
      if (StateId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getlgas", StateId)
          .then((resp) => {
            this.lgaitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            if (
              err.response.data === "Access Expired" ||
              err.response.data === "Invalid Credential"
            ) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            } else {
              swal({
                title: "Oops!",
                text: err.response.data,
                icon: "error",
                button: "Ok",
              });
            }
          });
      }
    },
  }, 
};
</script>

<style>
</style>
