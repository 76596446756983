<template>
  <div id="loaderoverlay" v-bind:style="{ display: onloader }">
    <div class="w-100 d-flex justify-content-center align-items-center">
      <div class="loaderspinner"></div>
      <h2>Loading...</h2>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    onloader: String
  }
}
</script>

<style></style>
