<template>
<div>
   <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <router-link to="/dashboard" class="text-white pl-3">
           Dashboard</router-link>
        <router-link to="/student" class="text-white">
             <i class="fa fa-ellipsis-v px-2"></i>Student
        </router-link>
        <span class="text-white"> <i class="fa fa-ellipsis-v px-2"></i>Mock Record</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">

        <div class="mb-5 mb-xl-0">
            <card header-classes="bg-transparent">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card bg-secondary border-0 mb-0">
                            <div class="card-body">
                                <div class="form-horizontal">
                                    <form role="form" @submit.prevent="submitdata">
                                        <div class="card bg-gradient-success">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Session</label>
                                                            <select v-model="SessionIdval" class="form-control">
                                                                <option value=""></option>
                                                                <option v-for="item in Sessionitems" :key="item.Id" v-bind:value="item.Id">
                                                                    {{item.SchSession}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Mock</label>
                                                            <select v-model="MockIdval" class="form-control" @change=" getoption()">
                                                                <option value="0"></option>
                                                                <option v-for="item in mocks" :key="item.Id" v-bind:value="item.Id">
                                                                    {{item.ExamTitle}} ({{item.Sch_Class}})
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Option</label>
                                                            <select v-model="OptionIdval" class="form-control" @change=" getmocksubject()">
                                                                <option value="0"></option>
                                                                <option v-for="item in optionitems" :key="item.Id" v-bind:value="item.Id">
                                                                    {{item.ClassOption}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Subject</label>
                                                            <select v-model="SubjectIdval" class="form-control" @change="getmockrecord()">
                                                                <option value="0"></option>
                                                                <option v-for="item in subjectitems" :key="item.Id" v-bind:value="item.SubjectId">
                                                                    {{item.SubjectName}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Student</label>
                                                            <select v-model="StudIdval" class="form-control" @change="edititem()">
                                                                <option value="0"></option>
                                                                <option v-for="item in studentitems" :key="item.Id" v-bind:value="item.Id">
                                                                    {{item.StudentName}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Score</label>
                                                            <input type="number" min="0" v-model="Scoreval" class="form-control">
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center mb-3">
                                            <button type="submit" class="btn btn-primary my-4">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">

                        <div v-if="mockrecorditem" class="table-responsive mt-2">
                            <table class="table table-bordered table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Admission Number</th>
                                        <th>Student Name</th>
                                        <th>Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in mockrecorditem" :key="item.Id">
                                        <td>{{ item.AdmNo }}</td>
                                        <td>{{ item.StudentName }}</td>
                                        <td>{{ item.Score }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </card>
        </div>
        <!-- End charts-->
    </div>
</div>
</template>

<script>
import moment from 'moment'
import swal from 'sweetalert'
import PageSpinner from "@/components/PageSpinner"
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin"
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin"
export default {
    name: 'mockrecord',
    mixins: [CheckApiResponse, CheckStaffUser],
    components: {
        PageSpinner
    },
    data() {
        return {
            MockIdval: '',
            SessionIdval: '',
            StudIdval: '',
            OptionIdval: '',
            SubjectIdval: '',
            Scoreval: '',
            Sessionitems: null,
            subjectitems: null,
            optionitems: null,
            studentitems: null,
            mockrecorditem: null,
            mocks: null,
            onloader: 'none'
        }
    },

    methods: {
        edititem: function () {
            let MockId = this.MockIdval;
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let Score = 1;
            let SubjectId = this.SubjectIdval;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            this.Scoreval = '';
            let userdata = {
                MockId,
                Score,
                SubjectId,
                StudId,
                SessionId,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('getmockrecordbyid', userdata)
                .then(resp => {

                    this.Scoreval = resp.Score;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        submitdata: function () {
            let MockId = this.MockIdval;
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let Score = this.Scoreval;
            let SubjectId = this.SubjectIdval;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                MockId,
                Score,
                SubjectId,
                StudId,
                SessionId,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('mockrecordregistration', userdata)
                .then((resp) => {
                    this.mockrecorditem = resp;
                    this.onloader = 'none'
                    swal({
                        title: "Success!",
                        text: 'Submitted successfully',
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getmockrecord: function () {
            let MockId = this.MockIdval;
            let StudId = 1;
            let SessionId = this.SessionIdval;
            let Score = 1;
            let SubjectId = this.SubjectIdval;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            this.Scoreval = '';
            let userdata = {
                MockId,
                Score,
                SubjectId,
                StudId,
                SessionId,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('getmockrecord', userdata)
                .then((resp) => {
                    this.mockrecorditem = resp;
                    this.getstudent();
                    this.onloader = 'none'
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getregsession: function () {
            this.studentitems = null;
            this.Sessionitems = null;
            this.optionitems = null;
            this.subjectitems = null;
            this.mockrecorditem = null;
            this.SessionIdval = 0;
            this.onloader = 'flex'
            this.$store.dispatch('getregsession')
                .then(resp => {
                    this.Sessionitems = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    console.log(err);
                    this.onloader = 'none'
                    this.$store.dispatch('logout')
                    this.$router.push('/home')
                })
        },
        getoption: function () {
            this.onloader = 'flex';
            this.mockrecorditem = null;
            this.subjectitems = null;
            let val = this.MockIdval;
            this.$store.dispatch('getoptionbymock', val)
                .then((resp) => {
                    this.optionitems = resp;
                    this.onloader = 'none';
                    //console.log(resp)
                })
                .catch(err => {
                    this.onloader = 'none';
                    this.checkresponse(err.response.data);
                })
        },
        getmocksubject: function () {
            this.mockrecorditem = null;
            this.subjectitems = null;
            let SubjectId = 1;
            let MockId = this.MockIdval;
            let OptionId = this.OptionIdval;
            let processop = 'U';
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let payload = {
                SubjectId,
                MockId,
                OptionId,
                processop,
                username,
                accesskey
            }
            this.onloader = 'flex';
            this.$store.dispatch('getmocksubject', payload)
                .then((resp) => {
                    this.subjectitems = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none';
                    this.checkresponse(err.response.data);
                })
        },
        getstudent: function () {
            let MockId = this.MockIdval;
            if (MockId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('getstudentformock', MockId)
                    .then(resp => {
                        this.studentitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getmock: function () {
            this.onloader = 'flex'
            this.$store.dispatch('getmock')
                .then(resp => {
                    this.mocks = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        frontEndDateFormat: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
        backEndDateFormat: function (date) {
            return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }

    },
    mounted() {
        if (this.$store.state.auth.user_status_id != 1) {
            this.$store.dispatch('logout')
            this.$router.push('/login')
        } else {
            this.getmock();
            this.getregsession();
        }
    }
};
</script>

<style></style>
