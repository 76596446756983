<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white pl-3">
                Dashboard
            </router-link>
            <router-link to="/student" class="text-white">
                <i class="fa fa-ellipsis-v px-2"></i>Student
            </router-link>
            <span class="text-white form-inline"><i class="fa fa-ellipsis-v px-2"></i>Conduct Assessment <i class="fa fa-arrows-alt-v px-2"></i><select
                    v-model="TermType" class="custom-select-sm bg-secondary mx-2">
                    <option value="1">Full Term</option>
                    <option value="2">Mid Term</option>
                </select></span>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">
            <div class="mb-5 mb-xl-0">
                <FullTerm v-if="TermType == 1"></FullTerm>
                 <MidTerm v-if="TermType == 2"></MidTerm>
            </div>
        </div>
    </div>
</template>

<script>
import FullTerm from './ConductAssessment/FullTerm.vue'
import MidTerm from './ConductAssessment/MidTerm.vue'

import CheckStaffUser from "@/mixins/CheckStaffUser.mixin"
export default {
    name: 'classattendance',
    mixins: [CheckStaffUser],
    components: {
        FullTerm,
        MidTerm
    },
    data() {
        return {
            TermType: 1,
        }
    },
};
</script>