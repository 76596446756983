<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link><i class="fa fa-ellipsis-v px-2"></i>
      <router-link to="/reportmenu" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Report
      </router-link>
      <span class="text-white"><i class="ni ni-bold-right"></i> Broadsheet (Full Term)</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body">
              <div class="form-inline">
                <div class="row">
                  <div class="col-md-4 pt-3">
                    <div class="input-group">
                      <label class="input-group-text">Session</label>
                      <select v-model="SessionIdval" class="form-control btn btn-success" @change="getregterm($event)">
                        <option value=""></option>
                        <option v-for="item in Sessionitems" :key="item.Id" v-bind:value="item.Id">
                          {{ item.SchSession }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4 pt-3">
                    <div class="input-group">
                      <label class="input-group-text">Term</label>
                      <select v-model="TermIdval" @change="getclass()" class="form-control btn btn-success">
                        <option value="0"></option>
                        <option v-for="item in termitems" :key="item.Id" v-bind:value="item.Id">
                          {{ item.SchTerm }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4 pt-3">
                    <div class="input-group">
                      <span class="input-group-text">Class</span>
                      <select v-model="ClassIdval" class="form-control btn btn-success" @change="getoption($event)">
                        <option value="0"></option>
                        <option v-for="item in classitems" :key="item.Id" v-bind:value="item.Id">
                          {{ item.Sch_Class }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4 pt-3">
                    <div class="input-group">
                      <span class="input-group-text">Option</span>
                      <select v-model="OptionIdval" class="form-control btn btn-success" @change="getreport()">
                        <option value="0"></option>
                        <option v-for="item in optionitems" :key="item.Id" v-bind:value="item.Id">
                          {{ item.ClassOption }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="report != null">
            <div id="divReport">
              <div v-html="report"></div>
            </div>
            <div class="text-center my-3">
              <button class="btn btn-default" @click.prevent="printreport()">
                <i class="fa fa-print"></i> Print
              </button>
            </div>
          </div>
        </card>
      </div>

      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment"
import swal from "sweetalert"
import PageSpinner from "@/components/PageSpinner"
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin"
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin"
export default {
  name: "Broadsheet",
  mixins: [CheckApiResponse, CheckStaffUser],
  components: {
    PageSpinner
  },
  data() {
    return {
      dataid: 1,
      SessionIdval: "",
      TermIdval: "",
      ClassIdval: "",
      OptionIdval: "",
      Sessionitems: null,
      termitems: null,
      classitems: null,
      optionitems: null,
      report: null,
      currdate: null,
      process: "N",
      onloader: "none",
    };
  },
  methods: {
    getcurendate: function () {
      var today = new Date();
      this.currdate =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();
    },
    printreport: function () {
      // Get HTML to print from element

      var printContents = document.getElementById("divReport").innerHTML;
      var originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
    },
    getreport: function () {
      if (this.OptionIdval > 0) {
        let SessionId = this.SessionIdval;
        let TermId = this.TermIdval;
        let ClassId = this.ClassIdval;
        let OptionId = this.OptionIdval;
        let userdata = {
          ClassId,
          OptionId,
          SessionId,
          TermId,
        };
        this.onloader = "flex";
        this.$store
          .dispatch("getbroadsheet", userdata)
          .then((resp) => {
            this.onloader = "none";
            if (resp != null) {
             // window.open(this.$store.state.auth.baseurl + resp, "_blank");
              const url = this.$store.state.auth.apibaseurl + resp;
              console.log(url)

              const link = document.createElement('a')
              link.href = url
              link.setAttribute(
                'download',
                `${new Date().toLocaleDateString()}.xlsx`
              )
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link);
            }
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getregsession: function () {
      this.studentitems = null;
      this.termitems = null;
      this.optionitems = null;
      this.Sessionitems = null;
      this.SessionIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getregsession")
        .then((resp) => {
          this.Sessionitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/home");
        });
    },
    getregterm: function () {
      this.classitems = null;
      this.studentitems = null;
      this.termitems = null;
      this.optionitems = null;
      this.report = null;
      this.TermIdval = 0;
      let SessionId = this.SessionIdval;
      if (SessionId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getregterm", SessionId)
          .then((resp) => {
            this.termitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getclass: function () {
      this.classitems = null;
      this.studentitems = null;
      this.optionitems = null;
      this.ClassIdval = 0;
      this.report = null;
      if (this.TermIdval > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getclass")
          .then((resp) => {
            this.classitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            console.log(err);
            this.onloader = "none";
            this.$store.dispatch("logout");
            this.$router.push("/home");
          });
      }
    },
    getoption: function (event) {
      this.studentitems = null;
      this.optionitems = null;
      this.report = null;
      this.OptionIdval = 0;
      let ClassId = event.target.value;
      if (ClassId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getoption", ClassId)
          .then((resp) => {
            this.optionitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.auth.user_status_id != 1) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    } else {
      this.getregsession();
      this.getcurendate();
    }
  },
};
</script>

<style>
.borderall {
  text-align: center;
  border: 3px solid #000;
}

.bordercol {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  border: thin solid #000;
}

.padbody {
  padding: 20px;
}

.padhead {
  padding: 5px;
  border: 3px solid #606060;
}

.pad {
  padding: 10px;
}

table {
  font-size: 9pt;
  width: 100%;
}

.bodytext {
  font-size: 9pt;
}
</style>
