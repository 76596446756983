export default {
    methods: {
        GetHostelStudentBed: function() {
            this.onloader = "flex";
            this.$store
              .dispatch("hostelStudentBed/GetHostelStudentBed")
              .then(resp => {
                this.HostelStudentBedItems = resp;
                this.onloader = "none";
              })
              .catch(err => {
                this.onloader = "none";
                this.checkresponse(err.response.data);
              });
          },
    },
    mounted () {
      this.GetHostelStudentBed()
    }
  }