<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      ><i class="ni ni-bold-right text-white"></i>
      <router-link to="/exammenu" class="text-white">
        <i class="ni ni-badge"></i> CBT
      </router-link>
      <span class="text-white"
        ><i class="ni ni-bold-right"></i>CBT Result Summary
      </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body">
              <div v-html="resultItems"></div>
              <hr/>
              <div class="text-center" v-if="resultItems">
               <router-link to="/cbtresultdetails" class="btn btn-dark"
              > SEE ALL ANSWERS <i class="fa fa-arrow-right"></i> </router-link
            >  <router-link to="/studentcbtexam" class="btn btn-success"
              > TAKE NEW TEST <i class="fa fa-arrow-right"></i> </router-link
            >
              </div>
            </div>
          </div>
        </card>
      </div>

      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
export default {
  name: "exam",
  data() {
    return {
      dataid: 1,
      ExamCodeval: localStorage.getItem("ExamCode") || "",
      resultItems:null,     
      onloader: "none",
    };
  },
  methods: {    
    getresultsummary: function () {    
        if(this.ExamCodeval==null){
            this.$router.push("/cbtprep");
        }else{
            let ExamCode = this.ExamCodeval;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userData = {        
                ExamCode,
                username,
                accesskey,
            };

            this.onloader = "flex";
            this.$store
                .dispatch("getresultsummary", userData)
                .then((resp) => {
                    this.resultItems = resp;          
                    this.onloader = "none";
                })
                .catch(() => {
                    this.onloader = "none";
                    this.$router.push("/cbtprep");
                });
        }      
    },
    
    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.auth.user_status_id == 2) {
      this.getresultsummary();
    } else {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    }
  },
};
</script>

<style></style>
