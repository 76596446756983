<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 justify-content-center pb-3 text-center"></div>
      <div class="col-lg-4">
        <div class="px-5 py-5 text-white bg-dark">
          <div class="text-center text-muted my-3">           
            <p class="mt-3">Submit details below to change your password</p>
          </div>
          <form role="form" @submit.prevent="userdata">
            <base-input
              class="input-group-alternative"
              placeholder="New Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="newpasswordval"
            >
            </base-input>

            <base-input
              class="input-group-alternative"
              placeholder="Retype Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="retypepasswordval"
            >
            </base-input>

            <div class="text-center">
              <button type="submit" class="my-4 btn btn-primary">
                Submit <i class="fa fa-arrow-right"></i>
              </button>
            </div>
          </form>
          <div class="text-center pt-2">
            <router-link to="/login" class="text-primary"
              ><small>Back to Login</small></router-link
            >
          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import swal from "sweetalert";
export default {
  name: "changepassword",
   components: {
   
  },
  data() {
    return {
      username: "",
      retypepasswordval: "",
      newpasswordval: "",
      errormsg: "",
      onloader: "none",
    };
  },
  methods: {
    userdata: function () {
      let username = this.$store.state.auth.username;
      let retypepassword = this.retypepasswordval;
      let newpassword = this.newpasswordval;    
      if (retypepassword == newpassword) {
        this.onloader = "flex";
        this.$store
          .dispatch("changenewpassword", {newpassword,username})
          .then(() => {
            this.onloader = "none";
            this.$router.push("/login");
            swal({
              title: "Success!",
              text:
                "Your password has been change successfuly, you can now login with new password",
              icon: "success",
              button: "Ok",
            });
          })
          .catch((err) => {
            this.onloader = "none";
            swal({
              title: "Oops!",
              text: err.response.data,
              icon: "error",
              button: "Ok",
            });
          });
      } else {
        swal({
          title: "Oops!",
          text: "Password Mismatched",
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
};
</script>

<style>
</style>
