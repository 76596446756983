<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 justify-content-center pb-3 text-center"></div>
      <div class="col-lg-6">
        <div class="py-5 text-white bg-dark">
          <div class="text-center text-muted my-3">
            <router-link to="/login" class="nav-link">
              <img src="img/brand/green.png" height="30"
            /></router-link>

            <p class="mt-3">Signup on SchoolDock here</p>
          </div>
          <div class="text-center">
            <div class="btn-group btn-group-toggle">
              <router-link to="/register" class="btn btn-outline-success active"
                ><i class="ni ni-circle-08"></i> I'm Student</router-link
              >
              <router-link to="/registersch" class="btn btn-outline-success"
                ><i class="ni ni-building"></i> I'm School Owner</router-link
              >
            </div>
          </div>
           <div class="text-center pt-3">
            Already registered
            <router-link to="/login" class="text-primary"
              >Login here</router-link
            >
          </div>   
          <hr />
          <form role="form" @submit.prevent="submitdata" class="px-5">
            <div v-if="errmsg" class="alert alert-danger text-center">
              <p>{{ errmsg }}</p>
            </div>
            <div class="row">
              <div class="col-md-6">
                <base-input
                  :class="Firstnamestyle"
                  placeholder="Enter First Name"
                  addon-left-icon="ni ni ni-single-02"
                  v-model="Firstnameval"
                >
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input
                  :class="Lastnamestyle"
                  placeholder="Enter Last Name"
                  addon-left-icon="ni ni ni-single-02"
                  v-model="Lastnameval"
                >
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input
                  :class="Phonenostyle"
                  placeholder="Enter Phone No."
                  addon-left-icon="ni ni ni-mobile-button"
                  v-model="Phonenoval"
                >
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input
                  :class="Emailstyle"
                  placeholder="Email Address"
                  addon-left-icon="ni ni ni-email-83"
                  v-model="EmailAddval"
                >
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input
                  :class="Usernamestyle"
                  placeholder="User Name"
                  addon-left-icon="ni ni ni-badge"
                  v-model="Studusernameval"
                >
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input
                  :class="Passwordstyle"
                  placeholder="Password"
                  type="password"
                  addon-left-icon="ni ni-lock-circle-open"
                  v-model="Pwdval"
                >
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input
                  :class="PasswordRetypestyle"
                  placeholder="Confirm Password:"
                  type="password"
                  addon-left-icon="ni ni-lock-circle-open"
                  v-model="PasswordRetypeval"
                  @change="verifypassword()"
                >
                </base-input
                ><span class="text-danger text-xs">{{ pwdmsg }}</span>
              </div>
            </div>
            <div class="text-center my-2">
              <p class="text-xs">
                Submittin this form means you have accepted our
                <router-link to="/privacy" class="text-xs"
                  >Privacy Policy</router-link
                >
              </p>
            </div>
            <div class="text-center">
              <button type="submit" class="btn btn-primary btn-block my-3">Submit</button>
            </div>
          </form>
                
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";

export default {
  name: "login",
  data() {
    return {
      Studusernameval: "",
      Pwdval: "",
      PasswordRetypeval: "",
      Firstnameval: "",
      Lastnameval: "",
      Phonenoval: "",
      EmailAddval: "",
      Usernamestyle: "input-group-alternative mb-3",
      Passwordstyle: "input-group-alternative mb-3",
      PasswordRetypestyle: "input-group-alternative mb-3",
      Firstnamestyle: "input-group-alternative mb-3",
      Lastnamestyle: "input-group-alternative mb-3",
      Phonenostyle: "input-group-alternative mb-3",
      Emailstyle: "input-group-alternative mb-3",
      errmsg: "",
      pwdmsg: "",
      onloader: "none",
    };
  },
  methods: {
    verifypassword() {
      this.pwdmsg = "";
      if (this.Pwdval != this.PasswordRetypeval) {
        this.pwdmsg = "Password Mismatch";
      }
    },
    submitdata: function () {
      let msg = "";
      let Username = this.Studusernameval;
      let Password = this.Pwdval;
      let PasswordRetype = this.PasswordRetypeval;
      let Firstname = this.Firstnameval;
      let Lastname = this.Lastnameval;
      let Phoneno = this.Phonenoval;
      let Email = this.EmailAddval;
      this.Usernamestyle = "input-group-alternative mb-3";
      this.PasswordRetypeval = "input-group-alternative mb-3";
      this.PasswordRetypestyle = "input-group-alternative mb-3";
      this.Firstnamestyle = "input-group-alternative mb-3";
      this.Lastnamestyle = "input-group-alternative mb-3";
      this.Phonenostyle = "input-group-alternative mb-3";
      this.Emailstyle = "input-group-alternative mb-3";
      let accesskey = this.$store.state.auth.accesskey;
      if (Username == "" || Username == null) {
        msg = "Enter Username, ";
        this.Usernamestyle = "input-group-alternative mb-3 has-danger";
      }
      if (Password == "" || Password == null) {
        msg += "Enter Password, ";
        this.Passwordstyle = "input-group-alternative mb-3 has-danger";
      }
      if (PasswordRetype == "" || PasswordRetype == null) {
        msg += "Retype Password, ";
        this.PasswordRetypestyle = "input-group-alternative mb-3 has-danger";
      }
      if (Firstname == "" || Firstname == null) {
        msg += "Enter First Name, ";
        this.Firstnamestyle = "input-group-alternative mb-3 has-danger";
      }
      if (Lastname == "" || Lastname == null) {
        msg += "Enter Last Name, ";
        this.Lastnamestyle = "input-group-alternative mb-3 has-danger";
      }
      if (Phoneno == "" || Phoneno == null) {
        msg += "Enter Phone Number, ";
        this.Phonenostyle = "input-group-alternative mb-3 has-danger";
      }
      if (Email == "" || Email == null) {
        msg += "Enter Email Address ";
        this.Emailstyle = "input-group-alternative mb-3 has-danger";
      }
      if (msg == "") {
        this.onloader = "flex";
        let userdata = {
          Username,
          Password,
          Firstname,
          Lastname,
          Phoneno,
          Email,
          accesskey,
        };
        if (Password == PasswordRetype) {
          this.$store
            .dispatch("studentsignup", userdata)
            .then(() => {
              this.Studusernameval = "";
              this.Pwdval = "";
              this.PasswordRetypeval = "";
              this.Firstnameval = "";
              this.Lastnameval = "";
              this.Phonenoval = "";
              this.Emailval = "";
              this.onloader = "none";
              this.$router.push("/login");
              swal({
                title: "Success!",
                text:
                  "You are highly welcome to use our App, use your username and password to access our App",
                icon: "success",
                button: "Ok",
              });
            })
            .catch((err) => {
              this.onloader = "none";
              swal({
                title: "Oops!",
                text: err.response.data,
                icon: "error",
                button: "Ok",
              });
            });
        } else {
          swal({
            title: "Oops!",
            text: "Password Mismatch",
            icon: "error",
            button: "Ok",
          });
        }
      } else {
        this.errmsg = msg;
        swal({
          title: "Oops!",
          text: "One or More Input is/are empty",
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>
