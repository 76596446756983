<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white pl-3">
                Dashboard
            </router-link>
            <router-link to="/subscription" class="text-white">
                <i class="fa fa-ellipsis-v px-2"></i>Subscription
            </router-link>
            <span class="text-white"> <i class="fa fa-ellipsis-v px-2"></i>SMS Subscription</span>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">
            <div class="mb-5 mb-xl-0">
                <card header-classes="bg-transparent">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card bg-secondary border-0 mb-0">
                                <div class="card-body">
                                    <div class="my-3">
                                        <base-input @keyup="SearchSmsSuscription()" class="input-group-alternative"
                                            placeholder="Enter unit here..." addon-left-icon="fa fa-search"
                                            v-model="searchval">
                                        </base-input>
                                    </div>
                                    <div v-if="seachUnitVal" class="text-center">
                                        <b>{{ seachUnitVal }}</b>
                                        <hr>
                                        <button class="btn btn-info shrink" @click="asyncPay">Click here to make payment
                                            <i class="fa fa-arrow-right"></i> </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="table-responsive mt-2">
                                <table class="table table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in smsUnitItems" :key="item.Id">
                                            <td>{{ item.Fromunit }}</td>
                                            <td>{{ item.ToUnit }}</td>
                                            <td>{{ item.SMSPrice }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </card>
            </div>

            <!-- End charts-->
        </div>
    </div>
</template>

<script>
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin"
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin"
export default {
    name: "reportcarsub",
    mixins: [CheckApiResponse, CheckStaffUser],
    components: {
        PageSpinner
    },
    data() {
        return {
            seachUnitVal: '',
            searchval: "",
            smsUnitItems: null,
            paymentItem: null,
            process: "N",
            onloader: "none",
            paymentData: {
                tx_ref: '',
                amount: 0,
                currency: "NGN",
                payment_options: "card,ussd",
                redirect_url: "https://schooldock.com/sms-subscription",
                customer: {
                    name: "",
                    email: "",
                    phone_number: ""
                },
                customizations: {
                    title: "SchoolDock",
                    description: "Report card subscription",
                    logo: "https://schooldock.com/img/brand/favicon.png"
                },
                callback: this.makePaymentCallback,
                onclose: this.closedPaymentModal
            }
        };
    },
    methods: {
        asyncPay() {
            this.asyncPayWithFlutterwave(this.paymentData).then(response => {
                console.log(response);
            });
        },
        makePaymentCallback(response) {
            console.log("Payment callback", response);
        },
        closedPaymentModal() {
            console.log("payment modal is closed");
        },
        populatePaymentData(TransRef, Amount, CustomerName, CustomerEmail, CustomerPhoneNo) {
            this.paymentData.tx_ref = TransRef
            this.paymentData.amount = Amount
            this.paymentData.customer.name = CustomerName
            this.paymentData.customer.email = CustomerEmail
            this.paymentData.customer.phone_number = CustomerPhoneNo
        },
        GetSmsType() {
            this.onloader = "flex";
            this.$store
                .dispatch("smsSub/GetSmsType")
                .then(resp => {
                    this.smsUnitItems = resp;
                    this.onloader = "none";
                })
                .catch(err => {
                    console.log(err);
                    this.onloader = "none";
                    this.$store.dispatch("logout");
                    this.$router.push("/home");
                });
        },
        SearchSmsSuscription() {
            if (this.searchval) {
                this.$store
                    .dispatch("smsSub/SearchSmsSuscription", this.searchval)
                    .then(resp => {
                        this.populatePaymentData(resp.TransactionRef, resp.Amount, resp.CustormerName, resp.CustomerEmailAddress, resp.CustormerPhoneNo)
                        this.seachUnitVal = `${resp.TotalAmount}`;
                        localStorage.setItem("SmsUnit",this.searchval)
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        },
        ConfirmPayment() {
            let PaymentReference = this.$route.query.transaction_id;
            var SmsUnit=localStorage.getItem("SmsUnit");
            if (PaymentReference != null || PaymentReference != undefined) {
                let status = this.$route.query.status;
                if (status == "successful") {
                    this.onloader = "flex";
                  
                    this.$store
                        .dispatch("smsSub/ConfirmPayment", {PaymentReference,SmsUnit})
                        .then(() => {
                            this.onloader = "none";
                            swal({
                                title: "Success!",
                                text: "Subscribed Successfully",
                                icon: "success",
                                button: "Ok"
                            });
                        })
                        .catch(err => {
                            this.onloader = "none";
                            swal({
                                title: "Oops!",
                                text: err.response.data,
                                icon: "error",
                                button: "Ok"
                            });
                        });
                }
            }
        },
    },
    mounted() {
        this.ConfirmPayment()
        this.GetSmsType()
    }
};
</script>

<style>
</style>
