<template>
  <div>
   <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      ><i class="ni ni-bold-right text-white"></i>
      <router-link to="/setting" class="text-white"> Settings </router-link>
      <span class="text-white"
        ><i class="ni ni-bold-right"></i> School Details</span
      >
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="card bg-secondary border-0 mb-0">
          <div class="card-body">
            <div class="form-horizontal">
              <form role="form" @submit.prevent="submitdata">
                <div class="row">
                  <div class="col-md-12 my-2">
                    <img
                      v-if="uploadedimage"
                      height="100"
                      :src="uploadedimage"
                    />
                    <img v-else height="100" src="img/brand/empty.png" /><br />
                    <input
                      type="file"
                      id="file"
                      ref="file"
                      accept=".jpg,.png,.jpeg"
                      v-on:change="handleFileUpload()"
                    />
                    <br />
                    <button
                      class="btn btn-primary btn-sm"
                      @click.prevent="submitFile()"
                    >
                      <i class="ni ni-cloud-upload-96"></i>Upload
                    </button>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>School Name</label>
                      <input v-model="schoolname" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Short Name</label>
                      <input v-model="shortname" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>SMS Header</label>
                      <input v-model="smsheader" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Phone No.</label>
                      <input
                        type="tel"
                        v-model="phoneno"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Email Address</label>
                      <input
                        type="email"
                        v-model="emailladd"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Web Address</label>
                      <input v-model="webadd" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>State</label>
                      <select
                        v-model="state"
                        class="form-control"
                        @change="getlga()"
                      >
                        <option
                          v-for="item in stateitems"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.StateName }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Local Government</label>
                      <select v-model="lga" class="form-control">
                        <option
                          v-for="item in lgaitems"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.Local_Govt_Name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>City</label>
                      <base-input v-model="city"></base-input>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Address</label>
                      <textarea
                        v-model="address"
                        class="form-control"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Bank</label>
                      <select v-model="bankid" class="form-control">
                        <option
                          v-for="item in bankitems"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.BankName }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Account Name</label>
                      <input v-model="accountname" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Account Number</label>
                      <input v-model="accountno" class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="text-center mb-3">
                  <input type="hidden" v-model="addressid" />
                  <button type="submit" class="btn btn-primary my-4">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner";
export default {
  name: "schoolDetails",
  components: {
    PageSpinner
  },
  data() {
    return {
      schoolname: "",
      shortname: "",
      smsheader: "",
      phoneno: "",
      emailladd: "",
      webadd: "",
      state: "",
      lga: "",
      lgaitems: [],
      city: "",
      address: "",
      bankid: "",
      accountname: "",
      accountno: "",
      logo: "",
      addressid: "",
      file: "",
      uploadedimage: "",
      process: "N",
      onloader: "none",
    };
  },
  computed: {
    ...mapState({
      stateitems: (state) => state.addressinfo.stateitems,
      bankitems: (state) => state.appdatas.bankitems,
      schoolitems: (state) => state.schooldata.schoolinfo,
    }),
  },
  methods: {
    getschoolinfo: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getschooldetails")
        .then((resp) => {
          this.schoolname = resp.Org_Name;
          this.shortname = resp.ShortName;
          this.smsheader = resp.MessageHeader;
          this.address = resp.AddressOne;
          this.bankid = resp.BankId;
          this.accountname = resp.AccountName;
          this.accountno = resp.AccountNumber;
          if (resp.Logo != null) {
            this.uploadedimage =
              this.$store.state.auth.baseurl + resp.Logo.substr(1);
          }
          this.logo = resp.Logo;
          this.addressid = resp.AddId;
          this.phoneno = resp.Phone_No;
          this.webadd = resp.Web_Add;
          this.state = resp.StateId;
          this.getlga();
          this.lga = resp.LGId;
          this.city = resp.City;
          this.emailladd = resp.Email_Add;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    getlga: function () {
      this.onloader = "flex";
      this.lgaitems = [];
      let StateId = this.state;
      this.$store
        .dispatch("getlgas", StateId)
        .then((resp) => {
          this.lgaitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },

    submitdata: function () {
      let Id = 1;
      let SchoolName = this.schoolname;
      let ShortName = this.shortname;
      let SMSHeader = this.smsheader;
      let PhoneNo = this.phoneno;
      let EmailAdd = this.emailladd;
      let Logo = this.logo;
      let WebAddress = this.webadd;
      let AddressId = this.addressid;
      let StateId = this.state;
      let LgaId = this.lga;
      let City = this.city;
      let Address = this.address;
      let BankId = null;
      if (this.bankid != "") {
        BankId = this.bankid;
      }
      let AccountName = this.accountname;
      let AccountNo = this.accountno;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let payload = {
        Id,
        SchoolName,
        ShortName,
        SMSHeader,
        PhoneNo,
        EmailAdd,
        Logo,
        WebAddress,
        AddressId,
        StateId,
        LgaId,
        City,
        Address,
        BankId,
        AccountName,
        AccountNo,
        processop,
        username,
        accesskey,
      };

      this.onloader = "flex";
      this.$store
        .dispatch("schoolupdate", payload)
        .then(() => {
          this.getschoolinfo();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0];
    },
    submitFile: function () {
      this.onloader = "flex";
      var form = new FormData();
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let formalurl = this.Passportval;
      let doctype = "image";
      form.append("username", username);
      form.append("accesskey", accesskey);
      form.append("formalurl", formalurl);
      form.append("doctype", doctype);
      form.append("file", this.file);
      this.$store
        .dispatch("uploadadminfile", form)
        .then((resp) => {
          this.logo = resp;
          this.uploadedimage = resp.
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Uploaded successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.auth.user_status_id != 1) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    } else {
      this.getschoolinfo();
    }
  },
};
</script>

<style></style>
