<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <card header-classes="bg-transparent">
            <div class="card bg-secondary border-0 mb-0">
                <div class="card-header">
                    <h4>Mid Term Attendance</h4>
                </div>
                <div class="card-body">
                    <div class="form-inline">
                        <div class="row">
                            <div class="col-md-4 pt-3">
                                <div class="input-group">
                                    <label class="input-group-text">Session</label>
                                    <select v-model="SessionIdval" class="form-control btn btn-success"
                                        @change="getregterm($event)">
                                        <option value=""></option>
                                        <option v-for="item in Sessionitems" :key="item.Id" v-bind:value="item.Id">
                                            {{ item.SchSession }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4 pt-3">
                                <div class="input-group">
                                    <label class="input-group-text">Term</label>
                                    <select v-model="TermIdval" @change="getalumnibysession"
                                        class="form-control btn btn-success">
                                        <option value="0"></option>
                                        <option v-for="item in termitems" :key="item.Id" v-bind:value="item.Id">
                                            {{ item.SchTerm }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4 pt-3">
                                <div class="input-group">
                                    <label class="input-group-text">Student</label>
                                    <select v-model="StudIdval" class="form-control btn btn-success"
                                        @change="getreport()">
                                        <option value="0"></option>
                                        <option v-for="item in studentitems" :key="item.Id" v-bind:value="item.Id">
                                            {{ item.StudentName }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="reportcard != null">
                <div id="divReport">
                    <div v-html="reportcard"></div>
                </div>

                <div class="text-center my-3">
                    <button class="btn btn-default" @click.prevent="printreport()">
                        <i class="fa fa-print"></i> Print
                    </button>
                    <button class="btn btn-default" @click.prevent="sendreport()">
                        <i class="fa fa-print"></i> Send to Guardian
                    </button>
                </div>
            </div>
        </card>
    </div>
</template>
<script>
import moment from 'moment'
import swal from 'sweetalert'
import PageSpinner from "@/components/PageSpinner"
export default {
    name: 'classattendance',
    components: {
        PageSpinner
    },
    data() {
        return {
            dataid: 1,
            SessionIdval: "",
            TermIdval: "",
            ClassIdval: "",
            OptionIdval: "",
            ArmIdval: "",
            StudIdval: "",
            headteacherval: "",
            headteachercommval: "",
            dateheadteachercomment: null,
            classteacherval: "",
            classteachercommval: "",
            dateclassteachercomment: null,
            gradeimagepath: "img/brand/mark.png",
            Sessionitems: null,
            termitems: null,
            classitems: null,
            optionitems: null,
            armitems: null,
            studentitems: null,
            schooldetailsitem: null,
            reportheaderrep: null,
            studrep: null,
            reportcard: null,
            currdate: null,
            process: "N",
            onloader: "none",
            isopencomment: false,
            isopenclassteachercomment: false,
        };
    },
    methods: {
        sendreport: function () {
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let ClassId = this.ClassIdval;
            let OptionId = this.OptionIdval;
            let ArmId = this.ArmIdval;
            let processop = "N";
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                StudId,
                SessionId,
                TermId,
                ClassId,
                OptionId,
                ArmId,
                processop,
                username,
                accesskey,
            };
            this.onloader = "flex";
            this.$store
                .dispatch("sendreportmid", userdata)
                .then(() => {
                    this.onloader = "none";
                    swal({
                        title: "Success!",
                        text: "Sent successfully",
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        getcurendate: function () {
            var today = new Date();
            this.currdate =
                today.getDate() +
                "/" +
                (today.getMonth() + 1) +
                "/" +
                today.getFullYear();
        },
        clearreport: function () {
            this.markrecrep = null;
            this.markrecsumrep = null;
            this.reportheaderrep = null;
            this.studrep = null;
            this.attendancerep = null;
            this.physicaldevrep = null;
            this.conductrep = null;
            this.psycomotorrep = null;
            this.gradescalerep = null;
            this.headteachercomm = null;
        },
        printreport: function () {
            // Get HTML to print from element

            var printContents = document.getElementById("divReport").innerHTML;
            var originalContents = document.body.innerHTML;

            document.body.innerHTML = printContents;

            window.print();

            document.body.innerHTML = originalContents;
        },
        getreport: function () {
            this.reportcard = null;
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                StudId,
                SessionId,
                TermId,
                username,
                accesskey,
            };
            this.onloader = "flex";
            this.$store
                .dispatch("getreportmid", userdata)
                .then((resp) => {
                    this.reportcard = resp;
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        getregsession: function () {
            this.studentitems = null;
            this.termitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.Sessionitems = null;
            this.SessionIdval = 0;
            this.onloader = "flex";
            this.$store
                .dispatch("getregsession")
                .then((resp) => {
                    this.Sessionitems = resp;
                    this.onloader = "none";
                })
                .catch((err) => {
                    console.log(err);
                    this.onloader = "none";
                    this.$store.dispatch("logout");
                    this.$router.push("/home");
                });
        },
        getregterm: function () {
            this.termitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.clearreport();
            this.TermIdval = 0;
            let SessionId = this.SessionIdval;
            if (SessionId > 0) {
                this.onloader = "flex";
                this.$store
                    .dispatch("getregterm", SessionId)
                    .then((resp) => {
                        this.termitems = resp;
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        getalumnibysession: function () {
            this.studentitems = null;
            this.clearreport();
            this.StudIdval = 0;
            if (this.SessionIdval > 0) {
                let id = this.SessionIdval;
                let searchitem = null;
                let username = this.$store.state.auth.token;
                let accesskey = this.$store.state.auth.accesskey;
                let userdata = {
                    id,
                    searchitem,
                    username,
                    accesskey,
                };
                this.onloader = "flex";
                this.$store
                    .dispatch("getalumnibysession", userdata)
                    .then((resp) => {
                        this.studentitems = resp;
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        getstudentbyid: function () {
            let StudId = this.StudIdval;
            if (StudId > 0) {
                this.onloader = "flex";
                this.$store
                    .dispatch("getstudentbyid", StudId)
                    .then((resp) => {
                        this.studrep = resp;
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        frontEndDateFormat: function (date) {
            return moment(date).format("YYYY-MM-DD");
        },
        backEndDateFormat: function (date) {
            return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
        },
        
    },
    mounted() {
        this.getregsession();
        this.getcurendate();
    }
};
</script>

<style>
.borderall {
    text-align: center;
    border: 3px solid #000;
}

.bordercol {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    border: thin solid #000;
}

.padbody {
    padding: 20px;
}

.padhead {
    padding: 5px;
    border: 3px solid #606060;
}

.pad {
    padding: 10px;
}

table {
    font-size: 9pt;
    width: 100%;
}

.bodytext {
    font-size: 9pt;
}
</style>

