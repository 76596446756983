<template>
  <div>
     <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white">
         Dashboard</router-link
      >
       <router-link to="/notification" class="text-white pl-3">
        <i class="fa fa-ellipsis-v px-2"></i>Notification</router-link
      >
      <span class="text-white"> <i class="fa fa-ellipsis-v px-2"></i>News Setup</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-6">
              <div class="card bg-secondary border-0 mb-0">
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                      <div class="card bg-gradient-success">
                        <div class="card-body">
                          <div class="form-group">
                            <label class="text-white">Category</label>
                            <select
                              v-model="CategoryIdval"
                              class="form-control"
                            >
                              <option value="0"></option>
                              <option value="1">Education</option>
                              <option value="2">Entertainment</option>
                              <option value="3">Politics</option>
                              <option value="4">Health</option>
                              <option value="5">Business</option>
                            </select>
                          </div>
                          <div class="form-group">
                            <label class="text-white">Heading</label>
                            <textarea
                              v-model="NewsHeadingval"
                              class="form-control"
                              rows="3"
                            ></textarea>
                          </div>
                          <div class="form-group">
                            <label class="text-white">Preview</label>
                            <ckeditor
                              v-model="NewsPreviewval"
                             :editor="editor"
                                  :config="editorConfig"
                            ></ckeditor>
                          </div>
                          <div class="form-group">
                            <label class="text-white">News Detail</label>
                            <ckeditor
                              v-model="NewsBodyval"
                              :editor="editor"
                                  :config="editorConfig"
                            ></ckeditor>
                          </div>
                          <div class="form-group">
                            <img
                              v-if="uploadedimage"
                              height="50"
                              :src="uploadedimage"
                            />
                            <img v-else height="50" src="img/brand/empty.png" />
                            <input
                              type="file"
                              id="file"
                              ref="file"
                              v-on:change="handleFileUpload()"
                            />
                            <br />
                            <button
                              class="btn btn-outline-secondary"
                              @click.prevent="submitFile()"
                            >
                              <i class="ni ni-cloud-upload-96"></i>Upload
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="text-center mb-3">
                        <button type="submit" class="btn btn-primary my-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mt-2">
                <div v-for="item in newsitem" :key="item.Id">
                  <div class="row">
                    <div class="clo-md-3">
                      <img
                        :src="
                          $store.state.auth.baseurl + item.ImagePath.substr(1)
                        "
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                    <div class="col-md-9">
                      <h1 class="alert-heading">
                        {{ item.NewsHeading }}
                      </h1>
                      <div v-html="item.Newspreview"></div>
                      <div class="text-center">
                        <button
                          class="btn btn-secondary btn-sm"
                          v-on:click="edititem(item.Id)"
                        >
                          <i class="fa fa-edit"></i> Edit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner";
import CkEditorUploadAdapter from "@/mixins/CkEditorUploadAdapter.mixin";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "news",
   mixins: [CkEditorUploadAdapter],
  components: {
    PageSpinner,
  },
  data() {
    return {
      Idval: 1,
      CategoryIdval: "",
      NewsHeadingval: "",
      NewsPreviewval: "",
      NewsBodyval: "",
      ImagePathval: "",
      uploadedimage: "",
      newsitem: null,
      searchval: null,
      process: "N",
      onloader: "none",
       editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [this.uploadPlugin],
      },
    };
  },

  methods: {
    edititem: function (Id) {
      this.Idval = Id;
      this.process = "U";
      this.onloader = "flex";
      this.$store
        .dispatch("getnewsschbyid", Id)
        .then((resp) => {
          this.CategoryIdval = resp.CategoryId;
          this.NewsBodyval = resp.NewsBody;
          this.NewsHeadingval = resp.NewsHeading;
          this.NewsPreviewval = resp.Newspreview;
          this.ImagePathval = resp.ImagePath;
          this.uploadedimage =
            this.$store.state.auth.baseurl + resp.ImagePath.substr(1);
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata: function () {
      let Id = this.Idval;
      let CategoryId = this.CategoryIdval;
      let NewsHeading = this.NewsHeadingval;
      let NewsPreview = this.NewsPreviewval;
      let NewsBody = this.NewsBodyval;
      let ImagePath = this.ImagePathval;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        CategoryId,
        NewsHeading,
        NewsPreview,
        NewsBody,
        ImagePath,
        processop,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("processnewssch", userdata)
        .then((resp) => {
          this.newsitem = resp;
          this.process = "N";
          this.NewsHeadingval = "";
          this.NewsPreviewval = "";
          this.NewsBodyval = "";
          this.CategoryIdval = "";
          this.uploadedimage = "";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getnewssch: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getnewsschonly")
        .then((resp) => {
          this.newsitem = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsearch: function () {
      this.$store
        .dispatch("getnewsschbysearch", this.searchval)
        .then((resp) => {
          this.newsitem = resp;
        })
        .catch((err) => {
          this.checkresponse(err.response.data);
        });
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0];
    },
    submitFile: function () {
      this.onloader = "flex";
      var form = new FormData();
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let formalurl = this.ImgPathval;
      let doctype = "image";
      form.append("username", username);
      form.append("accesskey", accesskey);
      form.append("formalurl", formalurl);
      form.append("doctype", doctype);
      form.append("file", this.file);
      this.$store
        .dispatch("uploadfile", form)
        .then((resp) => {
          this.ImagePathval = resp;
          this.uploadedimage = this.$store.state.auth.baseurl + resp.substr(1);
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Uploaded successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
  },
  mounted() {
    if (this.$store.state.auth.user_status_id != 1) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    } else {
      this.getnewssch();
    }
  },
};
</script>

<style></style>
