<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link>
      <router-link to="/registrar" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Registrar
      </router-link>
      <span class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Student Achievement</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-6">
              <div class="card bg-secondary border-0 mb-0">
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                      <div class="card bg-gradient-success">
                        <div class="card-body">
                          <div v-if="studentitems" class="bg-secondary m-2 p-2">
                            <img v-if="studentitems.Passport" :src="studentitems.Passport" class="rounded" alt=""
                              height="100" />
                            <img v-else src="img/brand/loguser.png" class="rounded" alt="" height="50" />
                            <h2>
                              <small>{{ studentitems.StudentName }}</small>
                            </h2>
                            <h6>
                              <small>{{ studentitems.AdmNo }} |
                                {{ studentitems.Sch_Class }} |
                                {{ studentitems.ClassOption }} |
                                {{ studentitems.SectionName }}</small>
                            </h6>
                          </div>
                          <div class="form-group">
                            <label class="text-white">Date</label>
                            <input type="date" v-model="DateArchievedval" class="form-control" required />
                          </div>
                          <div class="form-group">
                            <label class="text-white">Heading</label>
                            <ckeditor v-model="Headingval" :editor="editor" :config="editorConfig"></ckeditor>
                          </div>
                          <div class="form-group">
                            <label class="text-white">Detail</label>
                            <ckeditor v-model="Detailval" :editor="editor" :config="editorConfig"></ckeditor>
                          </div>
                          <div class="row">
                            <div class="col">
                              <img v-if="ImgPathval" :src="ImgPathval" class="img-fluid" />
                              <img v-else src="img/brand/empty.png" class="img-fluid" />
                            </div>
                            <div class="col">
                              <ImageUploader @emitOutImgPathval="updateImgPathval" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center mb-3">
                        <button type="submit" class="btn btn-primary my-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mt-2">
                <div v-for="item in studentarchievementitem" :key="item.Id">
                  <div class="alert alert-secondary">
                    <blockquote class="blockquote">
                      <img height="100" :src="item.ImgPath" alt="" />
                      <h3 class="alert-heading">
                        <b class="badge badge-dark">{{
                          item.DateCreated | formatDate
                        }}</b>
                      </h3>
                      <div v-html="item.Heading"></div>
                      <div v-html="item.Detail"></div>
                      <div class="text-center">
                        <button class="btn btn-secondary btn-sm" v-on:click="edititem(item.Id)">
                          <i class="fa fa-edit"></i> Edit
                        </button>
                      </div>
                    </blockquote>
                  </div>
                  <div class="text-center">
                    <i class="fa fa-arrow-down"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>

      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner";
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin";
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin";
import CkEditorUploadAdapter from "@/mixins/CkEditorUploadAdapter.mixin";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ImageUploader from "@/components/ImageUploader"
export default {
  name: "StudentAchievement",
  mixins: [CheckApiResponse, CheckStaffUser, CkEditorUploadAdapter],
  components: {
    PageSpinner, ImageUploader
  },
  data() {
    return {
      dataid: 1,
      StudIdval: localStorage.getItem("StudentId"),
      Headingval: "",
      Detailval: "",
      DateArchievedval: "",
      ImgPathval: "",
      uploadedimage: "",
      studentitems: null,
      studentarchievementitem: null,
      process: "N",
      onloader: "none",
      editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [this.uploadPlugin],
      },
    };
  },

  methods: {
    updateImgPathval(data) {
      this.ImgPathval = data;
    },
    edititem(Id) {
      this.dataid = Id;
      let id = Id;
      let idd = this.StudIdval;
      let searchitem = null;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        id,
        idd,
        searchitem,
        username,
        accesskey,
      };
      this.process = "U";
      this.onloader = "flex";
      this.$store
        .dispatch("getstudentarchievementbyid", userdata)
        .then((resp) => {
          this.Headingval = resp.Heading;
          this.Detailval = resp.Detail;
          this.DateArchievedval = this.frontEndDateFormat(resp.DateCreated);
          this.ImgPathval = resp.ImgPath;
          this.uploadedimage =
            this.$store.state.auth.baseurl + resp.ImgPath.substr(1);
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata() {
      let Id = this.dataid;
      let StudId = this.StudIdval;
      let Heading = this.Headingval;
      let Detail = this.Detailval;
      let DateArchieved = this.DateArchievedval;
      let ImgPath = this.ImgPathval;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        Heading,
        Detail,
        DateArchieved,
        ImgPath,
        StudId,
        processop,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("studentachievementregistration", userdata)
        .then((resp) => {
          this.studentarchievementitem = resp;
          this.process = "N";
          this.Headingval = "";
          this.ImgPathval = "";
          this.uploadedimage = "";
          this.DateArchievedval = "";
          this.Detailval = "";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getstudentarchievement() {
      let StudId = this.StudIdval;
      if (StudId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getstudentarchievement", StudId)
          .then((resp) => {
            this.studentarchievementitem = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getstudent() {
      this.onloader = "flex";
      this.$store
        .dispatch("getstudentbyid", this.StudIdval)
        .then((resp) => {
          this.studentitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      this.submitFile();
    },
    submitFile() {
      this.onloader = "flex";
      var form = new FormData();
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let formalurl = this.ImgPathval;
      let doctype = "image";
      form.append("username", username);
      form.append("accesskey", accesskey);
      form.append("formalurl", formalurl);
      form.append("doctype", doctype);
      form.append("file", this.file);
      this.$store
        .dispatch("uploadfile", form)
        .then((resp) => {
          this.ImgPathval = resp;
          this.uploadedimage = resp;
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Uploaded successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    frontEndDateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.auth.user_status_id != 1) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    } else {
      this.getstudent();
      this.getstudentarchievement();
    }
  },
};
</script>

<style>

</style>
