<template>
  <div class="row flex-column-reverse flex-md-row no-gutters">
    <!-- Header -->
    <div class="col-md-7 bg-secondary">
      <div class="mx-3 my-5" v-for="item in news" :key="item.Id">
        <button
          class="btn btn-secondary"
          v-on:click="readnews(item.NewsHeading)"
        >
          <div class="row">
            <div class="col-md-4">
              <img
                v-if="item.ImagePath"
                :src="$store.state.auth.baseurl + item.ImagePath.substr(1)"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="col-md-8 text-left">
              <h1>{{ item.NewsHeading }}</h1>
              <div v-html="item.Newspreview"></div>
            </div>
          </div>
        </button>
      </div>
      <div class="text-center mb-3" v-if="news">
        <router-link to="/morenews" class="btn btn-secondary">
          Read More News <i class="fa fa-arrow-right"></i>
        </router-link>
      </div>
    </div>
    <div class="col-md-5">
      <div class="my-5">
        <div class="py-5 row text-white text-center bg-translucent-dark">
          <div class="col-lg-12 col-md-12 col-sm-6 col">
            <div class="mx-4 shrink">
              <router-link to="/login">
                <div class="mb-3">
                  <div class="row">
                    <div
                      class="col-md-3 bg-translucent-success text-white justify-content-center text-center pt-5"
                    >
                      <i class="fa fa-server fa-2x"></i>
                    </div>
                    <div class="col-md-9 pt-1 bg-green">
                      <h3 class="pt-3 text-white">School Portal</h3>
                      <div class="progress">
                        <div
                          class="progress-bar bg-translucent-success"
                          style="width: 70%"
                        ></div>
                      </div>
                      <p class="text-white text-left">
                        <small>Student, Staff, Education, Finance</small>
                      </p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-6 col">
            <div class="mx-4 shrink">
              <router-link to="/login">
                <div class="mb-3">
                  <div class="row">
                    <div
                      class="col-md-3 bg-translucent-success text-white justify-content-center text-center pt-5"
                    >
                      <i class="ni ni-laptop ni-2x"></i>
                    </div>
                    <div class="col-md-9 pt-1 bg-green">
                      <h3 class="pt-3 text-white">Exam Prep (CBT)</h3>
                      <div class="progress">
                        <div
                          class="progress-bar bg-translucent-success"
                          style="width: 70%"
                        ></div>
                      </div>
                      <p class="text-white text-left">
                        <small>JAMB, WAEC, NECO, GCE ...</small>
                      </p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      news: null,
    };
  },
  methods: {
    readnews: function (NewsHeading) {
      localStorage.setItem("NewsHeading", NewsHeading);
      this.$router.push("/readnews");
    },
    getnews: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getnewsforexternaltop10")
        .then((resp) => {
          this.news = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err.response.data);
        });
    },
  },
  mounted() {
    this.getnews();
  },
};
</script>

<style>
.newsbody {
  height: 1000px;
  overflow: auto;
}
.newsrow {
  border-left: 6px solid white;
}
.module-footer {
  height: 80px;
}
</style>
