import axios from "axios";
export default {
    methods: {
        uploadPlugin(editor) {
            editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
              return {
                upload() {
                  return new Promise((resolve, reject) => {
                    const data = new FormData();
                    loader.file.then((file) => {
                      data.append("file", file);
                      axios({
                        url: "http://localhost/SchoolDockAPI/api/Fileuploader/Uploadfile",
                        method: "post",
                        data,
                        headers: {
                          "Content-Type": "multipart/form-data;",
                        },
                        withCredentials: false,
                      })
                        .then((response) => {                         
                          if (response.data != null) {
                            resolve({                       
                              default: response.data,
                            });
                          } else {
                            reject(response.data);
                          }
                        })
                        .catch(() => {
                          reject("Upload failed");
                        });
                    });
                  });
                },
              };
            };
          },
    },    
  }