<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
       Dashboard</router-link
      >
      <router-link to="/library" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Library
      </router-link>
      <span class="text-white"
        ><i class="fa fa-ellipsis-v px-2"></i>Library Book Category</span
      >
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-4">
              <div class="card bg-secondary">
                <h3 class=" card-header">Registration</h3>
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                      <div class="form-group">
                        <label>Category Name</label>
                        <input
                          v-model="CategoryNameval"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group" v-if="CategoryNameval">
                        <label>Category Code</label>
                        <input
                          v-model="CategoryCodeval"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="text-center mb-3" v-if="CategoryCodeval">
                        <button type="submit" class="btn btn-primary my-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="justify-content-end form-inline mt-3">
                <base-input
                  class="input-group-alternative"
                  placeholder="Search here..."
                  addon-left-icon="fa fa-search"
                  v-model="searchval"
                >
                </base-input>
              </div>
              <div class="table-responsive mt-2">
                <table
                  class="table table-dark table-bordered table-striped table-hover"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Category Name</th>
                      <th>Category Code</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in searchedResultQuery"
                      :key="item.Id"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.CategoryName }}</td>
                      <td>{{ item.CategoryCode }}</td>
                      <td>
                        <button
                          class="btn btn-secondary btn-sm"
                          v-on:click="edititem(item.Id)"
                        >
                          <i class="fa fa-edit"></i> Edit
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import LibraryCategoryMixin from '@/mixins/LibraryCategory.mixin'
import PageSpinner from '@/components/PageSpinner'
export default {
  name: "LibraryCategory",
   mixins: [LibraryCategoryMixin],
   components: {
    PageSpinner
  },
  data() {
    return {
      Idval: 1,
      CategoryNameval: '',
      CategoryCodeval: '',
      searchval: '',
      LibraryCategoryItems: null,
      process: 'N',
      onloader: 'none'
    };
  },
  computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.LibraryCategoryItems.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.CategoryName.toLowerCase().includes(v) ||
                item.CategoryCode.toLowerCase().includes(v)
            );
        });
      } else {
        return this.LibraryCategoryItems;
      }
    }
  },
  methods: {
    edititem(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("libraryCategory/GetByIdLibraryCategory", Id)
        .then(resp => {
          this.Idval = Id;
          this.CategoryNameval = resp.CategoryName;
          this.CategoryCodeval = resp.CategoryCode;
          this.process = "U";
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata() {
      let Id = this.Idval;
      let CategoryName = this.CategoryNameval;
      let CategoryCode = this.CategoryCodeval;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        CategoryName,
        CategoryCode,
        processop,
        username,
        accesskey
      };
      this.onloader = "flex";
      this.$store
        .dispatch("libraryCategory/ProcessLibraryCategory", userdata)
        .then(resp => {
          this.LibraryCategoryItems = resp;
          this.CategoryNameval = "";
          this.CategoryCodeval = "";
          this.process = "N";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok"
          });
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },    
    checkresponse(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok"
        });
      }
    }
  },
  mounted() {
    if (this.$store.state.auth.user_status_id != 1) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    }
  }
};
</script>

<style></style>
