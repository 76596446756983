<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 justify-content-center pb-3 text-center"></div>
      <div class="col-lg-4">
        <div class="px-5 py-5 text-white bg-dark">
          <div class="text-center text-muted my-3">           
            <p class="mt-3">  Enter your username to retrieve your password</p>
          </div>         
         <form role="form" @submit.prevent="submitdetails">
              <base-input
                class="input-group-alternative mb-3"
                placeholder="Username"
                addon-left-icon="ni ni ni-single-02"
                v-model="username"
              >
              </base-input>

              <div class="text-center">
                <button type="submit" class="my-4 btn btn-primary" :disabled="$store.state.appdatas.disenableifNotOnline">
                  Submit <i class="fa fa-arrow-right"></i>
                </button>
              </div>
            </form>
            <div class="text-center pt-2">
              <router-link to="/login" class="text-primary"
                ><small>Back to Login</small></router-link
              >
            </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import swal from "sweetalert";
export default {
  name: "Retrievepassword",  
  data() {
    return {
      username: "",
      errormsg: "",
      onloader: "none",
    };
  },
  methods: {
    submitdetails: function () {
      let username = this.username;
      let accesskey = this.$store.state.auth.accesskey;

      this.onloader = "flex";
      this.$store
        .dispatch("resetpassword", {
          username,
          accesskey,
        })
        .then(() => {
          this.onloader = "none";
          swal({
            title: "Success!",
            text:
              "Your password has been reset successfully, check phone message and email for new password",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          swal({
            title: "Oops!",
            text: err.response.data,
            icon: "error",
            button: "Ok",
          });
        });
    },
  },
};
</script>

<style>
</style>
