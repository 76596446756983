var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.state.auth.user_status_id == 1)?_c('Staffportal',{attrs:{"userName":_vm.$store.state.auth.user_fullName,"userPhoneNo":_vm.$store.state.auth.user_phone,"userEmailAddress":_vm.$store.state.auth.user_email}}):_vm._e(),(
      _vm.$store.state.auth.user_status_id == 2 ||
        _vm.$store.state.auth.user_status_id == 8
    )?_c('Studentportal',{attrs:{"userName":_vm.$store.state.auth.user_fullName,"userPhoneNo":_vm.$store.state.auth.user_phone,"userEmailAddress":_vm.$store.state.auth.user_email}}):_vm._e(),(
      _vm.$store.state.auth.user_status_id == 6 ||
        _vm.$store.state.auth.user_status_id == 7 ||
        _vm.$store.state.auth.user_status_id == 9 ||
         _vm.$store.state.auth.user_status_id == 11
    )?_c('Adminportal',{attrs:{"userName":_vm.$store.state.auth.user_fullName,"userPhoneNo":_vm.$store.state.auth.user_phone,"userEmailAddress":_vm.$store.state.auth.user_email}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }