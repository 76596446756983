<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard
      </router-link>
      <router-link to="/registrar" class="text-white pl-3">
        <i class="fa fa-ellipsis-v px-2"></i>Registrar
      </router-link>
      <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>Staffs
      </span>
    </base-header>


    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">

        <!--Class Teacher-->
        <modal :show.sync="modals.classTeacherModal" modal-classes="modal-dialog-centered">
          <PageSpinner :onloader="onloader" />
          <h5 slot="header" class="modal-title" id="modal-title-default">Class Teacher</h5>
          <div class="card">
            <div class="card-body">
              <div class="text-center">
                <img v-if="staffPassport" :src="staffPassport" class="circle" alt="" height="50" />
                <img v-else src="img/brand/loguser.png" class="circle" alt="" height="50" />
                <h5>
                  <small>{{ staffName }}</small>
                </h5>
              </div>
              <div class="form-group">
                <label>Class</label>
                <select v-model="ClassIdvalAssignClass" class="form-control dropdown"
                  @change="getOptionForAssignClass()">
                  <option value="0">Select Class</option>
                  <option v-for="item in schclass" :key="item.Id" v-bind:value="item.Id">
                    {{ item.Sch_Class }}
                  </option>
                </select>
              </div>
              <div class="form-group" v-if="ClassIdvalAssignClass">
                <label>Option</label>
                <select v-model="OptionIdvalAssignClass" class="form-control" @change="getArmForAssignClass()">
                  <option value="0">Select Option</option>
                  <option v-for="item in optionitems" :key="item.Id" v-bind:value="item.Id">
                    {{ item.ClassOption }}
                  </option>
                </select>
              </div>
              <div class="form-group" v-if="OptionIdvalAssignClass">
                <label>Arm</label>
                <select v-model="ArmIdvalAssignClass" class="form-control">
                  <option value="0">Select Arm</option>
                  <option v-for="item in armitems" :key="item.Id" v-bind:value="item.Id">
                    {{ item.SectionName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <template slot="footer">
            <base-button type="secondary" class="ml-auto" @click="modals.classTeacherModal = false">Close
            </base-button>
            <button v-if="ArmIdvalAssignClass" class="btn btn-success"
              @click.prevent="submitAssignedClass()">Submit</button>

          </template>
        </modal>
        <!--Class Teacher-->

        <!--Subjec Teacher-->
        <modal :show.sync="modals.subjectTeacherModal" modal-classes="modal-dialog-centered">
          <PageSpinner :onloader="onloader" />
          <h5 slot="header" class="modal-title" id="modal-title-default">Subject Teacher</h5>
          <div class="card">
            <div class="card-body">
              <div class="text-center">
                <img v-if="staffPassport" :src="staffPassport" class="rounded" alt="" height="50" />
                <img v-else src="img/brand/loguser.png" class="rounded" alt="" height="50" />
                <h5>
                  <small>{{ staffName }}</small>
                </h5>
              </div>
              <div class="form-group">
                <label>Class</label>
                <select v-model="ClassIdvalSubjectTeacher" class="form-control dropdown"
                  @change="getOptionForSubjectTeacher()">
                  <option value="0">Select Class</option>
                  <option v-for="item in schclass" :key="item.Id" v-bind:value="item.Id">
                    {{ item.Sch_Class }}
                  </option>
                </select>
              </div>
              <div class="form-group" v-if="ClassIdvalSubjectTeacher">
                <label>Option</label>
                <select v-model="OptionIdvalSubjectTeacher" class="form-control" @change="getschsubject()">
                  <option value="0">Select Option</option>
                  <option v-for="item in optionitems" :key="item.Id" v-bind:value="item.Id">
                    {{ item.ClassOption }}
                  </option>
                </select>
              </div>
              <div class="form-group" v-if="OptionIdvalSubjectTeacher">
                <label>Subject</label>
                <select v-model="SubjectIdval" class="form-control">
                  <option value="0">Select Subject</option>
                  <option v-for="item in subjectitems" :key="item.Id" v-bind:value="item.Id">
                    {{ item.SchSubject }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <template slot="footer">
            <base-button type="secondary" class="ml-auto" @click="modals.subjectTeacherModal = false">Close
            </base-button>
            <button v-if="SubjectIdval" class="btn btn-success"
              @click.prevent="submitSubjectTeacherData()">Submit</button>

          </template>
        </modal>
        <!--Subject Teacher-->
        <div v-if="openform">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body">
              <div class="form-horizontal">
                <form role="form" @submit.prevent="submitdata">
                  <div class="card">
                    <h5 class="card-header">Biodata</h5>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col">
                              <img v-if="ImgPathval" :src="ImgPathval" class="img-fluid" />
                              <img v-else src="img/brand/empty.png" class="img-fluid" />
                            </div>
                            <div class="col">
                              <ImageUploader @emitOutImgPathval="updatePassportVal" />
                            </div>
                          </div>

                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Surname</label>
                            <input v-model="LastNameval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>First Name</label>
                            <input v-model="FirstNameval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Other Name</label>
                            <input v-model="MiddleNameval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Email Address</label>
                            <input type="email" v-model="EmailAddressval" class="form-control" required />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Phone No.</label>
                            <input type="tel" v-model="PhoneNoval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Sex</label>
                            <select v-model="SexIdval" class="form-control">
                              <option value="0"></option>
                              <option value="10">Male</option>
                              <option value="11">Female</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Marital Status</label>
                            <select v-model="MaritalStatusIdval" class="form-control">
                              <option value="0"></option>
                              <option value="12">Married</option>
                              <option value="13">Single</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Date of Birth</label>
                            <input type="date" v-model="DOBval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Full Address</label>
                            <textarea v-model="FullAddressval" class="form-control" rows="3" required></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div class="card">
                    <h5 class="card-header">Operation</h5>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Service Number</label>
                            <base-input v-model="ServiceNoval"></base-input>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Staff Type</label>
                            <select class="form-control" v-model="CategoryIdval">
                              <option value="0"></option>
                              <option value="14">Teaching</option>
                              <option value="15">Non-Teaching</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Operation to Perform</label>
                            <div class="row">
                              <div class="col-sm-4">
                                <base-checkbox class="ml-4" v-model="IsBursalval">
                                  Bursar
                                </base-checkbox>
                              </div>
                              <div class="col-sm-4">
                                <base-checkbox class="ml-4" v-model="IsCounselorval">
                                  Counselor
                                </base-checkbox>
                              </div>
                              <div class="col-sm-4">
                                <base-checkbox class="ml-4" v-model="IsHODval">
                                  HOD
                                </base-checkbox>
                              </div>
                              <div class="col-sm-4">
                                <base-checkbox class="ml-4" v-model="IsHostelMasterval">
                                  Hostel Master / Mistress
                                </base-checkbox>
                              </div>
                              <div class="col-sm-4">
                                <base-checkbox class="ml-4" v-model="IsLibraryAdminval">
                                  Library Admin
                                </base-checkbox>
                              </div>
                              <div class="col-sm-4">
                                <base-checkbox class="ml-4" v-model="IsRegistrarval">
                                  Registrar
                                </base-checkbox>
                              </div>
                              <div class="col-sm-4">
                                <base-checkbox class="ml-4" v-model="IsPortalAdminval">
                                  Portal Admin
                                </base-checkbox>
                              </div>
                              <div class="col-sm-4">
                                <base-checkbox class="ml-4" v-model="IsPrincipalval">
                                  Principal
                                </base-checkbox>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="text-center mb-3">
                    <button type="button" @click.stop="closeform()" class="btn btn-secondary">
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-primary my-4">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="justify-content-end form-inline">
            <button type="button" @click.prevent="newterm" class="btn btn-default">
              <i class="ni ni-fat-add"></i>Add New
            </button>
            <router-link to="/deleted-staff" class="btn btn-danger"> <i class="fa fa-trash-alt"></i> Deleted
              Staff</router-link>
            <base-input class="input-group-alternative" placeholder="Search here..." addon-left-icon="fa fa-search"
              v-model="searchval">
            </base-input>
          </div>
          <div class="row">
            <div class="col-md-6 col" v-for="item in searchedResultQuery" :key="item.StaffId">
              <div class="card animate__animated animate__flipInX text-center m-2">
                <div class="card-body bg-success">
                  <div class="row">
                    <div class="col-md-4">
                      <img v-if="item.Passport" :src="item.Passport" class="rounded" alt="" height="50" />
                      <img v-else src="img/brand/loguser.png" class="rounded" alt="" height="50" />
                      <h5 class="text-white">
                        <small>{{ item.StaffName }}</small>
                      </h5>
                      <h6 class="text-white">
                        <small>{{ item.SeviceNo }} | {{ item.EmailAddress }} |
                          {{ item.PhoneNumber }}</small>
                      </h6>
                      <div class="mb-3">
                        <button class="btn btn-secondary btn-sm" v-on:click="edititem(item.StaffId)">
                          <i class="fa fa-edit"></i>
                        </button>
                        <button v-if="item.IsDeleted" class="btn btn-default btn-sm"
                          v-on:click="deletestaff(item.StaffId)">
                          <i class="fa fa-trash-restore"></i>
                        </button>
                        <button v-else class="btn btn-danger btn-sm" v-on:click="deletestaff(item.StaffId)">
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                    <div class="col-md-8 text-left">
                      <div class="card mb-3">
                        <div class="card-body">
                          <h6>CLASS(ES) <button v-on:click="addClassTeacher(item.StaffId)"
                              class="btn btn-success btn-sm align-items-end text-right"><i class="fa fa-plus"></i> Add
                              Class</button></h6>
                          <div class="row">
                            <div class="col" v-for="itemClass in item.StaffClass" :key="itemClass.StaffClassId">
                              <div class="bg-secondary m-2 p-2">
                                <span class="small"> <small>{{ itemClass.ClassName }} - {{
                                  itemClass.SectionName
                                }}</small> <button class="btn btn-outline-danger btn-sm"
                                    v-on:click="deleteclassteacher(itemClass.StaffClassId)"> <i
                                      class="fa fa-trash"></i></button></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-body">
                          <h6>SUBJECT(S) <button v-on:click="addSubjectTeacher(item.StaffId)"
                              class="btn btn-success btn-sm align-items-end text-right"><i class="fa fa-plus"></i> Add
                              Subject</button></h6>
                          <div class="row">
                            <div class="col" v-for="itemSubject in item.StaffSubject" :key="itemSubject.StaffSubjectId">
                              <div class="bg-secondary m-2 p-2">
                                <span class="small"> <small>{{ itemSubject.SubjectName }} || {{ itemSubject.ClassName }}
                                    || {{ itemSubject.OptionName }}</small> <button
                                    class="btn btn-outline-danger btn-sm"
                                    v-on:click="deletesubjectteacher(itemSubject.StaffSubjectId)"> <i
                                      class="fa fa-trash"></i></button></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin"
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin"
import ImageUploader from "@/components/ImageUploader"
export default {
  name: "staff",
  mixins: [CheckApiResponse, CheckStaffUser],
  components: {
    PageSpinner, ImageUploader
  },
  data() {
    return {
      dataid: 1,
      ImgPathval: "",
      ContactIdval: 1,
      FirstNameval: "",
      MiddleNameval: "",
      LastNameval: "",
      PhoneNoval: "",
      EmailAddressval: "",
      SexIdval: 0,
      DOBval: "",
      UserIdval: 1,
      FullAddressval: "",
      ServiceNoval: "",
      CategoryIdval: null,
      MaritalStatusIdval: null,
      IsPrincipalval: false,
      IsBursalval: false,
      IsPortalAdminval: false,
      IsHostelMasterval: false,
      IsLibraryAdminval: false,
      IsCounselorval: false,
      IsRegistrarval: false,
      IsHODval: false,
      file: "",
      uploadedimage: "",
      staffs: null,
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none",
      StaffIdval: 0,
      optionitems: null,
      armitems: null,
      schclass: null,
      //--Class Teacher
      ClassIdvalAssignClass: 0,
      OptionIdvalAssignClass: 0,
      ArmIdvalAssignClass: 0,
      classteachers: null,
      classteacheritem: null,
      //--Class Teacher

      //--SubjectTeacher
      ClassIdvalSubjectTeacher: 0,
      OptionIdvalSubjectTeacher: 0,
      SubjectIdval: 0,
      subjectitems: null,
      subjectteacheritem: null,
      //--SubjectTeacher

      staffName: "",
      staffPassport: "",
      modals: {
        classTeacherModal: false,
        subjectTeacherModal: false
      }
    };
  },
  computed: {
    ...mapState({
      stateitems: (state) => state.addressinfo.stateitems,
    }),
    searchedResultQuery() {
      if (this.searchval) {
        return this.staffs.filter((item) => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item.SeviceNo.includes(v) ||
                item.StaffName.toLowerCase().includes(v) ||
                item.EmailAddress.toLowerCase().includes(v) ||
                item.PhoneNumber.includes(v)
            );
        });
      } else {
        return this.staffs;
      }
    },
  },
  methods: {
    newterm() {
      this.ImgPathval = "";
      this.FirstNameval = "";
      this.EmailAddressval = "";
      this.PhoneNoval = "";
      this.FullAddressval = "";
      this.ServiceNoval = "";
      this.IsPrincipalval = false;
      this.IsBursalval = false;
      this.IsPortalAdminval = false;
      this.IsHostelMasterval = false;
      this.IsLibraryAdminval = false;
      this.IsCounselorval = false;
      this.IsRegistrarval = false;
      this.IsHODval = false;
      this.MiddleNameval = "";
      this.LastNameval = "";
      this.SexIdval = 0;
      this.CategoryIdval = 0;
      this.MaritalStatusIdval = 0;
      this.DOBval = "";
      this.file = "";
      this.uploadedimage = "";
      this.getserviceno();
      this.openform = true;
      this.process = "N";
    },
    closeform() {
      this.ImgPathval = "";
      this.FullAddressval = "";
      this.FirstNameval = "";
      this.EmailAddressval = "";
      this.PhoneNoval = "";
      this.ServiceNoval = "";
      this.CategoryIdval = null;
      this.IsPrincipalval = false;
      this.IsBursalval = false;
      this.IsPortalAdminval = false;
      this.IsHostelMasterval = false;
      this.IsLibraryAdminval = false;
      this.IsCounselorval = false;
      this.IsRegistrarval = false;
      this.IsHODval = false;
      this.MiddleNameval = "";
      this.LastNameval = "";
      this.SexIdval = 0;
      this.CategoryIdval = 0;
      this.MaritalStatusIdval = 0;
      this.DOBval = "";
      this.file = "";
      this.uploadedimage = "";
      this.openform = false;
      this.process = "N";
    },
    updatePassportVal(data) {
      this.ImgPathval = data;
    },
    edititem(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getstaffbyid", Id)
        .then((resp) => {        
          this.dataid = resp.StaffId;         
          this.ImgPathval = resp.Passport;
          this.ContactIdval = resp.ContactId;
          this.IsPrincipalval = resp.IsPrincipal;
          this.IsBursalval = resp.IsBursal;
          this.IsPortalAdminval = resp.IsPortalAdmin;
          this.IsHostelMasterval = resp.IsHostelMaster;
          this.IsLibraryAdminval = resp.IsLibraryAdmin;
          this.IsCounselorval = resp.IsCounselor;
          this.IsRegistrarval = resp.IsRegistrar;
          this.IsHODval = resp.IsHod;
          this.FullAddressval = resp.FullAddress;
          this.FirstNameval = resp.FirstName;
          this.ServiceNoval = resp.StaffNo;
          this.CategoryIdval = resp.CategoryId;
          this.MiddleNameval = resp.MiddleName;
          this.LastNameval = resp.LastName;
          this.EmailAddressval = resp.EmailAddress;
          this.PhoneNoval = resp.PhoneNo;
          this.SexIdval = resp.SexId;
          this.MaritalStatusIdval = resp.MaritalStatusId;
          this.UserIdval = resp.UserId;
          if (resp.DateofBirth != null || resp.DateofBirth != "") {
            this.DOBval = this.frontEndDateFormat(resp.DateofBirth);
          }
          this.CategoryIdval = resp.StaffTypeId
          this.FullAddressval = resp.FullAddress
          this.PhoneNoval = resp.PhoneNumber

          this.openform = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    deletestaff(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("deletestaff", Id)
        .then(() => {
          this.getstaffs();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Processed successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata() {
      let Id = this.dataid;
      let Passport = this.ImgPathval;
      let ContactId = this.ContactIdval;
      let IsPrincipal = this.IsPrincipalval;
      let IsBursal = this.IsBursalval;
      let IsPortalAdmin = this.IsPortalAdminval;
      let IsHostelMaster = this.IsHostelMasterval;
      let IsLibraryAdmin = this.IsLibraryAdminval;
      let IsCounselor = this.IsCounselorval;
      let IsRegistrar = this.IsRegistrarval;
      let IsHOD = this.IsHODval;
      let FullAddress = this.FullAddressval;
      let FirstName = this.FirstNameval;
      let ServiceNo = this.ServiceNoval;
      let CategoryId = this.CategoryIdval;
      let MiddleName = this.MiddleNameval;
      let LastName = this.LastNameval;
      let EmailAddress = this.EmailAddressval;
      let MaritalStatusId = this.MaritalStatusIdval;
      let PhoneNo = this.PhoneNoval;
      let SexId = this.SexIdval;
      let DOB = this.DOBval;
      let UserId = this.UserIdval;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      this.onloader = "flex";
      let userdata = {
        Id,
        UserId,
        Passport,
        ContactId,
        MaritalStatusId,
        CategoryId,
        ServiceNo,
        IsPrincipal,
        IsBursal,
        IsPortalAdmin,
        IsHostelMaster,
        IsLibraryAdmin,
        IsCounselor,
        IsRegistrar,
        IsHOD,
        FirstName,
        MiddleName,
        LastName,
        EmailAddress,
        PhoneNo,
        SexId,
        DOB,
        FullAddress,
        processop,
        username,
        accesskey,
      };
      this.$store
        .dispatch("registerstaff", userdata)
        .then(() => {
          this.getstaffs();
          this.closeform();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getstaffs() {
      this.onloader = "flex";
      this.$store
        .dispatch("getstaffs")
        .then((resp) => {
          this.staffs = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    async getserviceno() {
      this.onloader = "flex";
      await this.$store
        .dispatch("getserviceno")
        .then((resp) => {
          this.ServiceNoval = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/home");
        });
    },
    deleteclassteacher(Id) {
      this.onloader = 'flex'
      this.$store.dispatch('deleteclassteacher', Id)
        .then(() => {
          this.getstaffs();
          this.onloader = 'none';
        })
        .catch(err => {
          this.onloader = 'none'
          this.checkresponse(err.response.data);
        })
    },
    deletesubjectteacher(Id) {
      this.onloader = 'flex'
      this.$store.dispatch('deletesubjectteacher', Id)
        .then(() => {
          this.getstaffs();
          this.onloader = 'none';
        })
        .catch(err => {
          this.onloader = 'none'
          this.checkresponse(err.response.data);
        })
    },
    addClassTeacher(StaffId) {
      this.StaffIdval = StaffId;
      this.getstaffbyid();
      this.ClassIdvalAssignClass = 0;
      this.modals.classTeacherModal = true
    },
    addSubjectTeacher(StaffId) {
      this.StaffIdval = StaffId;
      this.getstaffbyid();
      this.classteacheritem = null;
      this.ClassIdvalAssignClass = 0;
      this.modals.subjectTeacherModal = true
    },
    getClass() {
      this.classitems = null;
      this.optionitems = null;
      this.armitems = null;
      this.classteacheritem = null;
      this.onloader = "flex";
      this.$store
        .dispatch("getclass")
        .then((resp) => {
          this.schclass = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getstaffbyid() {
      this.onloader = "flex";
      this.$store
        .dispatch("getstaffbyid", this.StaffIdval)
        .then((resp) => {
          this.staffPassport = resp.Passport
          this.staffName = resp.StaffName
          this.classteacheritem = resp.StaffClass
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getOptionForAssignClass() {
      this.optionitems = null;
      this.armitems = null;
      this.classteacheritem = null;
      this.OptionIdvalAssignClass = 0;
      this.onloader = "flex";
      let ClassId = this.ClassIdvalAssignClass;
      if (ClassId > 0) {
        this.$store
          .dispatch("getoption", ClassId)
          .then((resp) => {
            this.optionitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getArmForAssignClass() {
      this.armitems = null;
      this.classteacheritem = null;
      this.ArmIdvalAssignClass = 0;
      this.onloader = "flex";
      let OptionId = this.OptionIdvalAssignClass;
      if (OptionId > 0) {
        this.$store
          .dispatch("getarm", OptionId)
          .then((resp) => {
            this.armitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    submitAssignedClass() {
      let StaffId = this.StaffIdval;
      let ClassId = this.ClassIdvalAssignClass;
      let ArmId = this.ArmIdvalAssignClass;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        StaffId,
        ClassId,
        ArmId,
        processop,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("Processclassteacher", userdata)
        .then(() => {
          this.getstaffs();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getOptionForSubjectTeacher() {
      this.optionitems = null;
      this.armitems = null;
      this.classteacheritem = null;
      this.OptionIdvalSubjectTeacher = 0;
      this.onloader = 'flex'
      let ClassId = this.ClassIdvalSubjectTeacher;
      if (ClassId > 0) {
        this.$store.dispatch('getoption', ClassId)
          .then(resp => {
            this.optionitems = resp;
            this.onloader = 'none';
          })
          .catch(err => {
            this.onloader = 'none'
            this.checkresponse(err.response.data);
          })
      }
    },
    getschsubject() {
      this.subjectitems = null;
      this.classteacheritem = null;
      this.SubjectIdval = 0;
      this.onloader = 'flex'
      let ClassId = this.ClassIdvalSubjectTeacher;
      let OptionId = this.OptionIdvalSubjectTeacher;
      let SchsubId = 1;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        ClassId,
        SchsubId,
        OptionId,
        processop,
        username,
        accesskey
      }

      if (OptionId > 0) {
        this.$store.dispatch('getschsubject', userdata)
          .then(resp => {
            this.subjectitems = resp;
            this.onloader = 'none';
          })
          .catch(err => {
            this.onloader = 'none';
            this.checkresponse(err.response.data);
          })
      }
    },
    submitSubjectTeacherData() {
      let StaffId = this.StaffIdval;
      let SubjectId = this.SubjectIdval;
      let OptionId = this.OptionIdvalSubjectTeacher;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        StaffId,
        SubjectId,
        OptionId,
        processop,
        username,
        accesskey
      }
      this.onloader = 'flex'
      this.$store.dispatch('processsubjectteacher', userdata)
        .then(() => {
          this.getstaffs();
          this.onloader = 'none'
          swal({
            title: "Success!",
            text: 'Submitted successfully',
            icon: "success",
            button: "Ok",
          });
        })
        .catch(err => {
          this.onloader = 'none'
          this.checkresponse(err.response.data);
        })
    },
    frontEndDateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.getClass();
    this.getstaffs();
  },
};
</script>

<style>

</style>
