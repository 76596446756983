import moment from "moment";
export default {
  methods: {
    frontEndDateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    }
  }
};
