<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white">
       Dashboard
      </router-link>
      <router-link to="/registrar" class="text-white">
       <i class="fa fa-ellipsis-v px-2"></i>Registrar
      </router-link>
      <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>Student 
      </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">    
        <div v-if="openform">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body">
              <div class="form-horizontal">                
                <form role="form" @submit.prevent="submitdata">
                  <div class="card">
                    <h5 class="card-header">Biodata</h5>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 pb-2">
                          <div class="row">
                            <div class="col">
                              <img v-if="ImgPathval" :src="ImgPathval" class="img-fluid" />
                              <img v-else src="img/brand/empty.png" class="img-fluid" />
                            </div>
                            <div class="col">
                              <ImageUploader @emitOutImgPathval="updatePassportVal" />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Surname</label>
                            <input v-model="LastNameval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>First Name</label>
                            <input v-model="FirstNameval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Other Name</label>
                            <input v-model="MiddleNameval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Sex</label>
                            <select v-model="SexIdval" class="form-control">
                              <option value="0"></option>
                              <option value="10">Male</option>
                              <option value="11">Female</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Date of Birth</label>
                            <input type="date" v-model="DOBval" class="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="card">
                    <h5 class="card-header">Guardian</h5>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Guardian Surname</label>
                            <input v-model="GLastNameval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Guardian First Name</label>
                            <input v-model="GFirstnameval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Guardian Other Name</label>
                            <input v-model="GMiddlenameval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Guardian Phone Number</label>
                            <input v-model="GPhoneNoval" class="form-control" required />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Guardian Email Address</label>
                            <input v-model="GEmailval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Full Address</label>
                            <textarea v-model="FullAddressval" class="form-control" rows="3" required></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="card">
                    <h5 class="card-header">Admission</h5>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Admission Number</label>
                            <input v-model="AdmissionNoval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Admission Date</label>
                            <input type="date" v-model="Admissiondateval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Class</label>
                            <select v-model="ClassIdval" @change="getoption()" class="form-control">
                              <option value="0"></option>
                              <option v-for="item in schclass" :key="item.Id" v-bind:value="item.Id">
                                {{ item.Sch_Class }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Option</label>
                            <select v-model="OptionIdval" @change="getarm()" class="form-control">
                              <option value="0"></option>
                              <option v-for="item in optionitems" :key="item.Id" v-bind:value="item.Id">
                                {{ item.ClassOption }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Arm</label>
                            <select v-model="ArmIdval" class="form-control">
                              <option value="0"></option>
                              <option v-for="item in armitems" :key="item.Id" v-bind:value="item.Id">
                                {{ item.SectionName }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="text-center mb-3">
                    <button type="button" @click.stop="closeform()" class="btn btn-secondary">
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-primary my-4">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="text-left">
            <button type="button" @click.prevent="newterm" class="btn btn-default">
              <i class="ni ni-fat-add"></i>Add New
            </button>
              <router-link to="/promotion" class="btn btn-default"
                > <i class="fa fa-caret-square-up"></i> Promote Student</router-link
              >
               <router-link to="/graduation" class="btn btn-default"
                > <i class="fa fa-user-graduate"></i> Graduate Student</router-link
              >
            <router-link to="/deletedstudent" class="btn btn-danger"
                > <i class="fa fa-trash-alt"></i> Deleted Student</router-link
              >
          </div>
          <div class="justify-content-end form-inline my-3">            
            <base-input class="input-group-alternative" placeholder="Search here..." addon-left-icon="fa fa-search"
              v-model="searchval">
            </base-input>
          </div>
          <div class="row">
            <div class="col-sm-4 col" v-for="item in searchedResultQuery" :key="item.Id">
              <div class="card text-center m-2 animate__animated animate__flipInX">
                <div class="card-body bg-success">
                  <div class="row">
                    <div class="col-md-6">
                      <img v-if="item.Passport" :src="item.Passport" class="rounded" alt="" height="50" />
                      <img v-else src="img/brand/loguser.png" class="rounded" alt="" height="50" />
                      <h5 class="text-white"><small>{{ item.StudentName }}</small> </h5>
                      <h6 class="text-white"> <small>{{ item.AdmNo }} | {{ item.Sch_Class }} | {{ item.ClassOption }} | {{ item.SectionName
                      }}</small> </h6>
                      <div>
                        <button class="btn btn-secondary btn-sm my-2" v-on:click="edititem(item.Id)">
                          <i class="fa fa-edit"></i>
                        </button>
                        <button v-if="item.IsDeleted" class="btn btn-default btn-sm"
                          v-on:click="deletestudent(item.Id)">
                          <i class="fa fa-history"></i>
                        </button>
                        <button v-else class="btn btn-danger btn-sm my-2" v-on:click="deletestudent(item.Id)">
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <button class="btn btn-secondary btn-sm my-3" v-on:click="addAchievement(item.Id)">Achievement</button> <br>
                      <button class="btn btn-secondary btn-sm" v-on:click="addNote(item.Id)">Note</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin"
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin"
import ImageUploader from "@/components/ImageUploader"
export default {
  name: "student",
   components: {
        PageSpinner,ImageUploader
    },
   mixins: [CheckApiResponse, CheckStaffUser],
  data() {
    return {
      dataid: 1,
      ImgPathval: "",
      ContactIdval: 1,
      FirstNameval: "",
      MiddleNameval: "",
      LastNameval: null,
      SexIdval: "",
      DOBval: "",
      UserIdval: 1,
      GIdval: 1,
      GContactIdval: 1,
      GUserIdval: 1,
      GPhoneNoval: "",
      GTitleval: "",
      GFirstnameval: "",
      GMiddlenameval: "",
      GLastNameval: "",
      GEmailval: "",
      FullAddressval: "",
      AddressIdval: 1,
      Addressval: "",
      AdmissionNoval: "",
      Admissiondateval: null,
      ClassIdval: "",
      OptionIdval: "",
      ArmIdval: "",
      optionitems: [],
      armitems: [],
      students: null,
      schclass: null,
      file: "",
      uploadedimage: "",
      searchval: "",
      process: "N",
      openform: false,
      inputdisable: false,
      onloader: "none",
    };
  },
  computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.students.filter((item) => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item.StudentName.toLowerCase().includes(v) ||
                item.Sch_Class.toLowerCase().includes(v) ||
                item.AdmNo.toLowerCase().includes(v)
            );
        });
      } else {
        return this.students;
      }
    },
  },
  methods: {
    newterm: function () {
      this.ImgPathval = "";
      this.GPhoneNoval = "";
      this.GTitleval = "";
      this.GFirstnameval = "";
      this.GMiddlenameval = "";
      this.GLastNameval = "";
      this.GEmailval = "";
      this.FirstNameval = "";
      this.FullAddressval = "";
      this.AdmissionNoval = "";
      this.Admissiondateval = null;
      this.ClassIdval = "";
      this.OptionIdval = "";
      this.ArmIdval = "";
      this.MiddleNameval = "";
      this.LastNameval = "";
      this.SexIdval = "";
      this.DOBval = "";
      this.file = "";
      this.uploadedimage = "";
      this.getadmno();
      this.openform = true;
      this.process = "N";
      this.inputdisable = false;
    },
    closeform: function () {
      this.ImgPathval = "";
      this.GPhoneNoval = "";
      this.GTitleval = "";
      this.GFirstnameval = "";
      this.GMiddlenameval = "";
      this.GLastNameval = "";
      this.GEmailval = "";
      this.FirstNameval = "";
      this.Addressval = "";
      this.AdmissionNoval = "";
      this.Admissiondateval = null;
      this.ClassIdval = "";
      this.OptionIdval = "";
      this.ArmIdval = "";
      this.MiddleNameval = "";
      this.LastNameval = "";
      this.SexIdval = "";
      this.DOBval = "";
      this.file = "";
      this.uploadedimage = "";
      this.openform = false;
      this.process = "N";
      this.inputdisable = false;
    },
    updatePassportVal(data) {
      this.ImgPathval = data;
    },
    edititem: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getstudentbyid", Id)
        .then((resp) => {
          this.dataid = resp.Id;
          if (resp.Passport != null) {
            this.uploadedimage = resp.Passport;
          }
          this.ImgPathval = resp.Passport;
          this.ContactIdval = resp.ContactId;
          this.GContactIdval = resp.GContactId;
          this.GUserIdval = resp.GUserId;
          this.GPhoneNoval = resp.GPhoneNo;
          this.GTitleval = resp.GTitle;
          this.GFirstnameval = resp.GFirstName;
          this.GMiddlenameval = resp.GMiddlename;
          this.GLastNameval = resp.GLastName;
          this.GEmailval = resp.GEmaillAdd;
          this.GIdval = resp.GId;
          this.FullAddressval = resp.FullAddress;
          this.FirstNameval = resp.FirstName;
          this.Addressval = resp.AddressOne;
          this.AdmissionNoval = resp.AdmNo;
          this.Admissiondateval = this.frontEndDateFormat(resp.DateAdmited);
          this.ClassIdval = resp.ClassId;
          this.getoption();
          this.OptionIdval = resp.OptionId;
          this.getarm();
          this.ArmIdval = resp.SectionId;
          this.MiddleNameval = resp.MiddleName;
          this.LastNameval = resp.LastName;
          this.SexIdval = resp.SexId;
          this.UserIdval = resp.UserId;
          if (resp.DateofBirth != null || resp.DateofBirth != "") {
            this.DOBval = this.frontEndDateFormat(resp.DateofBirth);
          }

          this.openform = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    deletestudent: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("deletestudent", Id)
        .then(() => {
          this.getstudents();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Processed successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          if (
            err.response.data === "Access Expired" ||
            err.response.data === "Invalid Credential"
          ) {
            this.$store.dispatch("logout");
            this.$router.push("/login");
          } else {
            swal({
              title: "Oops!",
              text: err.response.data,
              icon: "error",
              button: "Ok",
            });
          }
          //this.$store.dispatch('logout')
        });
    },
    submitdata: function () {
      let Id = this.dataid;
      let Passport = this.ImgPathval;
      let ContactId = this.ContactIdval;
      let GContactId = this.GContactIdval;
      let GUserId = this.GUserIdval;
      let GPhoneNo = this.GPhoneNoval;
      let GTitle = this.GTitleval;
      let GFirstname = this.GFirstnameval;
      let GMiddlename = this.GMiddlenameval;
      let GLastName = this.GLastNameval;
      let GEmail = this.GEmailval;
      let GId = this.GIdval;
      let FirstName = this.FirstNameval;
      let FullAddress = this.FullAddressval;
      let AdmissionNo = this.AdmissionNoval;
      let Admissiondate = this.Admissiondateval;
      let ClassId = this.ClassIdval;
      let OptionId = this.OptionIdval;
      let ArmId = this.ArmIdval;
      let MiddleName = this.MiddleNameval;
      let LastName = this.LastNameval;
      let SexId = this.SexIdval;
      let DOB = this.DOBval;
      let UserId = this.UserIdval;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      this.onloader = "flex";
      this.$store
        .dispatch("studentregistration", {
          Id,
          UserId,
          Passport,
          ContactId,
          GContactId,
          GUserId,
          GPhoneNo,
          GTitle,
          GFirstname,
          GMiddlename,
          GLastName,
          GEmail,
          GId,
          FullAddress,
          FirstName,
          AdmissionNo,
          Admissiondate,
          ClassId,
          OptionId,
          ArmId,
          MiddleName,
          LastName,
          SexId,
          DOB,
          processop,
          username,
          accesskey,
        })
        .then((resp) => {
          this.students = resp;
          this.closeform();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getstudents: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getstudents")
        .then((resp) => {
          this.students = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getclass: function () {
      this.$store
        .dispatch("getclass")
        .then((resp) => {
          this.schclass = resp;
        })
        .catch((err) => {
          this.checkresponse(err.response.data);
        });
    },
    getoption: function () {
      let ClassId = this.ClassIdval;
      if (ClassId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getoptionbyclass", ClassId)
          .then((resp) => {
            this.optionitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getarm: function () {
      let ClassId = this.ClassIdval;
      let OptionId = this.OptionIdval;
      let SchArm = "Search";
      let Id = 1;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      this.onloader = "flex";
      let payload = {
        Id,
        ClassId,
        OptionId,
        SchArm,
        processop,
        username,
        accesskey,
      };
      this.$store
        .dispatch("getarmbyoption", payload)
        .then((resp) => {
          this.armitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getadmno: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getadmno")
        .then((resp) => {
          this.AdmissionNoval = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    addNote: function (StudentId) {
      localStorage.setItem("StudentId", StudentId);
      this.$router.push('/noteonstudent');
    },
    addAchievement: function (StudentId) {
      localStorage.setItem("StudentId", StudentId);
      this.$router.push('/studentachiement');
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0];
      this.submitFile();
    },
    submitFile: function () {
      this.onloader = "flex";
      var form = new FormData();
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let formalurl = this.ImgPathval;
      let doctype = "image";
      form.append("username", username);
      form.append("accesskey", accesskey);
      form.append("formalurl", formalurl);
      form.append("doctype", doctype);
      form.append("file", this.file);
      this.$store
        .dispatch("uploadfile", form)
        .then((resp) => {
          this.ImgPathval = resp;
          this.uploadedimage = resp;
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Uploaded successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    frontEndDateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
     this.getstudents();
      this.getclass();
  },
};
</script>

<style>
.inputfile+label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
}

.inputfile:focus+label,
.inputfile+label:hover {
  background-color: red;
}
</style>
