export default {
    methods: {
        GetLibraryBook: function() {
            this.onloader = "flex";
            this.$store
              .dispatch("libraryBook/GetLibraryBook")
              .then(resp => {
                this.LibraryBookItems = resp;
                this.onloader = "none";
              })
              .catch(err => {
                this.onloader = "none";
                this.checkresponse(err.response.data);
              });
          },
    },
    mounted () {
      this.GetLibraryBook()
    }
  }