<template>
<div>
    <div id="loaderoverlay" v-bind:style={display:onloader}>
        <div class="w-100 d-flex justify-content-center align-items-center">
            <div class="loaderspinner"></div>
        </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <router-link to="/dashboard" class="text-white pl-3">
            <i class="ni ni-tv-2"></i> Dashborad</router-link><i class="ni ni-bold-right text-white"></i>
        <router-link to="/smsmenu" class="text-white">
            <i class="ni ni-bold-right"></i> Messages
        </router-link>
        <span class="text-white"><i class="ni ni-bold-right"></i> Track Messages</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
        <card header-classes="bg-transparent">
            <div class="form-inline">               
                <div class=" input-group">
                    <input v-model="searchval" class="form-control" placeholder="Search...">
                    <div class=" input-group-append">
                        <button type="button" class="btn btn-secondary"><i class="fa fa-search"></i> Search </button>
                    </div>
                </div>

            </div>
            <div class="mt-2">
                <div v-for="item in SMS" :key="item.Id">                      
                            <div class="card">                               
                                <div class="card-body">
                                     <h5 class="card-title"> <span class="btn btn-success btn-sm">{{ item.SentDate }}</span> </h5>
                                   <p> {{ item.smsmessage}}</p>
                                </div>
                                <div class="card-footer">
                                    {{ item.smsdetail }}                                   
                                </div>
                            </div>                                                      
                </div>
            </div>
        </card>
    </div>

</div>
</template>

<script>

import swal from 'sweetalert'
export default {
    name: 'Psycomotor',
    data() {
        return {
            dataid: 1,
            SMS: null,          
            searchval: '',
            process: 'N',          
            onloader: 'none'
        }
    },

    methods: {       
       
        tracksms: function () {
            this.onloader = 'flex'
            this.$store.dispatch('tracksms')
                .then((resp) => {
                    this.SMS = resp;
                    this.onloader = 'none'
                })
                .catch(err => {
                    console.log(err);
                    this.onloader = 'none'
                    this.$store.dispatch('logout')
                    this.$router.push('/login')
                })
        },
        getsearch: function () {
            this.onloader = 'flex'
            this.$store.dispatch('getconductsearch', this.searchval)
                .then((resp) => {
                    this.conducts = resp;
                    this.onloader = 'none'
                })
                .catch(err => {
                    console.log(err);
                    this.onloader = 'none'
                    this.$store.dispatch('logout')
                    this.$router.push('/login')
                })
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }

    },
    mounted() {
        if (this.$store.state.auth.user_status_id != 1) {
            this.$store.dispatch('logout')
            this.$router.push('/login')
        } else {
            this.tracksms();
        }

    }
};
</script>

<style></style>
