import swal from "sweetalert";
export default {
    methods: {
        checkresponse (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            } else {
              swal({
                title: "Oops!",
                text: resp,
                icon: "error",
                button: "Ok",
              });
            }
          },
    },    
  }