<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white"> Dashboard</router-link>
      <router-link to="/academic" class="text-white pl-3">
        <i class="fa fa-ellipsis-v px-2"></i>Academic</router-link
      >
      <span class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Entrance Exam
      </span>
    </base-header>
    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-6">
              <div class="card bg-secondary border-0 mb-0">
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                      <div class="card bg-gradient-success">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Session</label>
                                <select
                                  v-model="SessionIdval"
                                  class="form-control"
                                >
                                  <option value=""></option>
                                  <option
                                    v-for="item in Sessionitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.SchSession }}
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Class</label>
                                <select
                                  v-model="ClassIdval"
                                  class="form-control"
                                  @change="getschentrance()"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in classitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.Sch_Class }}
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white"
                                  >Exam Period (In Seconds)</label
                                >
                                <input
                                  type="number"
                                  min="0"
                                  v-model="ExamPeriodval"
                                  class="form-control"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white"
                                  >No. of Question to Display</label
                                >
                                <input
                                  type="number"
                                  min="0"
                                  v-model="NoofQuestiontoDisplayval"
                                  class="form-control"
                                />
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label class="text-white">Caution Note</label>
                                <ckeditor
                                  v-model="CautionNoteval"
                                  :editorUrl="$store.state.appdatas.editorUrl"
                                ></ckeditor>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center mb-3">
                        <button type="submit" class="btn btn-primary my-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="table-responsive mt-2">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <td>Access Code</td>
                      <td>No of Question to Display</td>
                      <td>Caution Note</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in cbtheaderitem" :key="item.Id">
                      <td>{{ item.PassCode }}</td>
                      <td>{{ item.NoofQuestiontoDisplay }}</td>
                      <td>
                        <div v-html="item.CautionNote"></div>
                      </td>
                      <td>
                        <button
                          class="btn btn-secondary btn-sm"
                          v-on:click="edititem(item.Id)"
                        >
                          <i class="fa fa-edit"></i> Edit
                        </button>

                        <button
                          class="btn btn-secondary btn-sm"
                          v-on:click="openquestion(item.Id)"
                        >
                          <i class="ni ni-caps-small"></i> View Questions
                        </button>

                        <button
                          class="btn btn-secondary btn-sm"
                          v-on:click="openstudentanswer(item.Id)"
                        >
                          <i class="fa fa-users"></i> View Student Answer
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </card>
      </div>

      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner";
import CkEditorUploadAdapter from "@/mixins/CkEditorUploadAdapter.mixin";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "questionentrance",
  mixins: [CkEditorUploadAdapter],
  components: {
    PageSpinner,
  },
  data() {
    return {
      dataid: 1,
      ClassIdval: "",
      SessionIdval: "",
      ExamPeriodval: "",
      CautionNoteval: "",
      NoofQuestiontoDisplayval: "",
      Sessionitems: null,
      termitems: null,
      classitems: null,
      cbtheaderitem: null,
      process: "N",
      onloader: "none",
      editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [this.uploadPlugin],
      },
    };
  },
  methods: {
    openquestion: function (Id) {
      localStorage.setItem("SchentranceheaderId", Id);
      this.$router.push("/schentrancequestion");
    },
    openstudentanswer: function (Id) {
      localStorage.setItem("SchentranceheaderId", Id);
      this.$router.push("/entrancestudentanswer");
    },
    edititem: function (Id) {
      this.dataid = Id;
      this.ExamPeriodval = "";
      this.CautionNoteval = "";
      this.NoofQuestiontoDisplayval = "";
      this.process = "U";
      this.onloader = "flex";
      this.$store
        .dispatch("getschentrancebyid", Id)
        .then((resp) => {
          if (resp != null) {
            this.ExamPeriodval = resp.ExamPeriod;
            this.CautionNoteval = resp.CautionNote;
            this.NoofQuestiontoDisplayval = resp.NoofQuestiontoDisplay;
          }
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata: function () {
      let Id = this.dataid;
      let ClassId = this.ClassIdval;
      let ExamPeriod = this.ExamPeriodval;
      let NoofQuestiontoDisplay = this.NoofQuestiontoDisplayval;
      let CautionNote = this.CautionNoteval;
      let SessionId = this.SessionIdval;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        ClassId,
        ExamPeriod,
        NoofQuestiontoDisplay,
        CautionNote,
        SessionId,
        processop,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("processschentrance", userdata)
        .then((resp) => {
          this.cbtheaderitem = resp;
          this.ExamPeriodval = "";
          this.CautionNoteval = "";
          this.NoofQuestiontoDisplayval = "";
          this.process = "N";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getschentrance: function () {
      let Id = 1;
      let ClassId = this.ClassIdval;
      let SessionId = this.SessionIdval;
      let ExamPeriod = 1;
      let NoofQuestiontoDisplay = 1;
      let CautionNote = "1";
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        ClassId,
        ExamPeriod,
        NoofQuestiontoDisplay,
        CautionNote,
        SessionId,
        processop,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("getschentrance", userdata)
        .then((resp) => {
          this.cbtheaderitem = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getregsession: function () {
      this.Sessionitems = null;
      this.subjectitems = null;
      this.cbtheaderitem = null;
      this.SessionIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getregsession")
        .then((resp) => {
          this.Sessionitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/home");
        });
    },
    getclassinclassassigned: function () {
      this.classitems = null;
      this.cbtheaderitem = null;
      this.ClassIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getclassinclassassigned")
        .then((resp) => {
          this.classitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/home");
        });
    },
    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.auth.user_status_id != 1) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    } else {
      this.getregsession();
      this.getclassinclassassigned();
    }
  },
};
</script>

<style></style>
