<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link>
      <span class="text-white"> <i class="fa fa-ellipsis-v px-2"></i>Support Staff
      </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div v-if="openform">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body">
              <div class="form-horizontal">
                <form role="form" @submit.prevent="submitdata">
                  <div class="card bg-gradient-success">                   
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 pb-2">
                          <img v-if="uploadedimage" height="100" :src="uploadedimage" />
                          <img v-else height="100" src="img/brand/empty.png" />
                          <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" />
                          <br />
                          <button class="btn btn-outline-secondary" @click.prevent="submitFile()">
                            <i class="ni ni-cloud-upload-96"></i>Upload
                          </button>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Surname</label>
                            <input v-model="LastNameval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">First Name</label>
                            <input v-model="FirstNameval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Other Name</label>
                            <input v-model="MiddleNameval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Email Address</label>
                            <input type="email" v-model="EmailAddressval" class="form-control" required />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Phone No.</label>
                            <input type="tel" v-model="PhoneNoval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Sex</label>
                            <select v-model="SexIdval" class="form-control">
                              <option value="0"></option>
                              <option value="10">Male</option>
                              <option value="11">Female</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Marital Status</label>
                            <select v-model="MaritalStatusIdval" class="form-control">
                              <option value="0"></option>
                              <option value="12">Married</option>
                              <option value="13">Single</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">User Status</label>
                            <select v-model="UserStatusIdval" class="form-control">
                              <option value="0"></option>
                              <option v-for="item in userStatusitems" :key="item.Id" v-bind:value="item.Id">
                                {{ item.StatusName }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div class="text-center mb-3">
                    <button type="button" @click.stop="closeform()" class="btn btn-secondary">
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-primary my-4">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="justify-content-end form-inline">
            <button type="button" @click.prevent="newterm" class="btn btn-default">
              <i class="ni ni-fat-add"></i>Add New
            </button>
            <base-input class="input-group-alternative" placeholder="Search here..." addon-left-icon="fa fa-search"
              v-model="searchval">
            </base-input>
          </div>
          <div class="mt-2 row">
            <div class="col-md-3" v-for="item in searchedResultQuery" :key="item.Id">
              <div class="card animate__animated animate__flipInX text-center m-2">
                <div class="card bg-success shrink my-3">
                  <div class="card-body text-center">
                    <img v-if="item.Passport" :src="item.Passport" class="rounded" alt="" height="50" />
                    <img v-else src="img/brand/loguser.png" class="rounded" alt="" height="50" />
                    <h5 class="text-white">{{ item.FullName }}</h5>
                    <h6 class="text-white">{{ item.StatusName }}</h6>
                    <h6 class="text-white"><small>{{ item.EmailAddress }} || {{ item.PhoneNo }}</small> </h6>
                    <div class="text-center">
                      <button class="btn btn-secondary btn-sm my-2" v-on:click="edititem(item.Id)">
                        <i class="fa fa-edit"></i>
                      </button>
                      <button v-if="item.IsDeleted" class="btn btn-default btn-sm" v-on:click="deletestaff(item.Id)">
                        <i class="fa fa-trash-restore"></i>
                      </button>
                      <button v-else class="btn btn-danger btn-sm my-2" v-on:click="deletestaff(item.Id)">
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin"
export default {
  name: "supportstaff",
  mixins: [CheckApiResponse],
  components: {
    PageSpinner
  },
  data() {
    return {
      dataid: 1,
      Passportval: "",
      ContactIdval: 1,
      FirstNameval: "",
      MiddleNameval: "",
      LastNameval: "",
      PhoneNoval: "",
      EmailAddressval: "",
      SexIdval: 0,
      CategoryIdval: null,
      UserStatusIdval: 0,
      file: "",
      uploadedimage: "",
      staffs: null,
      searchval: "",
      process: "N",
      openform: false,
      inputdisable: false,
      onloader: "none",
    };
  },
  computed: {
    ...mapState({
      stateitems: (state) => state.addressinfo.stateitems,
      userStatusitems: (state) => state.usersdata.userStatus,
    }),
    searchedResultQuery() {
      if (this.searchval) {
        return this.staffs.filter((item) => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item.FullName.toLowerCase().includes(v) ||
                item.EmailAddress.toLowerCase().includes(v) ||
                item.PhoneNo.toLowerCase().includes(v)
            );
        });
      } else {
        return this.staffs;
      }
    },
  },
  methods: {
    newterm () {
      this.Passportval = "";
      this.FirstNameval = "";
      this.EmailAddressval = "";
      this.PhoneNoval = "";
      this.MiddleNameval = "";
      this.LastNameval = "";
      this.SexIdval = 0;
      this.CategoryIdval = 0;
      this.MaritalStatusIdval = 0;
      this.file = "";
      this.uploadedimage = "";
      this.openform = true;
      this.process = "N";
    },
    closeform () {
      this.Passportval = "";
      this.FullAddressval = "";
      this.FirstNameval = "";
      this.EmailAddressval = "";
      this.PhoneNoval = "";
      this.CategoryIdval = null;
      this.MiddleNameval = "";
      this.LastNameval = "";
      this.SexIdval = 0;
      this.CategoryIdval = 0;
      this.MaritalStatusIdval = 0;
      this.UserStatusIdval = 0
      this.file = "";
      this.uploadedimage = "";
      this.openform = false;
      this.process = "N";
    },
    edititem (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("GetCompanyStaffById", Id)
        .then((resp) => {
          console.log(resp)
          this.dataid = resp.Id;
          if (resp.Passport != null) {
            this.uploadedimage = resp.Passport;
          }
          this.Passportval = resp.Passport;
          this.ContactIdval = resp.ContactId;
          this.FirstNameval = resp.FirstName;
          this.CategoryIdval = resp.CategoryId;
          this.MiddleNameval = resp.MiddleName;
          this.LastNameval = resp.LastName;
          this.EmailAddressval = resp.EmailAddress;
          this.PhoneNoval = resp.PhoneNo;
          this.SexIdval = resp.SexId;
          this.UserStatusIdval = resp.Status_Id
          this.MaritalStatusIdval = resp.MaritalStatusId;
          this.openform = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    deletestaff (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("DeleteCompanyStaff", Id)
        .then(() => {
          this.getstaffs()
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Processed successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata () {
      let Id = this.dataid;
      let Passport = this.Passportval;
      let ContactId = this.ContactIdval;
      let FirstName = this.FirstNameval;
      let CategoryId = this.CategoryIdval;
      let MiddleName = this.MiddleNameval;
      let LastName = this.LastNameval;
      let EmailAddress = this.EmailAddressval;
      let MaritalStatusId = this.MaritalStatusIdval;
      let PhoneNo = this.PhoneNoval;
      let SexId = this.SexIdval;
      let StatusId = this.UserStatusIdval
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      this.onloader = "flex";
      let userdata = {
        Id,
        Passport,
        ContactId,
        MaritalStatusId,
        CategoryId,
        FirstName,
        MiddleName,
        LastName,
        EmailAddress,
        PhoneNo,
        SexId,
        StatusId,
        processop,
        username,
        accesskey,
      };
      this.$store
        .dispatch("ProcessCompanyStaff", userdata)
        .then((resp) => {
          this.staffs = resp;
          this.closeform();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getstaffs () {
      this.onloader = "flex";
      this.$store
        .dispatch("GetCompanyStaffs")
        .then((resp) => {
          this.staffs = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0];
    },
    submitFile () {
      this.onloader = "flex";
      var form = new FormData();
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let formalurl = this.Passportval;
      let doctype = "image";
      form.append("username", username);
      form.append("accesskey", accesskey);
      form.append("formalurl", formalurl);
      form.append("doctype", doctype);
      form.append("file", this.file);
      this.$store
        .dispatch("uploadadminfile", form)
        .then((resp) => {
          this.Passportval = resp;
          this.uploadedimage = resp;
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Uploaded successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    frontEndDateFormat (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.auth.user_status_id == 6) {
      this.getstaffs();
    } else {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    }
  },
};
</script>

<style>
</style>
