<template>
<div>
    <div id="loaderoverlay" v-bind:style={display:onloader}>
        <div class="w-100 d-flex justify-content-center align-items-center">
            <div class="loaderspinner"></div>
        </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <router-link to="/dashboard" class="text-white pl-3">
            <i class="ni ni-tv-2"></i> Dashborad</router-link><i class="ni ni-bold-right text-white"></i>
        <router-link to="/studreport" class="text-white">
            <i class="ni ni-badge"></i> Report
        </router-link>
        <span class="text-white"><i class="ni ni-bold-right"></i> Report Card (Full Term)</span>
    </base-header>
    <!--Charts-->
    <div class="container-fluid mt--7">
        <div class="mb-5 mb-xl-0">
            <card header-classes="bg-transparent">
                <div class="card bg-secondary border-0 mb-0">
                    <div class="card-body">
                        <div class="form-inline">
                            <div class="row">
                                <div class="col-md-6 pt-3">
                                    <div class="input-group">
                                        <label class="input-group-text">Session</label>
                                        <select v-model="SessionIdval" @change="getregterm()" class="form-control btn btn-success">
                                            <option value=""></option>
                                            <option v-for="item in Sessionitems" :key="item.Id" v-bind:value="item.Id">
                                                {{item.SchSession}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6 pt-3">
                                    <div class="input-group">
                                        <label class="input-group-text">Term</label>
                                        <select v-model="TermIdval" @change="getreport()" class="form-control btn btn-success">
                                            <option value="0"></option>
                                            <option v-for="item in termitems" :key="item.Id" v-bind:value="item.Id">
                                                {{item.SchoolTerm}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="reportcard != null">
                    <div id="divReport">
                        <div v-html="reportcard"></div>
                    </div>
                    <div class="text-center my-3">
                        <button class="btn btn-default" @click.prevent="printreport()"><i class="fa fa-print"></i> Print</button>
                    </div>
                </div>

            </card>
        </div>
    </div>
</div>
</template>

<script>

import moment from 'moment'
import swal from 'sweetalert'
export default {
    name: 'reportcard',
    data() {
        return {
            dataid: 1,
            SessionIdval: '',
            TermIdval: '',
            StudIdval: localStorage.getItem('StudId') || '',
            headteacherval: '',
            headteachercommval: '',
            gradeimagepath: 'img/brand/mark.png',
            Sessionitems: null,
            termitems: null,
            studentitems: null,
            schooldetailsitem: null,
            reportcard: null,
            studrep: null,
            currdate: null,
            process: 'N',
            onloader: 'none',
            isopencomment: false
        }
    },
    methods: {
        
        printreport: function () {
            // Get HTML to print from element

            var printContents = document.getElementById('divReport').innerHTML;
            var originalContents = document.body.innerHTML;

            document.body.innerHTML = printContents;

            window.print();

            document.body.innerHTML = originalContents;

        },
        getreport: function () {
            this.reportcard = null;
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;           
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                StudId,
                SessionId,
                TermId,               
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('getreport', userdata)
                .then(resp => {
                    this.reportcard = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })

        },
        getregsession: function () {
           
            this.onloader = 'flex'
            this.$store.dispatch('getsessionforstudentreport', this.StudIdval)
                .then(resp => {
                    this.Sessionitems = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getregterm: function () {           
            this.reportcard=null;
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let userdata = {
                StudId,
                SessionId
            }
            this.TermIdval = 0;
            if (SessionId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('gettermforstudentreport', userdata)
                    .then(resp => {
                        this.termitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },       
        frontEndDateFormat: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
        backEndDateFormat: function (date) {
            return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                console.log(resp);   
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });             
            }
        }

    },
    mounted() {
        console.log('Status : '+this.$store.state.auth.user_status_id)  
        if (this.$store.state.auth.user_status_id == 2 || this.$store.state.auth.user_status_id == 8) {   
                       
            let passcode = this.$route.query.rep;
            if(passcode!=null || passcode!=undefined){
                this.onloader = 'flex'  
                console.log(passcode)             
                this.$store.dispatch('getreportcardbypasscode',passcode)
                    .then(resp => {
                        this.StudIdval=resp.StudId;                     
                        this.getregsession();                         
                        this.SessionIdval=resp.SessionId;
                        this.getregterm();
                        this.TermIdval=resp.TermId;
                        this.getreport();                       
                        this.onloader = 'none';                   
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        swal({
                            title: "Oops!",
                            text: err.response.data,
                            icon: "error",
                            button: "Ok",
                        });
                    })
            } else{
                this.getregsession();  
            }        
           
        } else {
             console.log('I\'m not allow to login ')  
            this.$store.dispatch('logout')
            this.$router.push('/login')

        }

    }
};
</script>

<style>
.borderall {
    text-align: center;
    border: 3px solid #000;
}

.bordercol {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    border: thin solid #000;
}

.padbody {
    padding: 20px;
}

.padhead {
    padding: 5px;
    border: 3px solid #606060;
}

.pad {
    padding: 10px;
}

table {
    font-size: 9pt;
    width: 100%;
}

.bodytext {
    font-size: 9pt;
}
</style>
