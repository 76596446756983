<template>
<div>
    <div id="loaderoverlay" v-bind:style={display:onloader}>
        <div class="w-100 d-flex justify-content-center align-items-center">
            <div class="loaderspinner"></div>
        </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <router-link to="/dashboard" class="text-white pl-3">
            <i class="ni ni-tv-2"></i> Dashborad</router-link><i class="ni ni-bold-right text-white"></i>
        <router-link to="/reportmenu" class="text-white">
            <i class="ni ni-badge"></i> Report
        </router-link>
        <span class="text-white"><i class="ni ni-bold-right"></i> Registered Student</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">

        <div class="mb-5 mb-xl-0">
            <card header-classes="bg-transparent">
                <div class="card bg-secondary border-0 mb-0">
                    <div class="card-body">
                        <div class="form-inline">
                            <div class="row">
                                <div class="col-md-3 pt-3">
                                    <div class="input-group">
                                        <span class="input-group-text">Class</span>
                                        <select v-model="ClassIdval" class="form-control btn btn-success" @change="getoption($event)">
                                            <option value="0"></option>
                                            <option v-for="item in schclass" :key="item.Id" v-bind:value="item.Id">
                                                {{item.Sch_Class}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3 pt-3">
                                    <div class="input-group">
                                        <span class="input-group-text">Option</span>
                                        <select v-model="OptionIdval" class="form-control btn btn-success" @change="getarm($event)">
                                            <option value="0"></option>
                                            <option v-for="item in optionitems" :key="item.Id" v-bind:value="item.Id">
                                                {{item.ClassOption}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3 pt-3">
                                    <div class="input-group">
                                        <label class="input-group-text">Arm</label>
                                        <select v-model="ArmIdval" class="form-control btn btn-success" @change="getstudentbyarm()">
                                            <option value="0"></option>
                                            <option v-for="item in armitems" :key="item.Id" v-bind:value="item.Id">
                                                {{item.SectionName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3 pt-3">
                                    <input v-model="searchval" class="form-control" @keyup="getsearch" placeholder="Search any text...">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="studentitems">
                    <div id="divReport">
                        <div class=" bg-light padhead">
                            <div class="text-center">
                                <img v-if="reportheaderrep.Logo" :src="$store.state.auth.baseurl +reportheaderrep.Logo.substr(1)" alt="" height="30" />
                                <img v-else src="img/brand/nologo.jpg" alt="" height="30" />
                                <h1>{{reportheaderrep.Org_Name}}</h1>
                                <h5>{{reportheaderrep.OrgAddress}}, {{reportheaderrep.Phone_No}}, {{reportheaderrep.Email_Add}}, {{reportheaderrep.Web_Add}}</h5>
                            </div>
                        </div>
                        <div class="borderall padbody">
                            <h5 class="text-center">
                                REGISTERED STUDENTS
                            </h5>
                            <h6>Generated on {{currdate}}</h6>
                            <br />
                            <table class="table table-bordered table-striped table-hover text-left">
                                <thead>
                                    <tr>
                                        <td>Admission Number</td>
                                        <td>Student Name</td>
                                        <td>Guardian</td>
                                        <td>Date Admited</td>
                                        <td>Class</td>
                                        <td>Option</td>
                                        <td>Arm</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in studentitems" :key="item.Id">
                                        <td>{{ item.AdmNo }}</td>
                                        <td>{{ item.StudentName }}</td>
                                        <td>{{ item.GName }}</td>
                                        <td>{{item.DateAdmited | formatDate }}</td>
                                        <td>{{ item.Sch_Class }}</td>
                                        <td>{{item.ClassOption }}</td>
                                        <td>{{ item.SectionName }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-default" @click.prevent="printreport()"><i class="fa fa-print"></i> Print</button>
                        <button class="btn btn-default"><i class="fa fa-file-excel"></i> Export</button>
                    </div>
                </div>
            </card>
        </div>

        <!-- End charts-->

    </div>

</div>
</template>

<script>
import {
    mapState
} from 'vuex'
import moment from 'moment'
import swal from 'sweetalert'
export default {
    name: 'reportcard',
    data() {
        return {
            ClassIdval: '',
            OptionIdval: '',
            ArmIdval: '',
            optionitems: null,
            armitems: null,
            studentitems: null,
            reportheaderrep: null,
            currdate: null,
            onloader: 'none'
        }
    },
    computed: {
        ...mapState({
            schclass: state => state.schclass.schclass,
        })
    },
    methods: {
        getcurendate: function () {
            var today = new Date();
            this.currdate = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
        },
        printreport: function () {
            // Get HTML to print from element
            const prtHtml = document.getElementById('divReport').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                ${prtHtml}
            </body>
            </html>`);

            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
        },
        getclass: function () {
            this.optionitems = null;
            this.armitems = null;
            this.ClassIdval = 0;
            this.onloader = 'flex'
            this.$store.dispatch('getclass')
                .then(() => {
                    this.onloader = 'none';
                })
                .catch(err => {
                    console.log(err);
                    this.onloader = 'none'
                    this.$store.dispatch('logout')
                    this.$router.push('/home')
                })
        },
        getoption: function () {
            this.optionitems = null;
            this.armitems = null;
            this.OptionIdval = 0;
            let ClassId = this.ClassIdval;
            if (ClassId > 0) {
                this.getstudentbyclass();
                this.onloader = 'flex'
                this.$store.dispatch('getoption', ClassId)
                    .then(resp => {
                        this.optionitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        if (err.response.data === "Access Expired" || err.response.data === "Invalid Credential") {
                            this.$store.dispatch('logout');
                            this.$router.push('/login')
                        } else {
                            swal({
                                title: "Oops!",
                                text: err.response.data,
                                icon: "error",
                                button: "Ok",
                            });
                        }
                    })
            }
        },
        getarm: function () {
            this.armitems = null;
            this.ArmIdval = 0;
            let OptionId = this.OptionIdval;
            if (OptionId > 0) {
                this.getstudentbyoption();
                this.onloader = 'flex'
                this.$store.dispatch('getarm', OptionId)
                    .then(resp => {
                        this.armitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        if (err.response.data === "Access Expired" || err.response.data === "Invalid Credential") {
                            this.$store.dispatch('logout');
                            this.$router.push('/login')
                        } else {
                            swal({
                                title: "Oops!",
                                text: err.response.data,
                                icon: "error",
                                button: "Ok",
                            });
                        }
                    })
            }
        },
        getsearch: function () {
            this.$store.dispatch('getstudentssearch', this.searchval)
                .then((resp) => {
                    this.studentitems = resp;
                })
                .catch(err => {
                    this.onloader = 'none'
                    if (err.response.data === "Access Expired" || err.response.data === "Invalid Credential") {
                        this.$store.dispatch('logout');
                        this.$router.push('/login')
                    } else {
                        swal({
                            title: "Oops!",
                            text: err.response.data,
                            icon: "error",
                            button: "Ok",
                        });
                    }
                })
        },
        getstudentbyclass: function () {
            this.onloader = 'flex'
            let Id = 1;
            let StudId = 1;
            let ClassId = this.ClassIdval;
            let ArmId = 1;
            let OptionId = 1;
            let SubjectId = 1;
            let SessionId = 1;
            let TermId = 1;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                Id,
                StudId,
                ClassId,
                ArmId,
                OptionId,
                SubjectId,
                SessionId,
                TermId,
                username,
                accesskey
            };
            this.$store.dispatch('getstudentbyclass', userdata)
                .then(resp => {
                    this.studentitems = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    if (err.response.data === "Access Expired" || err.response.data === "Invalid Credential") {
                        this.$store.dispatch('logout');
                        this.$router.push('/login')
                    } else {
                        swal({
                            title: "Oops!",
                            text: err.response.data,
                            icon: "error",
                            button: "Ok",
                        });
                    }
                })
        },
        getstudentbyoption: function () {
            let Id = 1;
            let StudId = 1;
            let ClassId = this.ClassIdval;
            let ArmId = 1;
            let OptionId = this.OptionIdval;
            let SubjectId = 1;
            let SessionId = 1;
            let TermId = 1;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                Id,
                StudId,
                ClassId,
                ArmId,
                OptionId,
                SubjectId,
                SessionId,
                TermId,
                username,
                accesskey
            };
            this.onloader = 'flex'
            this.$store.dispatch('getstudentbyoption', userdata)
                .then(resp => {
                    this.studentitems = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    if (err.response.data === "Access Expired" || err.response.data === "Invalid Credential") {
                        this.$store.dispatch('logout');
                        this.$router.push('/login')
                    } else {
                        swal({
                            title: "Oops!",
                            text: err.response.data,
                            icon: "error",
                            button: "Ok",
                        });
                    }
                })
        },
        getstudentbyarm: function () {
            let Id = 1;
            let StudId = 1;
            let ClassId = this.ClassIdval;
            let ArmId = this.ArmIdval;
            let OptionId = this.OptionIdval;
            let SubjectId = 1;
            let SessionId = 1;
            let TermId = 1;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                Id,
                StudId,
                ClassId,
                ArmId,
                OptionId,
                SubjectId,
                SessionId,
                TermId,
                username,
                accesskey
            };
            this.onloader = 'flex'
            this.$store.dispatch('getstudentbyarm', userdata)
                .then(resp => {
                    this.studentitems = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    if (err.response.data === "Access Expired" || err.response.data === "Invalid Credential") {
                        this.$store.dispatch('logout');
                        this.$router.push('/login')
                    } else {
                        swal({
                            title: "Oops!",
                            text: err.response.data,
                            icon: "error",
                            button: "Ok",
                        });
                    }
                })
        },
        getschooldetails: function () {
            this.onloader = 'flex'
            this.$store.dispatch('getschooldetails')
                .then(resp => {
                    this.reportheaderrep = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    if (err.response.data === "Access Expired" || err.response.data === "Invalid Credential") {
                        this.$store.dispatch('logout');
                        this.$router.push('/login')
                    } else {
                        swal({
                            title: "Oops!",
                            text: err.response.data,
                            icon: "error",
                            button: "Ok",
                        });
                    }
                })
        },

        frontEndDateFormat: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
        backEndDateFormat: function (date) {
            return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }, 
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }

    },
    mounted() {
        this.getschooldetails();
        this.getclass();
        this.getcurendate();
    }
};
</script>

<style>
.borderall {
    text-align: center;
    border: 3px solid #000;
}

.bordercol {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    border: thin solid #000;
}

.padbody {
    padding: 20px;
}

.padhead {
    padding: 5px;
    border: 3px solid #606060;
}

.pad {
    padding: 10px;
}

table {
    font-size: 9pt;
    width: 100%;
}

.bodytext {
    font-size: 9pt;
}
</style>
