<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      ><i class="ni ni-bold-right text-white"></i>
      <router-link to="/setting" class="text-white"> Settings </router-link>
      <span class="text-white"><i class="ni ni-bold-right"></i> Skill</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="row">
          <div class="col-md-4">
            <div class="card bg-secondary mb-0">
               <div class="card-header">
                <h3 class="card-title">Registration</h3>
              </div>
              <div class="card-body">
                <div class="form-horizontal">
                  <form role="form" @submit.prevent="submitdata">
                    <div class="form-inline">                    
                      <input v-model="skillval" class="form-control" placeholder="Skills Name" />
                       <button type="submit" class="btn btn-primary">
                        Submit
                      </button>
                    </div>                   
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
           <div class="justify-content-end form-inline">
              <base-input
                class="input-group-alternative"
                placeholder="Search here..."
                addon-left-icon="fa fa-search"
                v-model="searchval"
              >
              </base-input>
            </div>
            <div class="table-responsive mt-2">
              <table
                class="table table-dark table-bordered table-striped table-hover"
              >
                <thead>
                  <tr>
                    <th>Skill</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in searchedResultQuery" :key="item.Id">
                    <td>{{ item.Skill }}</td>
                    <td>
                      <button
                        class="btn btn-secondary btn-sm"
                        v-on:click="edititem(item.Id)"
                      >
                        <i class="fa fa-edit"></i> Edit
                      </button>
                      <button
                        class="btn btn-danger btn-sm"
                        v-on:click="deleteitem(item.Id)"
                      >
                        <i class="ni ni-fat-remove"></i> Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
export default {
  name: "Psycomotor",
  components: {
    PageSpinner
  },
  data() {
    return {
      dataid: 1,
      skillval: "",
      skillitems: "",
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none",
    };
  },
 computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.skillitems.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.Skill.toLowerCase().includes(v) 
            );
        });
      } else {
        return this.skillitems;
      }
    }
  },
  methods: {
    newterm: function () {
      this.skillval = "";
      this.openform = true;
      this.process = "N";
    },
    closeform: function () {
      this.skillval = "";
      this.openform = false;
      this.process = "N";
    },
    edititem: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getskillbyid", Id)
        .then((resp) => {
          this.dataid = Id;
          this.skillval = resp.Skill;
          this.openform = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          //this.$store.dispatch('logout')
        });
    },
    deleteitem: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getskillbyid", Id)
        .then((resp) => {
          this.dataid = Id;
          this.skillval = resp.Skill;
          this.process = "D";
          this.submitdata();
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          //this.$store.dispatch('logout')
        });
    },
    submitdata: function () {
      let SkillName = this.skillval;
      let Id = this.dataid;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      this.onloader = "flex";
      let payload = {
        Id,
        SkillName,
        processop,
        username,
        accesskey,
      };
      this.$store
        .dispatch("skillregistration", payload)
        .then((resp) => {
          this.skillitems = resp;
          this.closeform();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getskill: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getskill")
        .then((resp) => {
          this.skillitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    getsearch: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getskillsearch", this.searchval)
        .then((resp) => {
          this.skillitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.auth.user_status_id != 1) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    } else {
      this.getskill();
    }
  },
};
</script>

<style></style>
