<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link
      >
      <router-link to="/library" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Library
      </router-link>
      <span class="text-white"
        > <i class="fa fa-ellipsis-v px-2"></i>Library Book Stock Summary</span
      >
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
         <div class="justify-content-end form-inline mt-3">
              <base-input
                class="input-group-alternative"
                placeholder="Search here..."
                addon-left-icon="fa fa-search"
                v-model="searchval"
              >
              </base-input>
            </div>
            <div class="table-responsive mt-2">
              <table
                class="table table-dark table-bordered table-striped table-hover"
              >
                <thead>
                  <tr>
                    <th>Book</th>
                    <th>Book Edition</th>
                    <th>Category</th>
                    <th>Qty In Stock</th>
                    <th>Qty Borrowed</th>
                    <th>Qty Remain</th>                   
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in searchedResultQuery" :key="item.Id">
                    <td>{{ item.BookTitle }}</td>
                    <td>{{ item.BookEdition }}</td>
                    <td>{{ item.Category }}</td>
                    <td>{{ item.QtyInStock }}</td>
                    <td>{{ item.QtyBorrowed }}</td>
                    <td>{{ item.QtyRemain }}</td>                    
                  </tr>
                </tbody>
              </table>
            </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import DateFormaterMixin from "@/mixins/DateFormater.mixin";
import libraryBookStockSummaryMixin from "@/mixins/libraryBookStockSummary.mixin";
import PageSpinner from "@/components/PageSpinner";

export default {
  name: "LibraryBookReturn",
  mixins: [DateFormaterMixin, libraryBookStockSummaryMixin],
  components: {
    PageSpinner
  },
  data() {
    return {
      LibraryBookStockSummaryItems: null,
      searchval: '',
      onloader: "none"
    };
  },
  computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.LibraryBookStockSummaryItems.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              v =>               
                item.BookTitle.toLowerCase().includes(v) ||
                item.BookEdition.toLowerCase().includes(v) ||
                item.Category.toLowerCase().includes(v) 
            );
        });
      } else {
        return this.LibraryBookStockSummaryItems;
      }
    }
  },
  methods: {   
    checkresponse(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok"
        });
      }
    }
  },
  mounted() {
    if (this.$store.state.auth.user_status_id != 1) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    } 
  }
};
</script>

<style></style>
