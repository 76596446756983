<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard
      </router-link>
      <router-link to="/subscription" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i> Subscription
      </router-link>
      <span class="text-white"> <i class="fa fa-ellipsis-v px-2"></i>Report Card and CBT Subscription</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-4">
              <div class="card bg-secondary border-0 mb-0">
                <div class="card-body">
                  <div class="form-horizontal">
                    <div class="card bg-gradient-success">
                      <div class="card-body">
                        <div class="form-group">
                          <label class="text-white">Session</label>
                          <select v-model="SessionIdval" class="form-control" @change="getregterm()">
                            <option value=""></option>
                            <option v-for="item in Sessionitems" :key="item.Id" v-bind:value="item.Id">
                              {{ item.SchSession }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group" v-if="SessionIdval > 0">
                          <label class="text-white">Term</label>
                          <select v-model="TermIdval" class="form-control"
                            @change="searchstudentforreportcardsubscription()">
                            <option value="0"></option>
                            <option v-for="item in termitems" :key="item.Id" v-bind:value="item.Id">
                              {{ item.SchTerm }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div v-if="TermIdval">
                      <div class="form-group text-center" v-if="studentitems">
                        <h2 class="text-white">
                          <span class=" badge badge-primary">{{
                            studentitems.TotalAmount
                          }}</span>
                        </h2>
                        <div>
                          <button v-if="hasProcessTransaction == true" class="btn btn-warning shrink m-2"
                            @click="asyncPay">Click here to confirm payment <i class="ivu-icon-md-refresh"></i>
                          </button>

                          <button class="btn btn-info shrink" @click="asyncPay">Click here to make payment <i
                              class="fa fa-arrow-right"></i> </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8" v-if="TermIdval">
              <div class=" input-group">
                <input v-model="searchval" class="form-control" @keyup="searchstudentforreportcardsubscription"
                  placeholder="Search..." />
                <div class=" input-group-append">
                  <button type="button" @click.prevent="searchstudentforreportcardsubscription"
                    class="btn btn-secondary">
                    <i class="fa fa-search"></i> Search
                  </button>
                </div>
              </div>
              <div class="row" v-if="studentitems">
                <div class="col-md-4" v-for="item in studentitems.StudentList" :key="item.Id">
                  <div class="card text-center m-2 animate__animated animate__flipInX">
                    <div class="card-body bg-success">
                      <img v-if="item.Passport" :src="item.Passport" class="rounded" alt="" height="50" />
                      <img v-else src="img/brand/loguser.png" class="rounded" alt="" height="50" />
                      <h5 class="text-white"><small>{{ item.StudentName }}</small> </h5>
                      <h6 class="text-white"> <small>{{ item.AdmissionNumber }} | {{ item.StudentClass }} </small>
                      </h6>
                      <div>
                        <button class="btn btn-danger btn-sm my-2"
                          v-on:click="deletestudentforreportcardsubscription(item.Id)">
                          <i class="fa fa-times"></i> Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin"
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin"
export default {
  name: "reportcarsub",
  mixins: [CheckApiResponse, CheckStaffUser],
  components: {
    PageSpinner
  },
  data() {
    return {
      dataid: 1,
      SessionIdval: "",
      TermIdval: "",
      hasProcessTransaction: false,
      Sessionitems: null,
      termitems: null,
      searchval: "",
      studentitems: null,
      paymentItem: null,
      process: "N",
      onloader: "none",
      paymentData: {
        tx_ref: '',
        amount: 0,
        currency: "NGN",
        payment_options: "card,ussd",
        redirect_url: "https://schooldock.com/reportcardandcbtsubscription",
        customer: {
          name: "",
          email: "",
          phone_number: ""
        },
        customizations: {
          title: "SchoolDock",
          description: "Report card subscription",
          logo: "https://schooldock.com/img/brand/favicon.png"
        },
        callback: this.makePaymentCallback,
        onclose: this.closedPaymentModal
      }
    };
  },
  methods: {
    asyncPay() {
      this.asyncPayWithFlutterwave(this.paymentData).then(response => {
        console.log(response);
      });
    },
    makePaymentCallback(response) {
      console.log("Payment callback", response);
    },
    closedPaymentModal() {
      console.log("payment modal is closed");
    },
    generateReference() {
      let date = new Date();
      return date.getTime().toString();
    },
    populatePaymentData(TransRef, Amount, CustomerName, CustomerEmail, CustomerPhoneNo) {
      this.paymentData.tx_ref = TransRef
      this.paymentData.amount = Amount
      this.paymentData.customer.name = CustomerName
      this.paymentData.customer.email = CustomerEmail
      this.paymentData.customer.phone_number = CustomerPhoneNo
    },
    getregsession: function () {
      this.termitems = null;
      this.SessionIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getregsession")
        .then((resp) => {
          this.Sessionitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/home");
        });
    },
    getregterm: function () {
      this.TermIdval = 0;
      let SessionId = this.SessionIdval;
      if (SessionId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getregterm", SessionId)
          .then((resp) => {
            this.termitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    searchstudentforreportcardsubscription: function () {
      let SessionId = this.SessionIdval;
      let TermId = this.TermIdval;
      let SearchItem = this.searchval;
      if (TermId > 0) {
        let userdata = {
          SessionId,
          TermId,
          SearchItem,
        };
        this.$store
          .dispatch("getstudentforreportcardandcbtsubscriptionforpayment", userdata)
          .then((resp) => {
            this.populatePaymentData(resp.TransactionRef, resp.Amount, resp.CustormerName, resp.CustomerEmailAddress, resp.CustormerPhoneNo)
            this.studentitems = resp;
            this.hasProcessTransaction = resp.hasProcessTransaction
          })
          .catch((err) => {
            this.checkresponse(err.response.data);
          });
      }
    },
    deletestudentforreportcardsubscription: function (Id) {
      let SessionId = this.SessionIdval;
      let TermId = this.TermIdval;
      if (TermId > 0) {
        let userdata = {
          SessionId,
          TermId,
          Id,
        };
        this.onloader = "flex";

        this.$store
          .dispatch("deletestudentforreportcardsubscription", userdata)
          .then((resp) => {
            this.populatePaymentData(resp.TransactionRef, resp.Amount, resp.CustormerName, resp.CustomerEmailAddress, resp.CustormerPhoneNo)
            this.studentitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getpaymentrequest: function () {
      let tx_ref = this.$route.query.transaction_id;
      if (tx_ref != null || tx_ref != undefined) {
        let status = this.$route.query.status;
        if (status == "successful") {
          this.onloader = "flex";
          this.$store
            .dispatch("processreportcardsubscription", tx_ref)
            .then(() => {
              this.onloader = "none";
              swal({
                title: "Success!",
                text: "Subscribed Successfully",
                icon: "success",
                button: "Ok",
              });
            })
            .catch((err) => {
              this.onloader = "none";
              swal({
                title: "Oops!",
                text: err.response.data,
                icon: "error",
                button: "Ok",
              });
            });
        }
      }
    },
  },
  mounted() {
    this.getpaymentrequest();
    this.getregsession();
  },
};
</script>

<style>

</style>
