<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white pl-3">
                Dashboard</router-link>
            <router-link to="/academic" class="text-white">
                <i class="fa fa-ellipsis-v px-2"></i>Academics
            </router-link>
            <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>Lesson Note</span>
        </base-header>
        <!--Charts-->
        <div class="container-fluid mt--7">
            <div class="mb-5 mb-xl-0">
                <card header-classes="bg-transparent">
                    <div class="card bg-secondary border-0 mb-0">
                        <div class="card-body">
                            <div class="card bg-secondary">
                                <div class="card-body">
                                    <modal :show.sync="modals.newModal" modal-classes="modal-dialog-centered modal-xl">
                                        <div class="card bg-secondary border-0 mb-0">
                                            <div class="card-body">
                                                <form role="form" @submit.prevent="submitdata">
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label>Session</label>
                                                                <select v-model="SessionIdval" class="form-control"
                                                                    @change="getregterm()">
                                                                    <option value=""></option>
                                                                    <option v-for="item in Sessionitems" :key="item.Id"
                                                                        v-bind:value="item.Id">
                                                                        {{ item.SchSession }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3" v-if="SessionIdval">
                                                            <div class="form-group">
                                                                <label>Term</label>
                                                                <select v-model="TermIdval" class="form-control"
                                                                    @change="getclassinsubjectassigned()">
                                                                    <option value="0"></option>
                                                                    <option v-for="item in termitems" :key="item.Id"
                                                                        v-bind:value="item.Id">
                                                                        {{ item.SchTerm }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3" v-if="TermIdval">
                                                            <div class="form-group">
                                                                <label>Class</label>
                                                                <select v-model="ClassIdval" class="form-control"
                                                                    @change="getoptioninsubjectassigned()">
                                                                    <option value="0"></option>
                                                                    <option v-for="item in classitems" :key="item.Id"
                                                                        v-bind:value="item.Id">
                                                                        {{ item.Sch_Class }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3" v-if="ClassIdval">
                                                            <div class="form-group">
                                                                <label>Option</label>
                                                                <select v-model="OptionIdval" class="form-control"
                                                                    @change="getarminsubjectassigned()">
                                                                    <option value="0"></option>
                                                                    <option v-for="item in optionitems" :key="item.Id"
                                                                        v-bind:value="item.Id">
                                                                        {{ item.ClassOption }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3" v-if="OptionIdval">
                                                            <div class="form-group">
                                                                <label>Arm</label>
                                                                <select v-model="ArmIdval" class="form-control"
                                                                    @change="getsubjectinsubjectassigned()">
                                                                    <option value="0"></option>
                                                                    <option v-for="item in armitems" :key="item.Id"
                                                                        v-bind:value="item.Id">
                                                                        {{ item.SectionName }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3" v-if="ArmIdval">
                                                            <div class="form-group">
                                                                <label>Subject</label>
                                                                <select v-model="SubjectIdval" class="form-control"
                                                                    @change="GetBySubjectLessonNote()">
                                                                    <option value="0"></option>
                                                                    <option v-for="item in subjectitems" :key="item.Id"
                                                                        v-bind:value="item.Id">
                                                                        {{ item.SchSubject }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group" v-if="SubjectIdval">
                                                        <label>Title</label>
                                                        <ckeditor v-model="Titleval" :editor="editor"
                                                            :config="editorConfig">
                                                        </ckeditor>
                                                    </div>
                                                    <div class="form-group" v-if="Titleval">
                                                        <label>Lesson Note</label>
                                                        <ckeditor v-model="LessonNoteval" :editor="editor"
                                                            :config="editorConfig">
                                                        </ckeditor>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <template slot="footer">
                                            <base-button type="default" @click="modals.newModal = false">Cancel
                                            </base-button>
                                            <base-button v-if="LessonNoteval" type="success"
                                                @click.prevent="submitdata()">Submit
                                            </base-button>
                                        </template>
                                    </modal>
                                    <modal :show.sync="modals.viewModal" modal-classes="modal-dialog-centered modal-xl">
                                        <div class="card bg-secondary border-0 mb-0">
                                            <div class="card-body">
                                                <div v-html="LessonNoteval"></div>
                                            </div>
                                        </div>
                                        <template slot="footer">
                                            <base-button type="default" @click="modals.viewModal = false">Close
                                            </base-button>
                                        </template>
                                    </modal>
                                    <modal :show.sync="modals.approveModal" modal-classes="modal-dialog-centered">
                                        <div class="card bg-secondary border-0 mb-0">
                                            <div class="card-body">
                                                <h3 class="text-center text-muted">Are you sure you want to approve this
                                                    lesson
                                                    Note</h3>
                                            </div>
                                        </div>
                                        <template slot="footer">
                                            <base-button type="default" @click="modals.approveModal = false">No
                                            </base-button>

                                            <base-button type="success" @click.prevent="ApproveLessonNote()">Yes
                                            </base-button>
                                        </template>
                                    </modal>
                                    <modal :show.sync="modals.disapproveModal" modal-classes="modal-dialog-centered">
                                        <div class="card bg-secondary border-0 mb-0">
                                            <div class="card-body">
                                                <label>Reason to disapprove the lesson note</label>
                                                <ckeditor v-model="DisapprovedCommentval" :editor="editor"
                                                    :config="editorConfig">
                                                </ckeditor>
                                            </div>
                                        </div>
                                        <template slot="footer">
                                            <base-button type="default" @click="modals.disapproveModal = false">Cancel
                                            </base-button>

                                            <base-button type="success" @click.prevent="DisapproveLessonNote()">
                                                Disapprove
                                            </base-button>
                                        </template>
                                    </modal>
                                    <div class="justify-content-end form-inline">
                                        <button class="btn btn-success" v-on:click="openNew()">
                                            <i class="fa fa-edit"></i>  New
                                        </button>
                                        <select v-model="SessionIdval" class="btn btn-dark bg-dark form-control"
                                            @change="getregterm()">
                                            <option value="0">-Select Session-</option>
                                            <option v-for="item in Sessionitems" :key="item.Id" v-bind:value="item.Id">
                                                {{ item.SchSession }}
                                            </option>
                                        </select>

                                        <select v-if="SessionIdval" v-model="TermIdval"
                                            class="btn btn-dark bg-dark form-control" @change="GetLessonNotes()">
                                            <option value="0">Select Term</option>
                                            <option v-for="item in termitems" :key="item.Id" v-bind:value="item.Id">
                                                {{ item.SchTerm }}
                                            </option>
                                        </select>
                                        <select v-if="TermIdval" v-model="filterval"
                                            class="btn btn-dark bg-dark form-control" @change="filterResult()">
                                            <option value="0">- Filter By -</option>
                                            <option value="1">Pending</option>
                                            <option value="2">Approved</option>
                                            <option value="3">Disapproved</option>
                                            <option value="4">All</option>
                                        </select>

                                        <base-input v-if="TermIdval" class="input-group-alternative"
                                            placeholder="Search here..." addon-left-icon="fa fa-search"
                                            v-model="searchval">
                                        </base-input>
                                    </div>
                                    <div class="table-responsive mt-2">
                                        <table class="
                        table
                        table-dark
                        table-bordered
                        table-striped
                        table-hover
                      ">
                                            <thead>
                                                <tr>
                                                    <th>Lesson Note</th>
                                                    <th>Subject</th>
                                                    <th>Class</th>
                                                    <th>Option</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="searchedResultQuery">
                                                <tr v-for="item in searchedResultQuery" :key="item.Id">
                                                    <td v-html="item.Title"></td>
                                                    <td>{{ item.SubjectName }}</td>
                                                    <td>{{ item.Sch_Class }}</td>
                                                    <td>{{ item.ClassOption }}</td>
                                                    <td>
                                                        <button v-if="item.IsDisapproved!=true"
                                                            class="btn btn-secondary btn-sm"
                                                            v-on:click="openDisapprove(item.LessonNoteId)">
                                                            <i class="fa fa-times"></i> Disapprove
                                                        </button>
                                                        <button v-if="item.IsApproved!=true"
                                                            class="btn btn-secondary btn-sm"
                                                            v-on:click="openApprove(item.LessonNoteId)">
                                                            <i class="fa fa-check"></i> Approve
                                                        </button>
                                                        <button class="btn btn-secondary btn-sm"
                                                            v-on:click="openView(item.LessonNote)">
                                                            <i class="fa fa-eye"></i> View
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
            <!-- End charts-->
        </div>
    </div>
</template>
    
<script>
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner";
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin";
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin";
import CkEditorUploadAdapter from "@/mixins/CkEditorUploadAdapter.mixin";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import modal from '@/components/Modal'
export default {
    name: "LessonNote",
    mixins: [CheckApiResponse, CheckStaffUser, CkEditorUploadAdapter],
    components: {
        PageSpinner, modal,
    },
    data() {
        return {
            LessonNoteIdval: 0,
            SessionIdval: 0,
            TermIdval: 0,
            DisapprovedCommentval: '',
            ClassIdval: "",
            OptionIdval: "",
            ArmIdval: "",
            SubjectIdval: "",
            Titleval: "",
            LessonNoteval: "",
            Sessionitems: null,
            termitems: null,
            classitems: null,
            optionitems: null,
            armitems: null,
            subjectitems: null,
            resultItem: null,
            searchval: "",
            filterval: 0,
            onloader: "none",
            editor: ClassicEditor,
            editorConfig: {
                extraPlugins: [this.uploadPlugin],
            },
            modals: {
                approveModal: false,
                disapproveModal: false,
                deleteModal: false,
                viewModal: false,
                newModal: false
            }
        };
    },
    computed: {
        searchedResultQuery() {
            if (this.searchval) {
                return this.resultItem.filter((item) => {
                    return this.searchval
                        .toLowerCase()
                        .split(" ")
                        .every(
                            (v) =>
                                item.Title.toLowerCase().includes(v) ||
                                item.SubjectName.toLowerCase().includes(v) ||
                                item.Sch_Class.toLowerCase().includes(v) ||
                                item.ClassOption.toLowerCase().includes(v)
                        );
                });
            } else {
                return this.resultItem;
            }
        },
    },
    methods: {
        openNew() {
            this.modals.newModal = true
        },
        openDisapprove(LessonNoteId) {
            this.LessonNoteIdval = LessonNoteId
            this.modals.disapproveModal = true
        },
        openApprove(LessonNoteId) {
            this.LessonNoteIdval = LessonNoteId
            this.modals.approveModal = true
        },
        openDelete(LessonNoteId) {
            this.LessonNoteIdval = LessonNoteId
            this.modals.deleteModal = true
        },
        openView(LessonNote) {
            this.LessonNoteval = LessonNote
            this.modals.viewModal = true
        },
        filterResult() {
            if (this.filterval == 1) {
                this.GetLessonNotesPending()
            } else if (this.filterval == 2) {
                this.GetLessonNotesApproved()
            } else if (this.filterval == 3) {
                this.GetLessonNotesDisApproved()
            } else if (this.filterval == 4) {
                this.GetLessonNotes()
            }
        },
        ApproveLessonNote() {
            this.onloader = "flex";
            this.$store
                .dispatch("ApproveLessonNote", this.LessonNoteIdval)
                .then((resp) => {
                    this.modals.approveModal = false
                    this.GetLessonNotesApproved()
                    this.onloader = "none";
                    swal({
                        title: "Success!",
                        text: resp,
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        DisapproveLessonNote() {
            let LessonNoteId = this.LessonNoteIdval
            let Comment = this.DisapprovedCommentval
            let userdata = { LessonNoteId, Comment }
            console.log(userdata)
            this.onloader = "flex";
            this.$store
                .dispatch("DisapproveLessonNote", userdata)
                .then((resp) => {
                    this.modals.disapproveModal = false
                    this.GetLessonNotesDisApproved()
                    this.onloader = "none";
                    swal({
                        title: "Success!",
                        text: resp,
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        GetByIdLessonNote(Id) {
            this.onloader = "flex";
            this.$store
                .dispatch("GetByIdLessonNote", Id)
                .then((resp) => {
                    this.LessonNoteIdval = Id;
                    this.ClassIdval = resp.ClassId;
                    this.GetSubjectByClass();
                    this.SubjectIdval = resp.SubjectByClassId;
                    this.LessonNoteDetailsval = resp.LessonNoteDetails;
                    this.LessonNoteTopicval = resp.LessonNoteTopic;
                    this.IsModifiedval = true;
                    this.onloader = "none";
                    this.openNew();
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        GetLessonNotes() {
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            if (TermId > 0) {
                let userdata = {
                    SessionId,
                    TermId,
                };
                this.onloader = "flex";
                this.$store
                    .dispatch("GetLessonNotes", userdata)
                    .then((resp) => {
                        this.resultItem = resp;
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        GetLessonNotesPending() {
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            if (TermId > 0) {
                let userdata = {
                    SessionId,
                    TermId,
                };
                this.onloader = "flex";
                this.$store
                    .dispatch("GetLessonNotesPending", userdata)
                    .then((resp) => {
                        this.resultItem = resp;
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        GetLessonNotesApproved() {
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            if (TermId > 0) {
                let userdata = {
                    SessionId,
                    TermId,
                };
                this.onloader = "flex";
                this.$store
                    .dispatch("GetLessonNotesApproved", userdata)
                    .then((resp) => {
                        this.resultItem = resp;
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        GetLessonNotesDisApproved() {
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            if (TermId > 0) {
                let userdata = {
                    SessionId,
                    TermId,
                };
                this.onloader = "flex";
                this.$store
                    .dispatch("GetLessonNotesDisApproved", userdata)
                    .then((resp) => {
                        this.resultItem = resp;
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        GetBySubjectLessonNote() {
            if (this.SubjectIdval > 0) {
                let SessionId = this.SessionIdval;
                let TermId = this.TermIdval;
                let SubjectId = this.SubjectIdval;
                let userdata = {
                    SessionId,
                    TermId,
                    SubjectId,
                };
                this.onloader = "flex";
                this.$store
                    .dispatch("GetBySubjectLessonNote", userdata)
                    .then((resp) => {
                        this.Titleval = resp.Title;
                        this.LessonNoteval = resp.LessonNote;
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        console.log(err);
                        //this.$store.dispatch('logout')
                    });
            }
        },
        submitdata() {
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let SubjectId = this.SubjectIdval;
            let Title = this.Titleval;
            let LessonNote = this.LessonNoteval;
            let Username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                SubjectId,
                Title,
                SessionId,
                TermId,
                LessonNote,
                Username,
                accesskey,
            };
            this.onloader = "flex";
            this.$store
                .dispatch("ProcessLessonNote", userdata)
                .then((resp) => {
                    this.modals.newModal = false;
                    this.filterval = 0;
                    this.GetLessonNotes();
                    this.Titleval = "";
                    this.LessonNoteval = "";
                    this.onloader = "none";
                    swal({
                        title: "Success!",
                        text: resp,
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        getregsession() {
            this.termitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.Sessionitems = null;
            this.subjectitems = null;
            this.SessionIdval = 0;
            this.onloader = "flex";
            this.$store
                .dispatch("getregsession")
                .then((resp) => {
                    this.Sessionitems = resp;
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        getregterm() {
            this.classitems = null;
            this.termitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.TermIdval = 0;
            let SessionId = this.SessionIdval;
            if (SessionId > 0) {
                this.onloader = "flex";
                this.$store
                    .dispatch("getregterm", SessionId)
                    .then((resp) => {
                        this.termitems = resp;
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        getclassinsubjectassigned() {
            this.classitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.subjectitems = null;
            this.ClassIdval = 0;
            this.onloader = "flex";
            this.$store
                .dispatch("getclassinsubjectassigned")
                .then((resp) => {
                    this.classitems = resp;
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        getoptioninsubjectassigned() {
            this.optionitems = null;
            this.armitems = null;
            this.subjectitems = null;
            this.OptionIdval = 0;
            this.onloader = "flex";
            let ClassId = this.ClassIdval;
            if (ClassId > 0) {
                this.$store
                    .dispatch("getoptioninsubjectassigned", ClassId)
                    .then((resp) => {
                        this.optionitems = resp;
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        getarminsubjectassigned: function () {
            this.armitems = null;
            this.subjectitems = null;
            this.ArmIdval = 0;
            this.onloader = "flex";
            let OptionId = this.OptionIdval;
            if (OptionId > 0) {
                this.$store
                    .dispatch("getarminsubjectassigned", OptionId)
                    .then((resp) => {
                        this.armitems = resp;
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        getsubjectinsubjectassigned: function () {
            this.studentitems = null;
            this.subjectitems = null;
            this.SubjectIdval = 0;
            this.onloader = "flex";
            let ArmId = this.ArmIdval;
            if (ArmId > 0) {
                this.$store
                    .dispatch("getschsubjectinsubjectassigned", ArmId)
                    .then((resp) => {
                        this.subjectitems = resp;
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        frontEndDateFormat(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        backEndDateFormat(date) {
            return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
        },
        checkresponse(resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch("logout");
                this.$router.push("/login");
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        },
    },
    mounted() {
        if (this.$store.state.auth.user_status_id != 1) {
            this.$store.dispatch("logout");
            this.$router.push("/login");
        } else {
            this.getregsession();
        }
    },
};
</script>
    
<style>

</style>
    