<template>
  <div>
   <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      ><i class="ni ni-bold-right text-white"></i>
      <router-link to="/setting" class="text-white"> Settings </router-link>
      <span class="text-white"><i class="ni ni-bold-right"></i> Option</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="row">
          <div class="col-md-5">
            <div class="card bg-secondary mb-0">
              <div class="card-header">
                <h3 class="card-title">Registration</h3>
              </div>
              <div class="card-body">
                <div class="form-horizontal">
                  <form role="form" @submit.prevent="submitclass">
                    <div class="form-group">
                      <label>Class</label>
                      <select
                        v-model="classval"
                        @change="getoption()"
                        class="form-control"
                      >
                        <option value="0"></option>
                        <option
                          v-for="item in classitems"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.Sch_Class }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group" v-if="classval">
                      <label>Option</label>
                      <input v-model="optionval" class="form-control" />
                    </div>
                    <div class="text-center mb-3" v-if="optionval">                     
                      <button type="submit" class="btn btn-primary my-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div class="justify-content-end form-inline">              
                  <base-input
              class="input-group-alternative"
              placeholder="Search here..."            
              addon-left-icon="fa fa-search"
              v-model="searchval"
            >
            </base-input>          
           </div>
            <div class="table-responsive mt-2">
            <table  class="table table-dark table-bordered table-striped table-hover">
                <thead>
                  <tr>
                    <th>Option Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in searchedResultQuery" :key="item.Id">
                    <td>{{ item.ClassOption }}</td>
                    <td>
                      <button
                        class="btn btn-secondary btn-sm"
                        v-on:click="edititem(item.Id)"
                      >
                        <i class="fa fa-edit"></i> Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
export default {
  name: "option",
   components: {
    PageSpinner
  },
  data() {
    return {
      dataid: 1,
      optionval: "",
      searchval: "",
      classval: 0,
      classitems: null,
      optionitems: null,
      process: "N",
      openform: false,
      onloader: "none",
    };
  },
computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.optionitems.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.ClassOption.toLowerCase().includes(v)                 
            );
        });
      } else {
        return this.optionitems;
      }
    }
  },
  methods: {
    newterm: function () {
      this.optionval = "";
      this.openform = true;
      this.process = "N";
    },
    closeform: function () {
      this.optionval = "";
      this.openform = false;
      this.process = "N";
      this.inputdisable = false;
    },
    edititem: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getoptionbyid", Id)
        .then((resp) => {
          this.dataid = Id;
          this.classval = resp.ClassId;
          this.optionval = resp.ClassOption;
          this.openform = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitclass: function () {
      let ClassId = this.classval;
      let SchOptionName = this.optionval;
      let Id = this.dataid;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      this.onloader = "flex";
      let payload = {
        Id,
        ClassId,
        SchOptionName,
        processop,
        username,
        accesskey,
      };
      this.$store
        .dispatch("optionregistration", payload)
        .then((resp) => {
          this.optionitems = resp;
          this.closeform();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getoptionsearch: function () {
      this.$store
        .dispatch("getoptionsearch", this.optionsearchval)
        .then((resp) => {
          this.optionitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.checkresponse(err.response.data);
        });
    },
    getoption: function () {
      let ClassId = this.classval;
      if (ClassId > 0) {
        this.$store
          .dispatch("getoption", ClassId)
          .then((resp) => {
            this.optionitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getclass: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getclass")
        .then((resp) => {
          this.classitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.auth.user_status_id != 1) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    } else {
      this.getclass();
    }
  },
};
</script>

<style></style>
