<template>
<div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8 text-white">
        <!-- Card stats -->
        <router-link to="/dashboard" class="text-white pl-3">
            <i class="ni ni-tv-2"></i> Dashborad
        </router-link><span><i class="ni ni-bold-right"></i> Report</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
        <card type="default" header-classes="bg-transparent">
            <Submenu></Submenu>
        </card>
    </div>

</div>
</template>

<script>
import Submenu from './Menu.vue';
export default {
    components: {
        Submenu
    },
    data() {
        return {

        };
    },
    mounted() {
        if (!(this.$store.state.auth.user_status_id == 2 || this.$store.state.auth.user_status_id == 8)) {
            this.$store.dispatch('logout')
            this.$router.push('/login')
        } 
    }
};
</script>

<style></style>
