<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <router-link to="/dashboard" class="text-white pl-3">
            <i class="ni ni-tv-2"></i> Dashborad</router-link>
        <span class="text-white"><i class="ni ni-bold-right"></i>School Bill</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-6">
              <div class="card bg-secondary border-0 mb-0">
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                      <div class="card bg-gradient-success">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Session</label>
                                <select
                                  v-model="SessionIdval"
                                  class="form-control"
                                  @change="getregterm()"
                                >
                                  <option value=""></option>
                                  <option
                                    v-for="item in Sessionitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.SchSession }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="SessionIdval">
                              <div class="form-group">
                                <label class="text-white">Term</label>
                                <select
                                  v-model="TermIdval"
                                  class="form-control"
                                  @change="getclass()"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in termitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.SchTerm }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="TermIdval">
                              <div class="form-group">
                                <label class="text-white">Class</label>
                                <select
                                  v-model="ClassIdval"
                                  class="form-control"
                                  @change="getoption()"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in classitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.Sch_Class }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="ClassIdval">
                              <div class="form-group">
                                <label class="text-white">Option</label>
                                <select
                                  v-model="OptionIdval"
                                  class="form-control"
                                  @change="getallschoolbilldetails()"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in optionitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.ClassOption }}
                                  </option>
                                </select>
                              </div>
                            </div>                            
                          </div>
                        </div>
                      </div>                      
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <span class=" badge badge-primary" v-if="sumResultItem">Total Amount = {{
                sumResultItem
              }}</span>
              <div class="table-responsive mt-2" v-if="resultItems">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Bill Name</th>
                      <th>BillAmount</th>
                    
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in resultItems" :key="item.Id">
                      <td>{{ item.BillName }}</td>
                      <td>{{ item.BillAmount }}</td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
export default {
  name: "StudentSchooBillDetails",
  data() {
    return {
      Idval: 1,
      ClassIdval: "",
      OptionIdval: "",
      SessionIdval: "",
      TermIdval: "",      
      Sessionitems: null,
      termitems: null,
      classitems: null,
      optionitems: null,
      billNameItems: null,
      sumResultItem: null,
      resultItems: null,
      process: "N",
      onloader: "none",
    };
  },

  methods: {    
    getallschoolbilldetails: function() {
      if (this.OptionIdval) {
        let ClassOptionId = this.OptionIdval;
        let SessionId = this.SessionIdval;
        let TermId = this.TermIdval;
        let userdata = {
          ClassOptionId,
          SessionId,
          TermId,
        };
        this.onloader = "flex";
        this.$store
          .dispatch("getallschoolbilldetails", userdata)
          .then((resp) => {
            this.resultItems = resp;
            this.getsumschoolbilldetails();
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getsumschoolbilldetails: function() {
      if (this.OptionIdval) {
        let ClassOptionId = this.OptionIdval;
        let SessionId = this.SessionIdval;
        let TermId = this.TermIdval;
        let userdata = {
          ClassOptionId,
          SessionId,
          TermId,
        };
        this.onloader = "flex";
        this.$store
          .dispatch("getsumschoolbilldetails", userdata)
          .then((resp) => {
            this.sumResultItem = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getregsession: function() {
      this.studentitems = null;
      this.termitems = null;
      this.optionitems = null;
      this.armitems = null;
      this.Sessionitems = null;
      this.classattendancesitem = null;
      this.SessionIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getregsession")
        .then((resp) => {
          this.Sessionitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/home");
        });
    },
    getregterm: function() {
      this.classitems = null;
      this.studentitems = null;
      this.termitems = null;
      this.optionitems = null;
      this.armitems = null;
      this.classattendancesitem = null;
      this.TermIdval = 0;
      let SessionId = this.SessionIdval;
      if (SessionId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getregterm", SessionId)
          .then((resp) => {
            this.termitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getclass: function() {
      this.classitems = null;
      this.optionitems = null;
      this.ClassIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getclass")
        .then((resp) => {
          this.classitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getoption: function() {
      this.optionitems = null;
      this.OptionIdval = 0;
      this.onloader = "flex";
      let ClassId = this.ClassIdval;
      if (ClassId > 0) {
        this.$store
          .dispatch("getoption", ClassId)
          .then((resp) => {
            this.optionitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    
    frontEndDateFormat: function(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.auth.user_status_id == 2 || this.$store.state.auth.user_status_id == 8) {
             this.getregsession();
        } else {
            
            this.$store.dispatch('logout')
            this.$router.push('/login')
        }    
  },
};
</script>

<style></style>
