<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      ><i class="ni ni-bold-right text-white"></i>
      <router-link to="/schsubject" class="text-white">
        <i class="ni ni-badge"></i> Subject
      </router-link>
      <span class="text-white"
        ><i class="ni ni-bold-right"></i>Subject Topic
      </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-6">
              <div class="card bg-secondary mb-0">
                <div class="card-header">
                  <h3 class="card-title">Registration</h3>
                </div>
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                       <div v-if="subjectitem" class="card bg-info">
                            <div class=" card-body">
                              <div class="row">
                                <div class="col-md-12">
                                  <span
                                    ><b>Class : </b>
                                    {{ subjectitem.Sch_Class }}</span
                                  >
                                </div>
                                <div class="col-md-12">
                                  <span
                                    ><b>Option : </b>
                                    {{ subjectitem.ClassOption }}</span
                                  >
                                </div>
                                <div class="col-md-12">
                                  <span
                                    ><b>Subject : </b>
                                    {{ subjectitem.SchSubject }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label>Topic</label>
                            <ckeditor
                              v-model="Topicval"
                              :editorUrl="$store.state.appdatas.editorUrl"
                            ></ckeditor>
                          </div>
                          <div class="text-center mb-3">
                            <button type="submit" class="btn btn-primary my-4">
                              Submit
                            </button>
                          </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="justify-content-end form-inline">
                <base-input
                  class="input-group-alternative"
                  placeholder="Search here..."
                  addon-left-icon="fa fa-search"
                  v-model="searchval"
                >
                </base-input>
              </div>
              <div class="table-responsive mt-2">
                <table
                  class="table table-dark table-bordered table-striped table-hover"
                >
                  <thead>
                    <tr>
                      <th>Topic</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in searchedResultQuery" :key="item.Id">
                      <td v-html="item.Topic"></td>
                      <td>
                        <button
                          class="btn btn-secondary btn-sm"
                          v-on:click="edititem(item.Id)"
                        >
                          <i class="fa fa-edit"></i> Edit
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </card>
      </div>

      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner";
export default {
  name: "subjecttopic",
  components: {
    PageSpinner
  },
  data() {
    return {
      dataid: 1,
      SubjectTopicIdval: localStorage.getItem("SubjectTopicId") || "",
      Topicval: "",
      topicitem: null,
      subjectitem: null,
      searchval:'',
      process: "N",
      onloader: "none"
    };
  },
  computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.topicitem.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(v => item.Topic.toLowerCase().includes(v));
        });
      } else {
        return this.topicitem;
      }
    }
  },
  methods: {
    edititem: function(Id) {
      this.dataid = Id;
      this.Topicval = "";
      this.process = "U";
      this.onloader = "flex";
      let SubjectId = this.SubjectTopicIdval;
      this.$store
        .dispatch("getsubjecttopicbyid", {
          Id,
          SubjectId
        })
        .then(resp => {
          if (resp != null) {
            this.Topicval = resp.Topic;
          }
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          console.log(err);
          //this.$store.dispatch('logout')
        });
    },
    submitdata: function() {
      let Id = this.dataid;
      let SubjectId = this.SubjectTopicIdval;
      let Topic = this.Topicval;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        SubjectId,
        Topic,
        processop,
        username,
        accesskey
      };
      this.onloader = "flex";
      this.$store
        .dispatch("processsubjecttopic", userdata)
        .then(resp => {
          this.topicitem = resp;
          this.Topicval = "";
          this.process = "N";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok"
          });
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsubjecttopic: function() {
      let SubjectId = this.SubjectTopicIdval;
      this.onloader = "flex";
      this.$store
        .dispatch("getsubjecttopic", SubjectId)
        .then(resp => {
          this.topicitem = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getschsubjectbyid: function() {
      let Id = this.SubjectTopicIdval;
      this.onloader = "flex";
      this.$store
        .dispatch("getschsubjectbyid", Id)
        .then(resp => {
          this.subjectitem = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    frontEndDateFormat: function(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok"
        });
      }
    }
  },
  mounted() {
    if (this.$store.state.auth.user_status_id != 1) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    } else {
      this.getschsubjectbyid();
      this.getsubjecttopic();
    }
  }
};
</script>

<style></style>
