<template>
<div>
    <div id="loaderoverlay" v-bind:style={display:onloader}>
        <div class="w-100 d-flex justify-content-center align-items-center">
            <div class="loaderspinner"></div>
        </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <router-link to="/dashboard" class="text-white pl-3">
            <i class="ni ni-tv-2"></i> Dashborad</router-link>
        <span class="text-white"><i class="ni ni-bold-right"></i>Home Works</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">

        <div class="mb-5 mb-xl-0">
            <card header-classes="bg-transparent">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card border-0 mb-0">
                            <div class="card-body">
                                <div class="form-horizontal">
                                    <div class="card bg-gradient-success">
                                        <div class="card-body">
                                            <div class="input-group">
                                                <label class=" input-group-text">Subject</label>
                                                <select v-model="SubjectIdval" class="form-control" @change="gethomework()">
                                                    <option value="0"></option>
                                                    <option v-for="item in subjectitems" :key="item.Id" v-bind:value="item.Id">
                                                        {{item.SchSubject}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div v-for="item in homeworkitem" :key="item.Id">
                            <div class="card bg-secondary">
                                <div class="card-body mb-3">
                                    <h1 class=" badge badge-dark">Submittion Date : {{ item.SubmissionDate | formatDate }}</h1>
                                    <div v-html="item.Title"></div>
                                   
                                    <div class=" card-footer text-center"> <a :href="$store.state.auth.baseurl + item.DocPath.substr(1)" target="_blank" class="btn btn-dark btn-sm mx-3"><i class="fa fa-download"></i> Download </a>
                                        <button class="btn btn-success btn-sm" v-on:click="openanswer(item.Id)"><i class="fa fa-save"></i> Submit Answer</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </card>
        </div>

        <!-- End charts-->

    </div>

</div>
</template>

<script>

import swal from 'sweetalert'
export default {
    name: 'homework',
    data() {
        return {
            SubjectIdval: '',
            StudIdval: localStorage.getItem('StudId') || '',
            subjectitems: null,
            homeworkitem: null,
            onloader: 'none'
        }
    },
    methods: {
        openanswer: function (Id) {
            localStorage.setItem('HomeworkId', Id);
            this.$router.push('/Studhomeworkanswer');
        },
        gethomework: function () {
            let StudId = this.StudIdval;
            let SubjectId = this.SubjectIdval;
            this.onloader = 'flex'
            this.$store.dispatch('gethomeworkforstudent', {
                    StudId,
                    SubjectId
                })
                .then((resp) => {
                    this.homeworkitem = resp;
                    this.onloader = 'none'
                })
                .catch(err => {
                    this.onloader = 'none'
                     this.checkresponse(err.response.data);
                })
        },

        getschsubjectforstudent: function () {
            let StudId = this.StudIdval;
            if (StudId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('getschsubjectforstudent', StudId)
                    .then(resp => {
                        this.subjectitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }
    },
    mounted() {
        if (this.$store.state.auth.user_status_id == 2 || this.$store.state.auth.user_status_id == 8) {
             this.getschsubjectforstudent();
        } else {
           
            this.$store.dispatch('logout')
            this.$router.push('/login')
        }

    }
};
</script>

<style></style>
