<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link
      >
      <router-link to="/academic" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Academics
      </router-link>
      <span class="text-white"
        ><i class="fa fa-ellipsis-v px-2"></i>Home Works</span
      >
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-6">
              <div class="card bg-secondary border-0 mb-0">
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                      <div class="card bg-gradient-success">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Session</label>
                                <select
                                  v-model="SessionIdval"
                                  class="form-control"
                                  @change="getregterm()"
                                >
                                  <option value=""></option>
                                  <option
                                    v-for="item in Sessionitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.SchSession }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Term</label>
                                <select
                                  v-model="TermIdval"
                                  class="form-control"
                                  @change="getclassinsubjectassigned()"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in termitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.SchTerm }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Class</label>
                                <select
                                  v-model="ClassIdval"
                                  class="form-control"
                                  @change="getoptioninsubjectassigned()"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in classitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.Sch_Class }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Option</label>
                                <select
                                  v-model="OptionIdval"
                                  class="form-control"
                                  @change="getarminsubjectassigned()"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in optionitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.ClassOption }}
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Arm</label>
                                <select
                                  v-model="ArmIdval"
                                  class="form-control"
                                  @change="getsubjectinsubjectassigned()"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in armitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.SectionName }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Subject</label>
                                <select
                                  v-model="SubjectIdval"
                                  class="form-control"
                                  @change="gethomework()"
                                >
                                  <option value="0"></option>
                                  <option
                                    v-for="item in subjectitems"
                                    :key="item.Id"
                                    v-bind:value="item.Id"
                                  >
                                    {{ item.SchSubject }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label class="text-white">Title</label>
                                <ckeditor
                                  v-model="Titleval"
                                   :editor="editor"
                                    :config="editorConfig"
                                >
                                </ckeditor>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label class="text-white">Description</label>
                                <ckeditor
                                  v-model="Descripval"
                                  :editor="editor"
                                    :config="editorConfig"
                                >
                                </ckeditor>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label class="text-white"
                                  >Submission Date</label
                                >
                                <input
                                  type="date"
                                  min="0"
                                  v-model="SubmissionDateval"
                                  class="form-control"
                                />
                              </div>
                            </div>
                            <div class="col-md-12">
                              <span v-if="uploadedimage">
                                <i class="ni ni-box-2 ni-2x"></i>
                              </span>
                              <input
                                type="file"
                                id="file"
                                ref="file"
                                v-on:change="handleFileUpload()"
                              />
                              <br />
                              <button
                                class="btn btn-outline-secondary"
                                @click.prevent="submitFile()"
                              >
                                <i class="ni ni-cloud-upload-96"></i>Upload
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center mb-3">
                        <button type="submit" class="btn btn-primary my-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">             
              <div class="table-responsive mt-2">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Submission Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in homeworkitem" :key="item.Id">
                      <td v-html="item.Title"></td>
                      <td>{{ item.SubmissionDate | formatDate }}</td>

                      <td>
                        <button
                          class="btn btn-secondary btn-sm"
                          v-on:click="edititem(item.Id)"
                        >
                          <i class="fa fa-edit"></i> Edit
                        </button>

                        <button
                          class="btn btn-secondary btn-sm"
                          v-on:click="openanswer(item.Id)"
                        >
                          <i class="fa fa-eye"></i> View Student Submited
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </card>
      </div>

      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner";
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin";
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin";
import CkEditorUploadAdapter from "@/mixins/CkEditorUploadAdapter.mixin";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "homework",
  mixins: [CheckApiResponse, CheckStaffUser, CkEditorUploadAdapter],
  components: {
    PageSpinner,
  },
  data() {
    return {
      dataid: 1,
      ClassIdval: "",
      OptionIdval: "",
      ArmIdval: "",
      SessionIdval: "",
      TermIdval: "",
      StudIdval: "",
      SubjectIdval: "",
      Titleval: "",
      Descripval: "",
      DocPathval: "",
      uploadedimage: "",
      SubmissionDateval: "",
      Sessionitems: null,
      termitems: null,
      classitems: null,
      optionitems: null,
      armitems: null,
      subjectitems: null,
      homeworkitem: null,
      process: "N",
      onloader: "none",
      editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [this.uploadPlugin],
      },
    };
  },
  methods: {
    openanswer: function (Id) {
      localStorage.setItem("HomeworkId", Id);
      this.$router.push("/homeworkanswer");
    },
    edititem: function (Id) {
      this.dataid = Id;
      this.process = "U";
      this.onloader = "flex";
      this.$store
        .dispatch("gethomeworkbyid", Id)
        .then((resp) => {
          this.Titleval = resp.Title;
          this.Descripval = resp.Descrip;
          this.DocPathval = resp.DocPath;
          this.uploadedimage = resp.DocPath;
          this.SubmissionDateval = this.frontEndDateFormat(resp.SubmissionDate);
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
          //this.$store.dispatch('logout')
        });
    },
    submitdata: function () {
      let Id = this.dataid;
      let ClassId = this.ClassIdval;
      let OptionId = this.OptionIdval;
      let ArmId = this.ArmIdval;
      let SessionId = this.SessionIdval;
      let TermId = this.TermIdval;
      let SubjectId = this.SubjectIdval;
      let StudId = 1;
      let Title = this.Titleval;
      let Descrip = this.Descripval;
      let DocPath = this.DocPathval;
      let SubmissionDate = this.SubmissionDateval;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        ClassId,
        OptionId,
        ArmId,
        SubjectId,
        StudId,
        Title,
        SessionId,
        TermId,
        Descrip,
        DocPath,
        SubmissionDate,
        processop,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("homeworkregistration", userdata)
        .then((resp) => {
          this.homeworkitem = resp;
          this.Titleval = "";
          this.Descripval = "";
          this.DocPathval = "";
          this.SubmissionDateval = "";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    gethomework: function () {
      let Id = 1;
      let ClassId = this.ClassIdval;
      let OptionId = this.OptionIdval;
      let ArmId = this.ArmIdval;
      let SubjectId = this.SubjectIdval;
      let StudId = 1;
      let SessionId = this.SessionIdval;
      let TermId = this.TermIdval;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        ClassId,
        OptionId,
        ArmId,
        SubjectId,
        StudId,
        SessionId,
        TermId,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("gethomework", userdata)
        .then((resp) => {
          this.homeworkitem = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getregsession: function () {
      this.termitems = null;
      this.optionitems = null;
      this.armitems = null;
      this.Sessionitems = null;
      this.subjectitems = null;
      this.homeworkitem = null;
      this.SessionIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getregsession")
        .then((resp) => {
          this.Sessionitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getregterm: function () {
      this.classitems = null;
      this.termitems = null;
      this.optionitems = null;
      this.armitems = null;
      this.homeworkitem = null;
      this.TermIdval = 0;
      let SessionId = this.SessionIdval;
      if (SessionId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getregterm", SessionId)
          .then((resp) => {
            this.termitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getclassinsubjectassigned: function () {
      this.classitems = null;
      this.optionitems = null;
      this.armitems = null;
      this.subjectitems = null;
      this.homeworkitem = null;
      this.ClassIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getclassinsubjectassigned")
        .then((resp) => {
          this.classitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getoptioninsubjectassigned: function () {
      this.optionitems = null;
      this.armitems = null;
      this.subjectitems = null;
      this.homeworkitem = null;
      this.OptionIdval = 0;
      this.onloader = "flex";
      let ClassId = this.ClassIdval;
      if (ClassId > 0) {
        this.$store
          .dispatch("getoptioninsubjectassigned", ClassId)
          .then((resp) => {
            this.optionitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getarminsubjectassigned: function () {
      this.armitems = null;
      this.subjectitems = null;
      this.homeworkitem = null;
      this.ArmIdval = 0;
      this.onloader = "flex";
      let OptionId = this.OptionIdval;
      if (OptionId > 0) {
        this.$store
          .dispatch("getarminsubjectassigned", OptionId)
          .then((resp) => {
            this.armitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getsubjectinsubjectassigned: function () {
      this.studentitems = null;
      this.subjectitems = null;
      this.homeworkitem = null;
      this.SubjectIdval = 0;
      this.onloader = "flex";
      let ArmId = this.ArmIdval;
      if (ArmId > 0) {
        this.$store
          .dispatch("getschsubjectinsubjectassigned", ArmId)
          .then((resp) => {
            this.subjectitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0];
    },
    submitFile: function () {
      this.onloader = "flex";
      var form = new FormData();
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let formalurl = this.DocPathval;
      let doctype = "doc";
      form.append("username", username);
      form.append("accesskey", accesskey);
      form.append("formalurl", formalurl);
      form.append("doctype", doctype);
      form.append("file", this.file);
      this.$store
        .dispatch("uploadfile", form)
        .then((resp) => {
          this.DocPathval = resp;
          this.uploadedimage = this.$store.state.auth.baseurl + resp.substr(1);
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Uploaded successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.auth.user_status_id != 1) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    } else {
      this.getregsession();
    }
  },
};
</script>

<style>
</style>
