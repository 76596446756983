<template>
<div>
    <div id="loaderoverlay" v-bind:style={display:onloader}>
        <div class="w-100 d-flex justify-content-center align-items-center">
            <div class="loaderspinner"></div>
        </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <router-link to="/dashboard" class="text-white pl-3">
            <i class="ni ni-tv-2"></i> Dashborad</router-link>
        <span class="text-white"><i class="ni ni-bold-right"></i>Note on Student</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">

        <div class="mb-5 mb-xl-0">
            <card header-classes="bg-transparent">
                <div class="card bg-secondary border-0 mb-0">
                    <div class="card-body">
                        <div v-for="item in noteonstudentitem" :key="item.Id">
                            <div class="alert alert-warning">
                                <h1 class="text-center text-capitalize">
                                    <b class=" mr-3 badge badge-dark">{{ item.DateCreated | formatDate }}</b>
                                </h1>
                                <div v-html="item.Noteheading"></div>

                                <div v-html="item.Notebody"></div>

                            </div>
                            <div class="text-center">
                                <i class="fa fa-arrow-down"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </card>
        </div>

        <!-- End charts-->

    </div>

</div>
</template>

<script>
import moment from 'moment'
import swal from 'sweetalert'
export default {
    name: 'classattendance',
    data() {
        return {
            dataid: 1,
            StudIdval: localStorage.getItem('StudId') || '',
            noteonstudentitem: null,
            process: 'N',
            onloader: 'none'
        }
    },
    methods: {
        getnoteonstudent: function () {
            let StudId = this.StudIdval;
            if (StudId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('getnoteonstudent', StudId)
                    .then((resp) => {
                        this.noteonstudentitem = resp;
                        this.onloader = 'none'
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        frontEndDateFormat: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
        backEndDateFormat: function (date) {
            return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }

    },
    mounted() {
        if (this.$store.state.auth.user_status_id == 2 || this.$store.state.auth.user_status_id == 8) {
            this.getnoteonstudent();
        } else {
            
            this.$store.dispatch('logout')
            this.$router.push('/login')
        }
    }
};
</script>

<style></style>
