<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white pl-3">
               Dashboard
            </router-link>
            <router-link to="/staff" class="text-white">
              <i class="fa fa-ellipsis-v px-2"></i>Staff
            </router-link>
            <span class="text-white"> <i class="fa fa-ellipsis-v px-2"></i>Class Teacher</span>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">
            <div class="mb-5 mb-xl-0">
                <card header-classes="bg-transparent">
                <!--Modal Popup-->



                <!--Modal Popup-->

                    <div class="row">
                        <div class="col-md-6">
                            <div class="card bg-secondary border-0 mb-0">
                                <div class="card-body">
                                    <div class="form-horizontal">
                                        <form role="form" @submit.prevent="submitdata">
                                            <div class="card">
                                                <div class="card-body">
                                                    <img v-if="staffPassport" :src="staffPassport" class="rounded"
                                                        alt="" height="50" />
                                                    <img v-else src="img/brand/loguser.png" class="rounded" alt=""
                                                        height="50" />
                                                    <h5>
                                                        <small>{{ staffName }}</small>
                                                    </h5>
                                                    <div class="form-group">                                                       
                                                        <select v-model="ClassIdval" class="form-control dropdown"
                                                            @change="getoption()">
                                                            <option value="0">Select Class</option>
                                                            <option v-for="item in schclass" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.Sch_Class }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group" v-if="ClassIdval">                                                        
                                                        <select v-model="OptionIdval" class="form-control"
                                                            @change="getarm()">
                                                            <option value="0">Select Option</option>
                                                            <option v-for="item in optionitems" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.ClassOption }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group" v-if="OptionIdval">                                                     
                                                        <select v-model="ArmIdval" class="form-control"
                                                            >
                                                            <option value="0">Select Arm</option>
                                                            <option v-for="item in armitems" :key="item.Id"
                                                                v-bind:value="item.Id">
                                                                {{ item.SectionName }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-center mb-3" v-if="ArmIdval">
                                                <button type="submit" class="btn btn-primary my-4">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="table-responsive mt-2">
                                <table class="table table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Class</th>
                                            <th>Arm</th>
                                          
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in classteacheritem" :key="item.StaffClassId">
                                            <td>{{ item.ClassName }}</td>
                                            <td>{{ item.SectionName }}</td>                                           
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </card>
            </div>

            <!-- End charts-->

        </div>

    </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
export default {
    name: "classattendance",
    components: {
        PageSpinner
    },
    data() {
        return {
            dataid: 1,
            ClassIdval: 0,
            OptionIdval: 0,
            ArmIdval: 0,
            StaffIdval: localStorage.getItem("StaffId"),
            optionitems: null,
            armitems: null,
            schclass: null,
            classteachers: null,
            staffs: null,
            studentitems: null,
            classteacheritem: null,
            staffName: "",
            staffPassport: "",
            searchval: "",
            process: "N",
            onloader: "none",
        };
    },

    methods: {
        deleteclassteacher: function (Id) {
            this.onloader = "flex";
            this.$store
                .dispatch("deleteclassteacher", Id)
                .then(() => {
                    this.getstaffs();
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        submitdata: function () {
            let StaffId = this.StaffIdval;
            let ClassId = this.ClassIdval;
            let ArmId = this.ArmIdval;
            let processop = this.process;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                StaffId,
                ClassId,
                ArmId,
                processop,
                username,
                accesskey,
            };
            this.onloader = "flex";
            this.$store
                .dispatch("Processclassteacher", userdata)
                .then(() => {
                    this.getstaffs();
                    this.onloader = "none";
                    swal({
                        title: "Success!",
                        text: "Submitted successfully",
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        getclass: function () {
            this.classitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.classteacheritem = null;
            this.onloader = "flex";
            this.$store
                .dispatch("getclass")
                .then((resp) => {
                    this.schclass = resp;
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        getoption: function () {
            this.optionitems = null;
            this.armitems = null;
            this.classteacheritem = null;
            this.OptionIdval = 0;
            this.onloader = "flex";
            let ClassId = this.ClassIdval;
            if (ClassId > 0) {
                this.$store
                    .dispatch("getoption", ClassId)
                    .then((resp) => {
                        this.optionitems = resp;
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        getarm: function () {
            this.armitems = null;
            this.classteacheritem = null;
            this.ArmIdval = 0;
            this.onloader = "flex";
            let OptionId = this.OptionIdval;
            if (OptionId > 0) {
                this.$store
                    .dispatch("getarm", OptionId)
                    .then((resp) => {
                        this.armitems = resp;
                        this.onloader = "none";
                    })
                    .catch((err) => {
                        this.onloader = "none";
                        this.checkresponse(err.response.data);
                    });
            }
        },
        getstaffs: function () {
            this.onloader = "flex";         
            this.$store
                .dispatch("getstaffbyid", this.StaffIdval)
                .then((resp) => {                   
                    this.staffs = resp;
                    this.staffPassport = resp.Passport
                    this.staffName = resp.StaffName
                    this.classteacheritem = resp.StaffClass
                    this.onloader = "none";
                })
                .catch((err) => {
                    this.onloader = "none";
                    this.checkresponse(err.response.data);
                });
        },
        frontEndDateFormat: function (date) {
            return moment(date).format("YYYY-MM-DD");
        },
        backEndDateFormat: function (date) {
            return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch("logout");
                this.$router.push("/login");
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        },
    },
    mounted() {
        if (this.$store.state.auth.user_status_id != 1) {
            this.$store.dispatch("logout");
            this.$router.push("/login");
        } else {
            if (localStorage.getItem("StaffId") == null || localStorage.getItem("StaffId") == undefined) {
                this.$router.push("/staff");
            } else {               
                this.getstaffs();
                this.getclass();
            }

        }
    },
};
</script>

<style>
</style>
