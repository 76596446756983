<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link>
      <router-link to="/exammenu" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i> Exam
      </router-link>
      <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>Exam Question
      </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body">
              <div v-if="Openquestion == false" class="form-horizontal">
                <form role="form">
                  <div class="card bg-gradient-success">
                    <div class="card-body">
                      <div class="input-group">
                        <input type="text" placeholder="Enter Access Code" class="form-control"
                          v-model="AccessCodeval" />
                        <button type="submit" class="btn btn-primary input-group-append"
                          @click.prevent="verifyaccesscode">
                          Access
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div v-if="Openquestion" class="row">
                <div class="col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h6>
                        Session : {{ SchSessionval }} / {{ SchoolTermval }}
                      </h6>
                      <h6>Class : {{ Sch_Classval }} / {{ ClassOptionval }}</h6>
                      <h6>Subject : {{ SubjectNameval }}</h6>
                      <h1 class="h1">
                        <span class="badge badge-danger">{{ ExamTimeval }}
                        </span>
                      </h1>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="card">
                    <div class="card-body">
                      <div class="alert alert-secondary">
                        <p v-html="CautionNoteval"></p>
                      </div>
                      <div v-if="StartExam == false" class="pt-3 text-center">
                        <button class="btn btn-dark" @click.prevent="GetQuestionsforExam()">
                          Start Exam
                        </button>
                      </div>
                      <div v-if="StartExam" class="pt-3">
                        <h1 class="text-center">
                          <b class="badge badge-dark">Question {{ CurrentSNo }}</b>
                        </h1>
                        <div class="pt-2" v-html="CurrentQuestion"></div>
                        <div class="row" v-if="CurrentImage">
                          <img :src="
                            $store.state.auth.baseurl + CurrentImage.substr(1)
                          " />
                        </div>
                        <div class="row">
                          <table
                            class="table table-light table-hover table-sm table-borderless table-striped table-responsive">
                            <tr>
                              <td>
                                <b class="badge badge-dark">A. </b>
                                <input type="checkbox" class="ml-3 display-1" @change="processsanswerA()"
                                  v-model="ChkOptionA" />
                              </td>
                              <td v-html="CurrentOptionA" class="display-1"></td>
                            </tr>
                            <tr>
                              <td>
                                <b class="badge badge-dark">B. </b>
                                <input type="checkbox" class="ml-3 display-1" @change="processsanswerB()"
                                  v-model="ChkOptionB" />
                              </td>
                              <td v-html="CurrentOptionB" class="display-1"></td>
                            </tr>
                            <tr>
                              <td>
                                <b class="badge badge-dark">C. </b>
                                <input type="checkbox" class="ml-3 display-1" @change="processsanswerC()"
                                  v-model="ChkOptionC" />
                              </td>
                              <td v-html="CurrentOptionC" class="display-1"></td>
                            </tr>
                            <tr>
                              <td>
                                <b class="badge badge-dark">D. </b>
                                <input type="checkbox" class="ml-3 display-1" @change="processsanswerD()"
                                  v-model="ChkOptionD" />
                              </td>
                              <td v-html="CurrentOptionD" class="display-1"></td>
                            </tr>
                          </table>
                        </div>
                        <div>
                          <div class="p-3 text-center">
                            <button v-if="nextbtn" class="btn btn-secondary" @click.prevent="nextqtn">
                               Next <i class="fa fa-arrow-right"></i>
                            </button>
                            <button class="btn btn-default" @click.prevent="submitqtn">
                              <i class="ni ni-cloud-upload-96"></i> Submit
                            </button>
                            <button v-if="backbtn" @click.prevent="prevqtn" class="btn btn-secondary">
                              <i class="fa fa-arrow-left"></i> Previous
                            </button>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-body bg-light">
                            <div v-if="QuestionSelected" class="row">
                              <div class="col-md-1 col pt-2" v-for="item in QuestionSelected" :key="item.Id">
                                <button v-if="item.SelectedAnswerId" class="btn btn-success p-2" v-on:click="
                                  getschquestionforexambyid(item.SNo)
                                ">
                                  {{ item.SNo }}
                                </button>
                                <button v-else class="btn btn-secondary p-2" v-on:click="
                                  getschquestionforexambyid(item.SNo)
                                ">
                                  {{ item.SNo }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
export default {
  name: "exam",
  data() {
    return {
      dataid: 1,
      AccessCodeval: "",
      HeaderIdVal: "",
      ExamPeriodval: "",
      Timerval: "",
      NoofQuestiontoDisplayval: "",
      CautionNoteval: "",
      SubjectNameval: "",
      SchSessionval: "",
      SchoolTermval: "",
      Sch_Classval: "",
      ClassOptionval: "",
      ExamTimeval: "",
      CurrentQuestion: "",
      CurrentSNo: 1,
      CurrentQuestionId: "",
      CurrentImage: "",
      CurrentOptionA: "",
      CurrentOptionAId: "",
      CurrentOptionB: "",
      CurrentOptionBId: "",
      CurrentOptionC: "",
      CurrentOptionCId: "",
      CurrentOptionD: "",
      CurrentOptionDId: "",
      SelectedAnswerId: "",
      ChkOptionA: false,
      ChkOptionB: false,
      ChkOptionC: false,
      ChkOptionD: false,
      IsClosedval: false,
      ClosedDateval: null,
      QuestionSelectedId: "",
      Openquestion: false,
      StartExam: false,
      Question: null,
      QuestionSelected: null,
      nextbtn: true,
      backbtn: false,
      alertmsg: false,
      process: "N",
      onloader: "none",
    };
  },
  methods: {
    async nextqtn() {
      let SNo = this.CurrentSNo;
      if (SNo > 0) {
        await this.processsanswer();
      }
      SNo++;
      let MaxNo = this.NoofQuestiontoDisplayval;
      if (SNo > MaxNo) {
        SNo = MaxNo;
      }
      if (SNo == MaxNo) {
        this.nextbtn = false;
        this.backbtn = true;
      }
      if (SNo >= 1 && SNo < MaxNo) {
        if (SNo == 1) {
          this.nextbtn = true;
          this.backbtn = false;
        } else {
          this.nextbtn = true;
          this.backbtn = true;
        }
      }
      this.CurrentSNo = SNo;
      this.GetSelectedQuestionsforExam();
      await this.getschquestionforexambyid(SNo);
    },
    async prevqtn() {
      let SNo = this.CurrentSNo;
      if (SNo > 0) {
        await this.processsanswer();
      }
      SNo--;
      let MaxNo = this.NoofQuestiontoDisplayval;
      if (SNo < 1) {
        SNo = 1;
      }
      if (SNo == MaxNo) {
        this.nextbtn = false;
        this.backbtn = true;
      }
      if (SNo >= 1 && SNo <= MaxNo) {
        if (SNo == 1) {
          this.nextbtn = true;
          this.backbtn = false;
        } else {
          this.nextbtn = true;
          this.backbtn = true;
        }
        this.CurrentSNo = SNo;
        this.GetSelectedQuestionsforExam();
        await this.getschquestionforexambyid(SNo);
      }
    },
    async submitqtn() {
      let SNo = this.CurrentSNo;
      if (SNo > 0) {
        await this.processsanswer();
      }
      if (this.Timerval > 0) {
        swal({
          title: "Confirmation",
          text: "Are you sure, you want to submit now?",
          type: "warning",
          buttons: true,
          dangerMode: true,
        }).then((result) => {
          if (result) {
            this.StartExam = false;
            this.Openquestion = false;
            this.AccessCodeval = "";
            this.Question = null;
            this.Timerval = 0;
          }
        });
      } else {
        this.StartExam = false;
        this.Openquestion = false;
        this.AccessCodeval = "";
        this.Question = null;
        this.Timerval = 0;
        swal({
          title: "Hurray!",
          text: 'Your answer has been submited successfully',
          icon: "success",
          button: "Ok",
        });
      }
    },
    verifyaccesscode() {
      this.onloader = "flex";
      this.$store
        .dispatch("verifyaccesscode", this.AccessCodeval)
        .then((resp) => {
          if (resp != null) {
            this.Sch_Classval = resp.Sch_Class;
            this.ClassOptionval = resp.ClassOption;
            this.IsClosedval = resp.IsClosed;
            this.ClosedDateval = resp.ClosedDate;
            this.HeaderIdVal = resp.Id;
            this.ExamPeriodval = this.Timerval = resp.ExamPeriod;
            this.ExamTimeval = this.formattime(this.ExamPeriodval);
            this.NoofQuestiontoDisplayval = resp.NoofQuestiontoDisplay;
            this.CautionNoteval = resp.CautionNote;
            this.SubjectNameval = resp.SubjectName;
            this.SchSessionval = resp.SchSession;
            this.SchoolTermval = resp.SchoolTerm;
            this.Openquestion = true;
          } else {
            swal({
              title: "Oops!",
              text: "Invalid Access Code",
              icon: "error",
              button: "Ok",
            });
          }
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },    
    async GetQuestionsforExam() {      
      let HeaderId = this.HeaderIdVal;
      let Maxno = this.NoofQuestiontoDisplayval;     
      let userdata = {
        HeaderId,
        Maxno
      };
      this.onloader = "flex";
      await this.$store
        .dispatch("GetQuestionsforExam", userdata)
        .then((resp) => {
          if (resp != null) {
            this.Question = resp;
            this.getschquestionforexambyid(this.CurrentSNo);
            this.GetSelectedQuestionsforExam();
            this.StartExam = true;
            this.examtimer();
          }
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    async getschquestionforexambyid(Id) {
      this.ChkOptionA = false;
      this.ChkOptionB = false;
      this.ChkOptionC = false;
      this.ChkOptionD = false;
      let HeaderId = this.HeaderIdVal;
      let userdata = {
        Id,
        HeaderId,
      };
      this.onloader = "flex";
      await this.$store
        .dispatch("getschquestionforexambyid", userdata)
        .then((resp) => {
          if (resp != null) {
            this.QuestionSelectedId = resp.Id;
            this.CurrentQuestionId = resp.QuestionId;
            this.CurrentQuestion = resp.Question;
            this.CurrentImage = resp.ImagePath;
            this.CurrentOptionA = resp.OptionA;
            this.CurrentOptionAId = resp.OptionAId;
            this.CurrentOptionB = resp.OptionB;
            this.CurrentOptionBId = resp.OptionBId;
            this.CurrentOptionC = resp.OptionC;
            this.CurrentOptionCId = resp.OptionCId;
            this.CurrentOptionD = resp.OptionD;
            this.CurrentOptionDId = resp.OptionDId;
            this.CurrentSNo = resp.SNo;
            this.SelectedAnswerId = resp.SelectedAnswer;
            if (this.SelectedAnswerId != null) {
              if (this.CurrentOptionAId == this.SelectedAnswerId) {
                this.ChkOptionA = true;
                this.ChkOptionB = false;
                this.ChkOptionC = false;
                this.ChkOptionD = false;
              }
              if (this.CurrentOptionBId == this.SelectedAnswerId) {
                this.ChkOptionA = false;
                this.ChkOptionB = true;
                this.ChkOptionC = false;
                this.ChkOptionD = false;
              }
              if (this.CurrentOptionCId == this.SelectedAnswerId) {
                this.ChkOptionA = false;
                this.ChkOptionB = false;
                this.ChkOptionC = true;
                this.ChkOptionD = false;
              }
              if (this.CurrentOptionDId == this.SelectedAnswerId) {
                this.ChkOptionA = false;
                this.ChkOptionB = false;
                this.ChkOptionC = false;
                this.ChkOptionD = true;
              }
            }
          }
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    GetSelectedQuestionsforExam() {             
      this.$store
        .dispatch("GetSelectedQuestionsforExam", this.HeaderIdVal)
        .then((resp) => {
          if (resp != null) {
            this.QuestionSelected = resp;           
          }
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    processsanswerA() {
      if (this.ChkOptionA == true) {
        this.ChkOptionB = false;
        this.ChkOptionC = false;
        this.ChkOptionD = false;
      }
    },
    processsanswerB() {
      if (this.ChkOptionB == true) {
        this.ChkOptionA = false;
        this.ChkOptionC = false;
        this.ChkOptionD = false;
      }
    },
    processsanswerC() {
      if (this.ChkOptionC == true) {
        this.ChkOptionB = false;
        this.ChkOptionA = false;
        this.ChkOptionD = false;
      }
    },
    processsanswerD() {
      if (this.ChkOptionD == true) {
        this.ChkOptionB = false;
        this.ChkOptionC = false;
        this.ChkOptionA = false;
      }
    },
    async processsanswer() {
      let AnswerId = 0;
      if (this.ChkOptionA == true) {
        AnswerId = this.CurrentOptionAId;
      } else if (this.ChkOptionB == true) {
        AnswerId = this.CurrentOptionBId;
      } else if (this.ChkOptionC == true) {
        AnswerId = this.CurrentOptionCId;
      } else if (this.ChkOptionD == true) {
        AnswerId = this.CurrentOptionDId;
      }
      if (AnswerId > 0) {
        let Id = this.QuestionSelectedId;
        let HeaderId = this.HeaderIdVal;
        let QuestionId = this.CurrentQuestionId;
        let TimeSpent = this.ExamPeriodval - this.Timerval;
        if (TimeSpent < 1) {
          TimeSpent = 1;
        }
        let username = this.$store.state.auth.token;
        let accesskey = this.$store.state.auth.accesskey;
        let userdata = {
          Id,
          HeaderId,
          QuestionId,
          AnswerId,
          TimeSpent,
          username,
          accesskey,
        };

        this.onloader = "flex";
        await this.$store
          .dispatch("processsanswer", userdata)
          .then(() => {
            this.ChkOptionA = false;
            this.ChkOptionB = false;
            this.ChkOptionC = false;
            this.ChkOptionD = false;
            this.onloader = "none";
          })
          .catch((err) => {
            this.ChkOptionA = false;
            this.ChkOptionB = false;
            this.ChkOptionC = false;
            this.ChkOptionD = false;
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    formattime(time) {
      let hrs = ~~(time / 3600);
      let mins = ~~((time % 3600) / 60);
      let secs = ~~time % 60;
      let ret = "";
      if (hrs > 0) {
        ret += "" + hrs + " Hrs : " + (mins < 10 ? "0" : "");
      }
      ret += "" + mins + " Min : " + (secs < 10 ? "0" : "");
      ret += "" + secs + " Sec";
      return ret;
    },
    examtimer() {
      this.Timerval = this.ExamPeriodval;
      if (this.StartExam == false) {
        this.Timerval = 0;
      }
      var timer = null;
      clearInterval(timer);
      timer = setInterval(() => {
        if (this.Timerval > 0) {
          if (this.Timerval == 30) {
            swal({
              title: "Oops!",
              text: "You have less than 30 seconds",
              icon: "error",
              button: "Ok",
            });
          }
          this.Timerval--;
          this.ExamTimeval = this.formattime(this.Timerval);
        } else {
          this.submitqtn();
          clearInterval(timer);
        }
      }, 1000);
    },
    frontEndDateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (
      !(
        this.$store.state.auth.user_status_id == 2 ||
        this.$store.state.auth.user_status_id == 8
      )
    ) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    }
  },
};
</script>

<style>

</style>
