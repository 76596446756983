<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8 text-white"
    >
      <!-- Card stats -->
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard </router-link
      ><span><i class="fa fa-ellipsis-v px-2"></i>Student Records</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card type="default" header-classes="bg-transparent">
        <Submenu></Submenu>
      </card>
    </div>
  </div>
</template>
<script>
import Submenu from "./Submenu.vue";
export default {
  components: {
    Submenu,
  },
  data() {
    return {};
  },
  mounted() {
    if (this.$store.state.auth.user_status_id != 1) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    }
  },
};
</script>
<style></style>
