<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white"> Dashboard</router-link>
      <router-link to="/academic" class="text-white pl-3">
        <i class="fa fa-ellipsis-v px-2"></i>Academic
      </router-link>
      <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>Exam Setup</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-6">
              <div class="card border-0 mb-0">
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                      <div class="card bg-gradient-secondary">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Session</label>
                                <select v-model="SessionIdval" class="custom-select" @change="getregterm($event)">
                                  <option value=""></option>
                                  <option v-for="item in Sessionitems" :key="item.Id" v-bind:value="item.Id">
                                    {{ item.SchSession }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="SessionIdval">
                              <div class="form-group">
                                <label>Term</label>
                                <select v-model="TermIdval" class="custom-select" @change="getclassinsubjectassigned()">
                                  <option value="0"></option>
                                  <option v-for="item in termitems" :key="item.Id" v-bind:value="item.Id">
                                    {{ item.SchTerm }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="TermIdval">
                              <div class="form-group">
                                <label>Class</label>
                                <select v-model="ClassIdval" class="custom-select" @change="getoptioninsubjectassigned()">
                                  <option value="0"></option>
                                  <option v-for="item in classitems" :key="item.Id" v-bind:value="item.Id">
                                    {{ item.Sch_Class }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="ClassIdval">
                              <div class="form-group">
                                <label>Option</label>
                                <select v-model="OptionIdval" class="custom-select" @change="getarminsubjectassigned()">
                                  <option value="0"></option>
                                  <option v-for="item in optionitems" :key="item.Id" v-bind:value="item.Id">
                                    {{ item.ClassOption }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="OptionIdval">
                              <div class="form-group">
                                <label>Arm</label>
                                <select v-model="ArmIdval" class="custom-select" @change="getsubjectinsubjectassigned()">
                                  <option value="0"></option>
                                  <option v-for="item in armitems" :key="item.Id" v-bind:value="item.Id">
                                    {{ item.SectionName }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="ArmIdval">
                              <div class="form-group">
                                <label>Subject</label>
                                <select v-model="SubjectIdval" class="custom-select" @change="getschquestionheader()">
                                  <option value="0"></option>
                                  <option v-for="item in subjectitems" :key="item.Id" v-bind:value="item.Id">
                                    {{ item.SchSubject }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="SubjectIdval">
                              <div class="form-group">
                                <label>Exam Period (In Seconds)</label>
                                <input type="number" min="0" v-model="ExamPeriodval" class="form-control" />
                              </div>
                            </div>
                            <div class="col-md-6" v-if="ExamPeriodval">
                              <div class="form-group">
                                <label>No. of Question to Display</label>
                                <input type="number" min="0" v-model="NoofQuestiontoDisplayval" class="form-control" />
                              </div>
                            </div>
                            <div class="col-md-12" v-if="NoofQuestiontoDisplayval">
                              <div class="form-group">
                                <label>Caution Note</label>
                                <quillEditor v-model="CautionNoteval" ref="CautionNoteval" :options="editorOption">

                                </quillEditor>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center mb-3" v-if="NoofQuestiontoDisplayval">
                        <button type="submit" class="btn btn-primary my-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mt-2">
                <div v-for="item in cbtheaderitem" :key="item.Id">
                  <div class="card">
                    <div class="card-body bg-secondary">
                      <h3><b class="mr-2 mb-2 text-muted">Access Code :</b> {{ item.PassCode }}</h3>
                      <h3><b class="mr-2 mb-2 text-muted">No of Question to Display :</b> {{ item.NoofQuestiontoDisplay }}</h3>
                      <h3 class="text-muted">Caution Note</h3>
                      <div v-html="item.CautionNote"></div>
                      <div class="card-footer text-center">
                        <button class="btn btn-secondary btn-sm m-2" v-on:click="edititem(item.Id)">
                          <i class="fa fa-edit"></i> Edit
                        </button>

                        <button class="btn btn-secondary btn-sm m-2" v-on:click="openquestion(item.Id)">
                          <i class="ni ni-caps-small"></i> View
                          Questions
                        </button>

                        <button class="btn btn-secondary btn-sm m-2" v-on:click="shareallquestion(item.Id)">
                          <i class="fa fa-share"></i> Share All Question
                        </button>

                        <button class="btn btn-secondary btn-sm m-2" v-on:click="openstudentanswer(item.Id)">
                          <i class="fa fa-users"></i> View Student
                          Answer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>

      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert";
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
import PageSpinner from "@/components/PageSpinner";
import CkEditorUploadAdapter from "@/mixins/CkEditorUploadAdapter.mixin";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


export default {
  name: "questionheader",
  mixins: [CkEditorUploadAdapter],
  components: {
    PageSpinner,
    quillEditor
  },
  data() {
    return {
      dataid: 1,
      ClassIdval: "",
      OptionIdval: "",
      ArmIdval: "",
      SessionIdval: "",
      TermIdval: "",
      SubjectIdval: "",
      ExamPeriodval: "",
      CautionNoteval: "",
      NoofQuestiontoDisplayval: "",
      Sessionitems: null,
      termitems: null,
      classitems: null,
      optionitems: null,
      armitems: null,
      subjectitems: null,
      studentitems: null,
      cbtheaderitem: null,
      process: "N",
      onloader: "none",
      editorOption: {
        debug: 'info',
        placeholder: 'Type in here',
        readonlly: true,
        theme: 'snow'
      },
      editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [this.uploadPlugin],
      },
    };
  },
  methods: {
    openquestion(Id) {
      localStorage.setItem("SchcbtheaderId", Id);
      this.$router.push("/schcbtquestion");
    },
    openstudentanswer(Id) {
      localStorage.setItem("SchcbtheaderId", Id);
      this.$router.push("/schcbtstudentanswer");
    },
    shareallquestion(Id) {
      localStorage.setItem("SchcbtheaderId", Id);
      this.$router.push("/shareallschquestion");
    },

    edititem(Id) {
      this.dataid = Id;
      this.ExamPeriodval = "";
      this.CautionNoteval = "";
      this.NoofQuestiontoDisplayval = "";
      this.process = "U";
      this.onloader = "flex";
      this.$store
        .dispatch("getschquestionheaderbyid", Id)
        .then((resp) => {
          if (resp != null) {
            this.ExamPeriodval = resp.ExamPeriod;
            this.CautionNoteval = resp.CautionNote;
            this.NoofQuestiontoDisplayval = resp.NoofQuestiontoDisplay;
          }
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata() {
      let Id = this.dataid;
      let ClassId = this.ClassIdval;
      let OptionId = this.OptionIdval;
      let ArmId = this.ArmIdval;
      let ExamPeriod = this.ExamPeriodval;
      let NoofQuestiontoDisplay = this.NoofQuestiontoDisplayval;
      let CautionNote = this.CautionNoteval;
      let SessionId = this.SessionIdval;
      let TermId = this.TermIdval;
      let SubjectId = this.SubjectIdval;
      let Exam = this.Examval;
      let CA = this.CAval;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        ClassId,
        OptionId,
        ArmId,
        SubjectId,
        ExamPeriod,
        NoofQuestiontoDisplay,
        CautionNote,
        SessionId,
        TermId,
        Exam,
        CA,
        Comment,
        processop,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("processschquestionheader", userdata)
        .then((resp) => {
          this.cbtheaderitem = resp;
          this.ExamPeriodval = "";
          this.CautionNoteval = "";
          this.NoofQuestiontoDisplayval = "";
          this.process = "N";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getschquestionheader() {
      let Id = 1;
      let SubjectId = this.SubjectIdval;
      let SessionId = this.SessionIdval;
      let TermId = this.TermIdval;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        SubjectId,
        SessionId,
        TermId,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("getschquestionheader", userdata)
        .then((resp) => {
          this.cbtheaderitem = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getregsession() {
      this.termitems = null;
      this.optionitems = null;
      this.armitems = null;
      this.Sessionitems = null;
      this.subjectitems = null;
      this.cbtheaderitem = null;
      this.SessionIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getregsession")
        .then((resp) => {
          this.Sessionitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);

          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/home");
        });
    },
    getregterm(event) {
      this.classitems = null;
      this.termitems = null;
      this.optionitems = null;
      this.armitems = null;
      this.cbtheaderitem = null;
      this.TermIdval = 0;
      let SessionId = event.target.value;
      if (SessionId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getregterm", SessionId)
          .then((resp) => {
            this.termitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getclassinsubjectassigned() {
      this.classitems = null;
      this.optionitems = null;
      this.armitems = null;
      this.subjectitems = null;
      this.cbtheaderitem = null;
      this.ClassIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getclassinsubjectassigned")
        .then((resp) => {
          this.classitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/home");
        });
    },
    getoptioninsubjectassigned() {
      this.optionitems = null;
      this.armitems = null;
      this.subjectitems = null;
      this.cbtheaderitem = null;
      this.OptionIdval = 0;
      let ClassId = this.ClassIdval;
      if (ClassId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getoptioninsubjectassigned", ClassId)
          .then((resp) => {
            this.optionitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getarminsubjectassigned() {
      this.armitems = null;
      this.subjectitems = null;
      this.cbtheaderitem = null;
      this.ArmIdval = 0;
      let OptionId = this.OptionIdval;
      if (OptionId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getarminsubjectassigned", OptionId)
          .then((resp) => {
            this.armitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getsubjectinsubjectassigned() {
      this.subjectitems = null;
      this.cbtheaderitem = null;
      this.SubjectIdval = 0;
      let ArmId = this.ArmIdval;
      if (ArmId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getschsubjectinsubjectassigned", ArmId)
          .then((resp) => {
            this.subjectitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    frontEndDateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.auth.user_status_id != 1) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    } else {
      if (this.$store.state.schooldata.schoolinfo.StatusId == 10) {
        this.$router.push("/tccbtheader");
      } else {
        this.getregsession();
      }
    }
  },
};
</script>

<style></style>
