<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      ><i class="ni ni-bold-right text-white"></i>
      <router-link to="/setting" class="text-white">
        Settings
      </router-link>
      <span class="text-white"
        ><i class="ni ni-bold-right"></i> Mock Subject</span
      >
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="row">
          <div class=" col-md-5">
            <div class="card bg-secondary mb-0">
                 <div class="card-header">
                <h3 class="card-title">Registration</h3>
              </div>
              <div class="card-body">
                <div class="form-horizontal">
                  <form role="form" @submit.prevent="submitsubject">
                    <div class="form-group">
                      <label>Mock</label>
                      <select
                        v-model="mockval"
                        @change="getoption()"
                        class="form-control"
                      >
                        <option value="0"></option>
                        <option
                          v-for="item in mocks"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.ExamTitle }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group" v-if="mockval">
                      <label>Option</label>
                      <select
                        v-model="optionval"
                        @change="getmocksubject()"
                        class="form-control"
                      >
                        <option value="0"></option>
                        <option
                          v-for="item in optionitems"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.ClassOption }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group" v-if="optionval">
                      <label>Available Subject</label>
                      <select v-model="subjectval" class="form-control">
                        <option value="0"></option>
                        <option
                          v-for="item in subjectitems"
                          :key="item.Id"
                          v-bind:value="item.Id"
                        >
                          {{ item.Title }}
                        </option>
                      </select>
                    </div>
                    <div class="text-center mb-3" v-if="subjectval">
                      <button type="submit" class="btn btn-primary my-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div class="justify-content-end form-inline">
              <base-input
                class="input-group-alternative"
                placeholder="Search here..."
                addon-left-icon="fa fa-search"
                v-model="searchval"
              >
              </base-input>
            </div>
            <div class="table-responsive mt-2">
              <table
                class="table table-dark table-bordered table-striped table-hover"
              >
                <thead>
                  <tr>
                    <th>Subject</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in searchedResultQuery" :key="item.Id">
                    <td>{{ item.SubjectName }}</td>
                    <td>
                      <button
                        class="btn btn-secondary btn-sm"
                        v-on:click="deleteitem(item.Id)"
                      >
                        <i class="fa ni ni-fat-remove"></i> Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner";
export default {
  name: "mockSubject",
  components: {
    PageSpinner
  },
  data() {
    return {
      dataid: 1,
      subjectval: "",
      subjectitems: null,
      mocksubjectitems: null,
      optionval: "",
      optionitems: null,
      mockval: "",
      mocks: null,
      searchval:'',
      process: "N",
      onloader: "none"
    };
  },
  computed: {
    searchedResultQuery() {
      if (this.searchval) {
        return this.mocksubjectitems.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.SubjectName.toLowerCase().includes(v) 
            );
        });
      } else {
        return this.mocksubjectitems;
      }
    }
  },
  methods: {
    deleteitem: function(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getmocksubjectbyid", Id)
        .then(resp => {
          this.mockval = resp.MockId;
          this.optionval = resp.OptionId;
          this.subjectval = resp.SubjectId;
          this.process = "D";
          this.submitsubject();
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitsubject: function() {
      let SubjectId = this.subjectval;
      let MockId = this.mockval;
      let OptionId = this.optionval;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let payload = {
        SubjectId,
        MockId,
        OptionId,
        processop,
        username,
        accesskey
      };
      this.onloader = "flex";
      this.$store
        .dispatch("registermocksubject", payload)
        .then(resp => {
          this.mocksubjectitems = resp;
          this.process = "N";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok"
          });
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getmocksubject: function() {
      this.mocksubjectitems = null;
      this.subjectitems = null;
      let SubjectId = 1;
      let MockId = this.mockval;
      let OptionId = this.optionval;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let payload = {
        SubjectId,
        MockId,
        OptionId,
        processop,
        username,
        accesskey
      };
      this.subjectval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getavailablemocksubject", payload)
        .then(resp => {
          this.subjectitems = resp;
        })
        .catch(err => {
          console.log(err);
        });
      this.$store
        .dispatch("getmocksubject", payload)
        .then(resp => {
          this.mocksubjectitems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getmocksubjectnotselected: function() {
      this.mocksubjectitems = null;
      this.subjectitems = null;
      let SubjectId = 1;
      let MockId = this.mockval;
      let OptionId = this.optionval;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let payload = {
        SubjectId,
        MockId,
        OptionId,
        processop,
        username,
        accesskey
      };
      this.onloader = "flex";
      this.subjectval = 0;
      this.$store
        .dispatch("getavailablemocksubject", payload)
        .then(resp => {
          this.subjectitems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          console.log(err);
        });
      this.$store
        .dispatch("getmocksubject", payload)
        .then(resp => {
          this.mocksubjectitems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getoption: function() {
      this.onloader = "flex";
      this.mocksubjectitems = null;
      this.subjectitems = null;
      let val = this.mockval;
      this.optionval = 0;
      this.$store
        .dispatch("getoptionbymock", val)
        .then(resp => {
          this.optionitems = resp;
          this.onloader = "none";
          //console.log(resp)
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getmock: function() {
      this.onloader = "flex";
      this.$store
        .dispatch("getmock")
        .then(resp => {
          this.mocks = resp;
          this.onloader = "none";
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
    },
    checkresponse: function(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok"
        });
      }
    }
  },
  mounted() {
    if (this.$store.state.auth.user_status_id != 1) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    } else {
      this.getmock();
    }
  }
};
</script>

<style></style>
