<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white pl-3">
                Dashboard</router-link>
            <router-link to="/reportmenu" class="text-white">
                <i class="fa fa-ellipsis-v px-2"></i> Report
            </router-link>
            <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>Mock Report Card</span>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">

            <div class="mb-5 mb-xl-0">
                <card header-classes="bg-transparent">
                    <div class="card bg-secondary border-0 mb-0">
                        <div class="card-body">
                            <div class="form-inline">
                                <div class="row">
                                    <div class="col-md-4 pt-3">
                                        <div class="input-group">
                                            <label class="input-group-text">Session</label>
                                            <select v-model="SessionIdval" class="form-control btn btn-success">
                                                <option value=""></option>
                                                <option v-for="item in Sessionitems" :key="item.Id"
                                                    v-bind:value="item.Id">
                                                    {{ item.SchSession }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 pt-3">
                                        <div class="input-group">
                                            <label class="input-group-text">Mock</label>
                                            <select v-model="MockIdval" @change="getstudent()"
                                                class="form-control btn btn-success">
                                                <option value="0"></option>
                                                <option v-for="item in mocks" :key="item.Id" v-bind:value="item.Id">
                                                    {{ item.Sch_Class }} {{ item.ExamTitle }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 pt-3">
                                        <div class="input-group">
                                            <label class="input-group-text">Student</label>
                                            <select v-model="StudIdval" class="form-control btn btn-success"
                                                @change="getreport()">
                                                <option value="0"></option>
                                                <option v-for="item in studentitems" :key="item.Id"
                                                    v-bind:value="item.Id">
                                                    {{ item.StudentName }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="mockrep">
                        <div id="divReport">
                            <div v-html="mockrep"></div>
                        </div>
                        <div v-if="isopencomment">
                            <div class="card  bg-secondary-gradient my-3">
                                <div class=" card-body">
                                    <label>Head Teacher Comment</label>
                                    <textarea v-model="headteachercommval" class="form-control"
                                        placeholder="Enter Comment"></textarea>
                                    <div class="text-center card-footer my-3">
                                        <button class="btn btn-secondary" @click.prevent="closecomment()">
                                            Close</button>
                                        <button class="btn btn-default" @click.prevent="processheadteacherrepcomment()">
                                            Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="isopenclassteachercomment">
                            <div class="card bg-secondary-gradient my-3">
                                <div class=" card-body">
                                    <label>Class Teacher Comment</label>
                                    <textarea v-model="classteachercommval" class="form-control"
                                        placeholder="Enter Comment"></textarea>
                                    <div class="text-center card-footer my-3">
                                        <button class="btn btn-secondary" @click.prevent="closeclassteachercomment()">
                                            Close</button>
                                        <button class="btn btn-default"
                                            @click.prevent="processclassteacherrepcomment()"> Submit</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="text-center my-3">
                            <button class="btn btn-default" @click.prevent="opencomment()"><i
                                    class="ni ni-chat-round"></i> Head Teacher Comment</button>
                            <button class="btn btn-default" @click.prevent="openclassteachercomment()"><i
                                    class="ni ni-chat-round"></i> Class Teacher Comment</button>
                            <button class="btn btn-default" @click.prevent="printreport()"><i class="fa fa-print"></i>
                                Print</button>
                            <button class="btn btn-default" @click.prevent="sendreport()"><i class="fa fa-print"></i>
                                Send to Guardian</button>
                        </div>
                    </div>
                </card>
            </div>

            <!-- End charts-->

        </div>

    </div>
</template>

<script>
import moment from 'moment'
import swal from 'sweetalert'
import PageSpinner from "@/components/PageSpinner"
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin"
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin"
export default {
    name: 'mockreportcard',
    mixins: [CheckApiResponse, CheckStaffUser],
    components: {
        PageSpinner
    },
    data() {
        return {
            dataid: 1,
            SessionIdval: '',
            MockIdval: '',
            StudIdval: '',
            headteachercommval: '',
            classteachercommval: '',
            Sessionitems: null,
            studentitems: null,
            schooldetailsitem: null,
            reportheaderrep: null,
            mockrep: null,
            studrep: null,
            gradescalerep: null,
            currdate: null,
            mocks: null,
            process: 'N',
            onloader: 'none',
            isopencomment: false,
            isopenclassteachercomment: false
        }
    },

    methods: {
        opencomment: function () {
            this.isopencomment = true;
        },
        closecomment: function () {
            this.isopencomment = false;
        },
        openclassteachercomment: function () {
            this.isopenclassteachercomment = true;
        },
        closeclassteachercomment: function () {
            this.isopenclassteachercomment = false;
        },
        processclassteacherrepcomment: function () {
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let MockId = this.MockIdval;
            let Comment = this.classteachercommval;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                StudId,
                SessionId,
                MockId,
                Comment,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('Processmockcommentbyteacher', userdata)
                .then(resp => {
                    this.mockrep = resp;
                    this.isopenclassteachercomment = false;
                    this.isopencomment = false;
                    this.onloader = 'none';
                    swal({
                        title: "Success!",
                        text: 'Submitted successfully',
                        icon: "success",
                        button: "Ok",
                    });

                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        openheadteachercomment: function () {
            this.isheadteacheropencomment = true;
        },
        closeheadteachercomment: function () {
            this.isheadteacheropencomment = false;
        },
        processheadteacherrepcomment: function () {
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let MockId = this.MockIdval;
            let Comment = this.headteachercommval;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                StudId,
                SessionId,
                MockId,
                Comment,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('Processmockcommentbyheadteacher', userdata)
                .then(resp => {
                    this.mockrep = resp;
                    this.isopenclassteachercomment = false;
                    this.isopencomment = false;
                    this.onloader = 'none';
                    swal({
                        title: "Success!",
                        text: 'Submitted successfully',
                        icon: "success",
                        button: "Ok",
                    });

                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getmockcomment: function () {
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let MockId = this.MockIdval;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                StudId,
                SessionId,
                MockId,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('getmockcomment', userdata)
                .then(resp => {
                    if (resp != null) {
                        this.teachercommval = resp.TeacherComment;
                        this.teacherval = resp.ClassTeacher;
                        this.teachercommdateval = resp.DateTeacherComment;
                        this.headteachercommval = resp.HeadTeacherComment;
                        this.headteacherval = resp.HeadTeacher;
                        this.headteachercommdateval = resp.DateHeadTeacherComment;
                    }
                    this.onloader = 'none';
                    swal({
                        title: "Success!",
                        text: 'Submitted successfully',
                        icon: "success",
                        button: "Ok",
                    });

                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        sendreport: function () {
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let ClassId = this.ClassIdval;
            let OptionId = this.OptionIdval;
            let ArmId = this.ArmIdval;
            let processop = 'N';
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                StudId,
                SessionId,
                TermId,
                ClassId,
                OptionId,
                ArmId,
                processop,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('sendreportmid', userdata)
                .then(() => {
                    this.onloader = 'none';
                    swal({
                        title: "Success!",
                        text: 'Sent successfully',
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getcurendate: function () {
            var today = new Date();
            this.currdate = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
        },
        printreport: function () {
            // Get HTML to print from element
            const prtHtml = document.getElementById('divReport').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                ${prtHtml}
            </body>
            </html>`);

            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
        },

        getregsession: function () {
            this.studentitems = null;
            this.termitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.Sessionitems = null;
            this.SessionIdval = 0;
            this.onloader = 'flex'
            this.$store.dispatch('getregsession')
                .then(resp => {
                    this.Sessionitems = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    console.log(err);
                    this.onloader = 'none'
                    this.$store.dispatch('logout')
                    this.$router.push('/login')
                })
        },
        getmock: function () {
            this.mockrep = null;
            this.onloader = 'flex'
            this.$store.dispatch('getmock')
                .then(resp => {
                    this.mocks = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getstudent: function () {
            this.studentitems = null;
            this.StudIdval = 0;
            let MockId = this.MockIdval;
            if (MockId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('getstudentformock', MockId)
                    .then(resp => {
                        this.studentitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getstudentbyid: function () {

            let StudId = this.StudIdval;
            if (StudId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('getstudentbyid', StudId)
                    .then(resp => {
                        this.studrep = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getreport: function () {
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let MockId = this.MockIdval;
            this.mockrep = null;
            this.onloader = 'flex'
            this.$store.dispatch('getmockreport', { MockId, SessionId, StudId })
                .then(resp => {

                    this.mockrep = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getmockreportheader: function () {
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let MockId = this.MockIdval;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                StudId,
                SessionId,
                MockId,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('getmockreportheader', userdata)
                .then(resp => {
                    this.reportheaderrep = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getgradescalereport: function () {
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let MockId = this.MockIdval;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                StudId,
                SessionId,
                MockId,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('getgradescalformock', userdata)
                .then(resp => {
                    this.gradescalerep = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        frontEndDateFormat: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
        backEndDateFormat: function (date) {
            return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }

    },
    mounted() {
        if (this.$store.state.auth.user_status_id != 1) {
            this.$store.dispatch('logout')
            this.$router.push('/login')
        } else {
            this.getregsession();
            this.getmock();
            this.getcurendate();
        }

    }
};
</script>

<style>
.borderall {
    text-align: center;
    border: 3px solid #000;
}

.bordercol {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    border: thin solid #000;
}

.padbody {
    padding: 20px;
}

.padhead {
    padding: 5px;
    border: 3px solid #606060;
}

.pad {
    padding: 10px;
}

table {
    font-size: 9pt;
    width: 100%;
}

.bodytext {
    font-size: 9pt;
}
</style>
