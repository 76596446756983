<template>
  <div>
    <div id="loaderoverlay" v-bind:style="{ display: onloader }">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="loaderspinner"></div>
      </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad</router-link
      >
      <span class="text-white"
        ><i class="ni ni-bold-right"></i>School Registration
      </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div v-if="openform">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body">
              <div class="form-horizontal">
                <form role="form" @submit.prevent="submitdata">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="card bg-gradient-success">
                        <div class="card-body text-center">
                          <img :src="Logo" class="img-fluid" alt="" />
                          <h3 class="text-white">{{ SchoolName }}</h3>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card bg-gradient-success">
                        <div class="card-body">
                          <div class="text-center">
                            <input
                              v-model="SMSUnitval"
                              class="form-control"
                              type="number"
                              placeholder="Enter Sms Unit"
                              required
                            />
                            <textarea
                              v-model="PaymentDescriptionval"
                              class="form-control"
                              placeholder="Description"
                              rows="2"
                              required
                            ></textarea>

                            <div>
                              <button
                                type="button"
                                @click.stop="closeform()"
                                class="btn btn-secondary"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                class="btn btn-primary my-4"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="form-inline">
            <div class="input-group">
              <input
                v-model="searchval"
                class="form-control"
                @keyup="getsearch"
                placeholder="Search..."
              />
              <div class="input-group-append">
                <button
                  type="button"
                  @click.prevent="getsearch"
                  class="btn btn-secondary"
                >
                  <i class="fa fa-search"></i> Search
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3" v-for="item in smsItems" :key="item.Id">
              <div class="card bg-translucent-success shrink my-3">
                <div class="card-body text-center">
                  <img
                    v-if="item.Logo"
                    :src="$store.state.auth.baseurl + item.Logo.substr(1)"
                    class="rounded-circle"
                    alt=""
                    height="60"
                  />
                  <img
                    v-else
                    src="img/brand/empty.png"
                    class="rounded-circle"
                    alt=""
                    height="60"
                  />
                  <h5 class="text-white mt-2">{{ item.Org_Name }}</h5>
                  <h6 class="text-white">{{ item.ShortName }}</h6>
                  <h1 class="badge-secondary text-dark">{{ item.SMSUnit }}</h1>

                  <div>
                    <button
                      class="btn btn-secondary btn-sm my-2"
                      v-on:click="computenewsms(item.OrgId)"
                    >
                      <i class="fa fa-edit"></i> Add SMS Unit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
export default {
  name: "school",
  data() {
    return {
      SMSUnitval: 0,
      PaymentDescriptionval: "",
      SchoolName: "",
      Logo: "img/brand/empty.png",
      OrgIdval: 0,
      smsItems: null,
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none",
    };
  },

  methods: {
    newterm: function () {
      this.SMSUnitval = 0;
      this.PaymentDescriptionval = "";
      this.searchval = "";
      this.openform = true;
      this.process = "N";
    },
    closeform: function () {
      this.SMSUnitval = "";
      this.PaymentDescriptionval = "";
      this.searchval = "";
      this.openform = false;
      this.process = "N";
    },
    computenewsms: function (Id) {
      this.onloader = "flex";
      this.OrgIdval = Id;
      this.$store
        .dispatch("getschoolbyid", Id)
        .then((resp) => {
          if (resp.Logo != null) {
            this.Logo = this.$store.state.auth.baseurl + resp.Logo.substr(1);
          }
          this.SchoolName = resp.Org_Name + " - (" + resp.ShortName + ")";
          this.newterm();
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata: function () {
      let SMSUnit = this.SMSUnitval;
      let OrgId = this.OrgIdval;
      let PaymentDescription = this.PaymentDescriptionval;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      this.onloader = "flex";
      this.$store
        .dispatch("processsmspaymentbyadmin", {
          SMSUnit,
          OrgId,
          PaymentDescription,
          username,
          accesskey,
        })
        .then((resp) => {
          this.smsItems = resp;
          this.closeform();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsms: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getsms")
        .then((resp) => {
          this.smsItems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsearch: function () {
      if (this.searchval) {
        this.$store
          .dispatch("getsmsbysearch", this.searchval)
          .then((resp) => {
            this.smsItems = resp;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (
      this.$store.state.auth.user_status_id == 6 ||
      this.$store.state.auth.user_status_id == 7 ||
      this.$store.state.auth.user_status_id == 9
    ) {
      this.getsms();
    } else {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    }
  },
};
</script>

<style></style>
