<template>
<div>
    <div id="loaderoverlay" v-bind:style={display:onloader}>
        <div class="w-100 d-flex justify-content-center align-items-center">
            <div class="loaderspinner"></div>
        </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <router-link to="/dashboard" class="text-white pl-3">
            <i class="ni ni-tv-2"></i> Dashborad</router-link><i class="ni ni-bold-right text-white"></i>
        <router-link to="/smsmenu" class="text-white">
            <i class="ni ni-badge"></i> SMS
        </router-link>
        <span class="text-white"><i class="ni ni-bold-right"></i>SMS to Others</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">

        <div class="mb-5 mb-xl-0">
            <card header-classes="bg-transparent">
                <div class="card bg-secondary border-0 mb-0">
                    <div class="card-body">
                        <div class="form-horizontal">
                            <form role="form" @submit.prevent="submitdata">
                                <div class="card bg-gradient-success">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <label class="text-white">Enter Phone Number</label>
                                            <textarea v-model="PhoneNosval" class="form-control"></textarea>
                                            <span class="text-muted">For Multiple phone number, make sure you seperate it by comma <b>(',')</b> </span>
                                        </div>
                                        <div class="form-group">
                                            <label class="text-white">Enter Message</label>
                                            <textarea v-model="MessageBodyval" @keyup="countchar()" @keydown="countchar()" @keypress="countchar()" class="form-control"></textarea>
                                            <span class="text-white">{{Detailval}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mb-3">
                                    <button type="submit" class="btn btn-primary my-4">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </card>
        </div>

        <!-- End charts-->

    </div>

</div>
</template>

<script>
import swal from 'sweetalert'
export default {
    name: 'classattendance',
    data() {
        return {
            PhoneNosval: '',
            MessageBodyval: '',
            Detailval: '',
            ssmsitem: null,
            process: 'N',
            onloader: 'none'
        }
    },

    methods: {
        countchar: function () {
            this.Detailval = '';
            var n = this.MessageBodyval.length;
            var smspage = 1;
            var md = Math.floor(n / 160);

            if (md > 0) {
                smspage = (smspage * md) + 1;
            }
            if (smspage > 2) {
                this.Detailval = 'ALERT => : Maximum pages to be sent through this app is 2';
                swal({
                    title: "Oops!",
                    text: 'Maximum pages to be sent through this app is 2',
                    icon: "error",
                    button: "Ok",
                });

            } else {
                this.Detailval = n + ' Total characters, ' + smspage + ' Total page(s)';
            }
        },
        deletesmstempbyid: function (Id) {
            this.onloader = 'flex'
            this.process = 'D';
            this.$store.dispatch('deletesmstempbyid', Id)
                .then(resp => {
                    this.ssmsitem = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        submitdata: function () {
            let PhoneNos = this.PhoneNosval;
            let Description = 'Message to Staff(s)';
            let MessageBody = this.MessageBodyval;
            let processop = this.process;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                PhoneNos,
                Description,
                MessageBody,
                processop,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('processsmsother', userdata)
                .then((resp) => {
                    this.ssmsitem = resp;
                    this.process = 'N';
                    this.onloader = 'none'
                    swal({
                        title: "Success!",
                        text: 'Sent successfully',
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getsmsstaff: function () {
            this.onloader = 'flex'
            this.$store.dispatch('getsmsstaff')
                .then((resp) => {
                    this.ssmsitem = resp;
                    this.onloader = 'none'
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })

        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }
    },
    mounted() {
        if (this.$store.state.auth.user_status_id != 1) {
            this.$store.dispatch('logout')
            this.$router.push('/login')
        } else {
            this.getsmsstaff();
        }

    }
};
</script>

<style></style>
