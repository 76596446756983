<template>
  <div>
    <Staffportal
      :userName="$store.state.auth.user_fullName"
      :userPhoneNo="$store.state.auth.user_phone"
      :userEmailAddress=$store.state.auth.user_email
      v-if="$store.state.auth.user_status_id == 1"
    ></Staffportal>
    <Studentportal
      :userName="$store.state.auth.user_fullName"
      :userPhoneNo="$store.state.auth.user_phone"
      :userEmailAddress=$store.state.auth.user_email
      v-if="
        $store.state.auth.user_status_id == 2 ||
          $store.state.auth.user_status_id == 8
      "
    ></Studentportal>
    <Adminportal
      :userName="$store.state.auth.user_fullName"
      :userPhoneNo="$store.state.auth.user_phone"
      :userEmailAddress=$store.state.auth.user_email
      v-if="
        $store.state.auth.user_status_id == 6 ||
          $store.state.auth.user_status_id == 7 ||
          $store.state.auth.user_status_id == 9 ||
           $store.state.auth.user_status_id == 11
      "
    ></Adminportal>
    
  </div>
</template>
<script>
import Staffportal from "./Dashboardcomponent/Staffportal";
import Studentportal from "./Dashboardcomponent/Studentportal";
import Adminportal from "./Dashboardcomponent/Adminportal";
export default {
  components: {
    Staffportal,
    Studentportal,
    Adminportal,
  },

  methods: {
    getuser: function() {
      this.$store
        .dispatch("getuser")
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getuser();
  },
};
</script>
<style></style>
