<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link>
      <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i> School Subject</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div class="row">
          <div class="col-md-6">
            <div class="card bg-success border-0 mb-0">
              <div class="card-body">
                <div class="form-horizontal">
                  <form role="form" @submit.prevent="submitdata">
                    <div class="form-group">
                      <label class="text-white">School Type</label>
                      <select v-model="StatusIdval" @change="getsubjectsetup" class="form-control">
                        <option value="0"></option>
                        <option v-for="item in schooltypes" :key="item.Id" v-bind:value="item.Id">
                          {{ item.StatusName }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label class="text-white">Subject</label>
                      <input v-model="Titleval" class="form-control" />
                    </div>

                    <div class="text-center mb-3">
                      <button type="submit" class="btn btn-primary my-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card bg-secondary border-0 mb-0">
              <div class="card-body">
                <div class="form-inline">
                  <div class="input-group">
                    <input v-model="searchval" class="form-control" @keyup="getsearch" placeholder="Search..." />
                    <div class="input-group-append">
                      <button type="button" @click.prevent="getsearch" class="btn btn-secondary">
                        <i class="fa fa-search"></i> Search
                      </button>
                    </div>
                  </div>
                </div>
                <div class="table-responsive mt-2">
                  <table class="table table-bordered table-striped table-hover">
                    <thead>
                      <tr>
                        <td>Title</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in subjectitems" :key="item.Id">
                        <td>{{ item.Title }}</td>
                        <td>
                          <button class="btn btn-secondary btn-sm" v-on:click="edititem(item.Id)">
                            <i class="fa fa-edit"></i> Edit
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import swal from "sweetalert"
import PageSpinner from "@/components/PageSpinner"

export default {
  name: "SubjectSetup", 
  components: {
    PageSpinner
  },
  data() {
    return {
      Idval: 1,
      StatusIdval: "",
      Titleval: "",
      searchval: "",
      subjectitems: "",
      process: "N",
      onloader: "none",
    };
  },
  computed: {
    ...mapState({
      schooltypes: (state) => state.appdatas.schooltypes,
    }),
  },
  methods: {
    newterm: function () {
      this.Titleval = "";
      this.process = "N";
    },
    edititem: function (Id) {
      this.onloader = "flex";
      let Idd = this.StatusIdval;
      this.$store
        .dispatch("getsubjectsetupbyid", {
          Id,
          Idd,
        })
        .then((resp) => {
          this.Idval = Id;
          this.Titleval = resp.Title;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata: function () {
      let StatusId = this.StatusIdval;
      let Title = this.Titleval;
      let Id = this.Idval;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      this.onloader = "flex";
      let payload = {
        Id,
        StatusId,
        Title,
        processop,
        username,
        accesskey,
      };
      this.$store
        .dispatch("processsubjectsetup", payload)
        .then((resp) => {
          this.subjectitems = resp;
          this.Titleval = "";
          this.process = "N";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsubjectsetup: function () {
      this.onloader = "flex";
      let id = this.StatusIdval;
      this.$store
        .dispatch("getsubjectsetup", id)
        .then((resp) => {
          this.subjectitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getsearch: function () {
      let Id = this.StatusIdval;
      let item = this.searchval;
      let userdata = {
        Id,
        item,
      };
      this.$store
        .dispatch("getsubjectsetupsearch", userdata)
        .then((resp) => {
          this.subjectitems = resp;
        })
        .catch((err) => {
          this.checkresponse(err.response.data);
        });
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (
      this.$store.state.auth.user_status_id == 6 ||
      this.$store.state.auth.user_status_id == 7 ||
      this.$store.state.auth.user_status_id == 9 ||
      this.$store.state.auth.user_status_id == 11
    ) {
      //this.getprivacypolicy();
    } else {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    }
  },
};
</script>

<style>

</style>
