<template>
  <div class="row no-gutters">
    <!-- Header -->
    <div class="col-md-8 bg-secondary" v-if="currentnews">
      <router-link to="/home" class="btn btn-secondary btn-block">
        <i class="fa fa-arrow-left"></i> Back to Home
      </router-link>
      <div class="mx-3 my-5">
        <div>
          <img
            v-if="currentnews.ImagePath"
            :src="$store.state.auth.baseurl + currentnews.ImagePath.substr(1)"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="text-left">
          <h1>{{ currentnews.NewsHeading }}</h1>
          <div v-html="currentnews.NewsBody"></div>
          <hr />
          <div v-for="item in comment" :key="item.Id">
            <div class="row">
              <div class="col-md-3">
                <img
                  v-if="item.Passport"
                  :src="$store.state.auth.baseurl + item.Passport.substr(1)"
                  class="img-fluid"
                  alt=""
                />
                <img
                  v-else
                  src="img/brand/loguser.png"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="col-md-9">
                <h6>{{ item.CommentedBy }}</h6>
                <p>{{ item.NewsComment }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-link to="/home" class="btn btn-secondary btn-block">
        <i class="fa fa-arrow-left"></i> Back to Home
      </router-link>
    </div>
    <div class="col-md-4">
      <div class="my-5">
        <div
          class="px-1 py-5 row text-white text-center bg-translucent-dark"
          v-for="item in news"
          :key="item.Id"
        >
          <div class="col-lg-12 col-md-12 col-sm-6 col">
            <div class="mx-4 shrink">
              <button
                class="btn btn-secondary newsrow my-2"
                v-on:click="readnews(item.NewsHeading)"
              >
                <div>
                  <img
                    v-if="item.ImagePath"
                    :src="$store.state.auth.baseurl + item.ImagePath.substr(1)"
                    class="img-fluid"
                    alt=""
                  />
                  <img
                    v-else
                    src="img/brand/empty.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="text-left">
                  <h1>{{ item.NewsHeading }}</h1>
                  <div v-html="item.Newspreview"></div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="text-center mb-3" v-if="news">
          <router-link to="/morenews" class="btn btn-secondary">
            Read More News <i class="fa fa-arrow-right"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      NewsHeadingval: localStorage.getItem("NewsHeading") || "",
      news: null,
      comment: null,
      currentnews: null,
    };
  },
  methods: {
    readnews: function (heading) {
      this.onloader = "flex";
      this.$store
        .dispatch("getnewsforexternalbyheading", heading)
        .then((resp) => {
          this.getnewscomment(resp.Id);
          this.currentnews = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err.response.data);
        });
    },
    getnewscomment: function (NewsId) {
      this.onloader = "flex";
      this.$store
        .dispatch("getnewscomment", NewsId)
        .then((resp) => {
          this.comment = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err.response.data);
        });
    },
    getnews: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getnewsforexternaltop10")
        .then((resp) => {
          this.news = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err.response.data);
        });
    },
  },
  mounted() {
    let heading = this.$route.query.he;
    if (heading != null || heading != undefined) {
      this.readnews(heading);
    } else {
      if (this.NewsHeadingval.length > 0) {
        this.readnews(this.NewsHeadingval);
      } else {
        this.$router.push("/morenews");
      }
    }
    this.getnews();
  },
};
</script>

<style>
.newsbody {
  height: 1000px;
  overflow: auto;
}
.newsrow {
  border-left: 6px solid white;
}
.module-footer {
  height: 80px;
}
</style>
