export default {
    methods: {
        GetLibraryBookStockSummary() {
            this.onloader = "flex";
            this.$store
              .dispatch("libraryBookStock/GetLibraryBookStockSummary")
              .then(resp => {
                this.LibraryBookStockSummaryItems = resp;
                this.onloader = "none";
              })
              .catch(err => {
                this.onloader = "none";
                this.checkresponse(err.response.data);
              });
          },
    },
    mounted () {
      this.GetLibraryBookStockSummary()
    }
  }