<template>
<div>
    <div id="loaderoverlay" v-bind:style={display:onloader}>
        <div class="w-100 d-flex justify-content-center align-items-center">
            <div class="loaderspinner"></div>
        </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <router-link to="/dashboard" class="text-white pl-3">
            <i class="ni ni-tv-2"></i> Dashborad</router-link>
        <span class="text-white"><i class="ni ni-bold-right"></i> Registered Entrance Student</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
        <div class="row">
            <div class="col-xl-8 mb-5 mb-xl-0">
                <card header-classes="bg-transparent">
                    <div class="form-inline">
                       
                        <div class=" input-group">
                            <input v-model="searchval" class="form-control" @keyup="getsearch" placeholder="Search...">
                            <div class=" input-group-append">
                                <button type="button" @click.prevent="getsearch" class="btn btn-secondary"><i class="fa fa-search"></i> Search </button>
                            </div>
                        </div>

                    </div>
                    <div class="table-responsive mt-2">
                        <table class="table table-bordered table-striped table-hover">
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>First Name</td>
                                    <td>Middle Name</td>
                                    <td>Last Name</td>                                   
                                    <td>Class</td>
                                    <td>Primary Contact Name</td>
                                    <td>Primary Email</td>
                                    <td>Primary Phone No.</td>
                                    <td>Registered Date</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in items" :key="item.Id">
                                    <td><button class="btn btn-secondary btn-sm" v-on:click="edititem(item.Id)"><i class="fa fa-remove"></i> Remove</button></td>
                                    <td>{{ item.FirstName }}</td>
                                    <td>{{ item.MiddleName }}</td>
                                    <td>{{ item.LastName }}</td>                                   
                                    <td>{{ item.Sch_Class }}</td>
                                    <td>{{ item.PrimaryContactName }}</td>
                                    <td>{{ item.PrimaryEmail }}</td>
                                    <td>{{ item.PrimaryPhoneNo }}</td>
                                    <td>{{ item.DateCreated | formatdate }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </card>
            </div>
            <div class="col-xl-4">
                <card type="default" header-classes="bg-transparent">
                    <Submenu></Submenu>
                </card>
            </div>
        </div>
        <!-- End charts-->

    </div>

</div>
</template>

<script>
import Submenu from '../Submenu.vue';
import swal from 'sweetalert'
export default {
    components: {
        Submenu
    },
    name: 'class',
    data() {
        return {
            Idval: 1,
            items: null,
            onloader: 'none'
        }
    },

    methods: {
        edititem: function (Id) {
            this.onloader = 'flex'
            this.$store.dispatch('removeentranceregistration', Id)
                .then(resp => {
                    this.items = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getentranceregistration: function () {
            this.$store.dispatch('getentranceregistration')
                .then((resp) => {
                    this.items = resp;
                })
                .catch(err => {
                    this.checkresponse(err.response.data);
                })
        },
        getsearch: function () {
            this.$store.dispatch('getentranceregistrationsearch', this.searchval)
                .then((resp) => {
                    this.items = resp;
                })
                .catch(err => {
                    this.checkresponse(err.response.data);
                })
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }
    },
    mounted() {
        if (this.$store.state.auth.user_status_id != 1) {
            this.$store.dispatch('logout')
            this.$router.push('/login')
        } else {
            this.getentranceregistration();
        }

    }
};
</script>

<style></style>
