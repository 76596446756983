<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <div class="row">
      <div class="col-lg-8 justify-content-center pb-3 text-center"></div>
      <div class="col-lg-4">
        <div class="animate__animated  animate__slideInDown px-5 py-5 text-white bg-dark">
          <div class="text-center text-muted my-3">
            <p class="mt-3">Login to School Account</p>
          </div>

          <form role="form" @submit.prevent="userlogin">
            <base-input
              class="input-group-alternative mb-3"
              placeholder="Username"
              addon-left-icon="ni ni ni-single-02"
              v-model="username"
            >
            </base-input>
            <base-input
              class="input-group-alternative"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="password"
            >
            </base-input>
            <div class="mb-3">
              <router-link to="/retrievepassword" class="text-primary"
                ><small class="pl-3">Forgot password?</small></router-link
              >
            </div>

            <base-checkbox class="custom-control-alternative">
              <span class="text-muted">Remember me</span>
            </base-checkbox>
            <div class="text-center">
              <button
                type="submit"
                class="btn btn-primary my-3"
                :disabled="$store.state.appdatas.disenableifNotOnline"
              >
                Sign in <i class="fa fa-arrow-right"></i>
              </button>
            </div>
          </form>
          <div class="text-center pt-2">
            I want to onboard my school
            <router-link to="/registersch" class="text-primary"
              >register here</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner"
export default {
  name: "login",
  components: {PageSpinner},
  data() {
    return {
      username: "",
      password: "",
      errormsg: "",
      onloader: "none",
    };
  },
  methods: {
    userlogin: function () {
      let username = this.username;
      let password = this.password;
      let accesskey = this.$store.state.auth.accesskey;
      this.onloader = "flex";
      let userdata = {
        username,
        password,
        accesskey,
      };

      this.$store
        .dispatch("login", userdata)
        .then((resp) => {
          this.getbank();
          this.getschooltypes();       
          if (resp != null) {
            if (resp.Status_Id == 1) {
              this.getstaffdetails();
            } else {
              this.onloader = "none";
              this.$router.push("/dashboard");
            }
          }
        })
        .catch((err) => {
          this.password = "";
          this.onloader = "none";
          if (err.response.data === "Yet to Logon") {
            this.$router.push("/changepassword");
          } else {
            swal({
              title: "Oops!",
              text: err.response.data,
              icon: "error",
              button: "Ok",
            });
          }
        });
    },
    getbank: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getbank")
        .then(() => {
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
        });
    },
    getschooltypes: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getschooltypes")
        .then(() => {
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
        });
    },
    getstate: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getstates")
        .then(() => {
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
        });
    },   
    getprivacypolicy: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getprivacypolicy")
        .then(() => {
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
        });
    },
    getstaffdetails: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getstaffdetails")
        .then(() => {
          this.onloader = "none";
          this.$router.push("/dashboard");
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
        });
    },
    getreportrequest: function () {
      let passcode = this.$route.query.rep;
      if (passcode != null || passcode != undefined) {
        this.onloader = "flex";
        this.$store
          .dispatch("passcodeaccess", passcode)
          .then((resp) => {
            this.onloader = "none";
            if (resp.ReportType == "Full") {
              this.$router.push("/studreportcard?rep=" + passcode);
            }
            if (resp.ReportType == "Mid") {
              this.$router.push("/studreportcardmid?rep=" + passcode);
            }
          })
          .catch((err) => {
            this.onloader = "none";
            swal({
              title: "Oops!",
              text: err.response.data,
              icon: "error",
              button: "Ok",
            });
          });
      }
    },
    GetUserStatus: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("GetUserStatus")
        .then(() => {
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
        });
    },
  },
  mounted() {
    this.getprivacypolicy();
    this.getstate();
    this.getreportrequest();
    this.GetUserStatus();  
   
  },
};
</script>

<style></style>
