<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        <i class="ni ni-tv-2"></i> Dashborad
      </router-link>
      <span class="text-white"><i class="ni ni-bold-right"></i>School Registration
      </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <card header-classes="bg-transparent">
        <div v-if="openform">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body">
              <div class="form-horizontal">
                <form role="form" @submit.prevent="submitdata">
                  <div class="card bg-gradient-success">
                    <h5 class="card-header">Biodata</h5>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 my-2">
                          <img v-if="uploadedimage" height="100" :src="uploadedimage" />
                          <img v-else height="100" src="img/brand/empty.png" />
                          <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" />
                          <br />
                          <button class="btn btn-outline-secondary" @click.prevent="submitFile()">
                            <i class="ni ni-cloud-upload-96"></i>Upload
                          </button>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">School Name *</label>
                            <input v-model="SchoolNameval" class="form-control" required />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Short Name *</label>
                            <input v-model="SchoolShortNameval" class="form-control" max="11" required />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Message Header *</label>
                            <input v-model="SchoolMessageHeaderval" max="11" class="form-control" required />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Phone Number *</label>
                            <input type="tel" v-model="SchoolPhoneNoval" class="form-control" required />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Email Address *</label>
                            <input type="email" v-model="SchoolEmailAddval" class="form-control" required />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Web Address</label>
                            <input type="url" v-model="SchoolWebAddval" class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">School Type</label>
                            <select v-model="SchoolStatusIdval" class="form-control">
                              <option value="0"></option>
                              <option v-for="item in schooltypes" :key="item.Id" v-bind:value="item.Id">
                                {{ item.StatusName }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="card bg-gradient-success">
                    <h5 class="card-header">Address</h5>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">State</label>
                            <select v-model="SchoolStateIdval" class="form-control" @change="getlga()">
                              <option value="0"></option>
                              <option v-for="item in stateitems" :key="item.Id" v-bind:value="item.Id">
                                {{ item.StateName }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Local Government</label>
                            <select v-model="SchoolLGIdval" class="form-control">
                              <option value="0"></option>
                              <option v-for="item in lgaitems" :key="item.Id" v-bind:value="item.Id">
                                {{ item.Local_Govt_Name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">City</label>
                            <base-input v-model="SchoolCityval"></base-input>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="text-white">Address</label>
                            <textarea v-model="SchoolAddressval" class="form-control"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="card bg-gradient-success">
                    <h5 class="card-header">Bank Details</h5>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Bank</label>
                            <select v-model="BankIdval" class="form-control">
                              <option value="0"></option>
                              <option v-for="item in bankitems" :key="item.Id" v-bind:value="item.Id">
                                {{ item.BankName }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Account Name</label>
                            <base-input v-model="AccountNameval"></base-input>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Account No.</label>
                            <base-input v-model="AccountNoval"></base-input>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="text-white">Account Type</label>
                            <select class="form-control" v-model="AccountTypeval">
                              <option value="0"></option>
                              <option value="Current">Current</option>
                              <option value="Saving">Saving</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="text-center mb-3">
                    <button type="button" @click.stop="closeform()" class="btn btn-secondary">
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-primary my-4">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="justify-content-end form-inline">
            <button type="button" @click.prevent="newterm" class="btn btn-default">
              <i class="ni ni-fat-add"></i>Add New
            </button>
            <base-input class="input-group-alternative" placeholder="Search here..." addon-left-icon="fa fa-search"
              v-model="searchval">
            </base-input>
          </div>
          <div class="mt-2 row">
            <div class="col-md-4" v-for="item in searchedResultQuery" :key="item.Id">
              <div class="card animate__animated animate__flipInX text-center m-2">
                <div class="card bg-success shrink my-3">
                  <div class="card-body text-center">
                    <img v-if="item.Logo" :src="item.Logo" class="rounded" alt="" height="50" />
                    <img v-else src="img/brand/empty.png" class="rounded" alt="" height="50" />
                    <h6> <small class="small text-secondary">{{ item.StatusName }}</small> </h6>
                    <h5 class="text-white">{{ item.Org_Name }}</h5>
                    <h6 class="text-white"><small>{{ item.Phone_No }} || {{ item.Email_Add }} || {{ item.ShortName
                    }}</small> </h6>
                    <div class="text-center">
                      <button class="btn btn-secondary btn-sm m-2" v-on:click="edititem(item.Id)">
                        <i class="fa fa-edit"></i> Edit
                      </button> <button class="btn btn-default btn-sm m-2" v-on:click="openstaff(item.Id)">
                        <i class="fa fa-users"></i> Staffs
                      </button>
                      <div v-if="$store.state.auth.user_status_id == 6">
 <button v-if="item.IsDeleted" class="btn btn-default btn-sm m-2"
                        v-on:click="deleteschool(item.Id)">
                        <i class="fa fa-history"></i> Restore
                      </button>
                      <button v-else class="btn btn-danger btn-sm m-2" v-on:click="deleteschool(item.Id)">
                        <i class="ni ni-fat-remove"></i> Delete
                      </button>
                      <button v-if="item.IsActivated" class="btn btn-danger btn-sm m-2"
                        v-on:click="activateschool(item.Id)">
                        <i class="fa fa-remove"></i> Deactivate
                      </button>
                      <button v-else class="btn btn-default btn-sm m-2" v-on:click="activateschool(item.Id)">
                        <i class="ni ni-fat-history"></i> Activate
                      </button>
                      </div>                    
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import swal from "sweetalert";
import PageSpinner from "@/components/PageSpinner";
export default {
  name: "school",
  components: {
    PageSpinner,
  },
  data() {
    return {
      Idval: 1,
      AddressIdval: 1,
      SchoolAddressval: "",
      SchoolCityval: "",
      SchoolLGIdval: "",
      SchoolStateIdval: 1,
      SchoolNameval: 1,
      SchoolShortNameval: 1,
      SchoolPhoneNoval: "",
      SchoolEmailAddval: "",
      SchoolWebAddval: "",
      SchoolLogoval: null,
      SchoolStatusIdval: "",
      SchoolMessageHeaderval: "",
      BankIdval: null,
      AccountNameval: "",
      AccountTypeval: "",
      AccountNoval: "",
      lgaitems: null,
      schoolitems: null,
      file: "",
      uploadedimage: "",
      searchval: "",
      process: "N",
      openform: false,
      onloader: "none",
    };
  },
  computed: {
    ...mapState({
      schooltypes: (state) => state.appdatas.schooltypes,
      stateitems: (state) => state.addressinfo.stateitems,
      bankitems: (state) => state.appdatas.bankitems,
    }),
    searchedResultQuery() {
      if (this.searchval) {
        return this.schoolitems.filter((item) => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item.Org_Name.toLowerCase().includes(v) ||
                item.ShortName.toLowerCase().includes(v) ||
                item.Email_Add.toLowerCase().includes(v) ||
                item.StatusName.toLowerCase().includes(v) ||
                item.OrgAddress.toLowerCase().includes(v)
            );
        });
      } else {
        return this.schoolitems;
      }
    },
  },
  methods: {
    newterm: function () {
      this.SchoolAddressval = "";
      this.SchoolCityval = "";
      this.SchoolStateIdval = 0;
      this.SchoolNameval = "";
      this.SchoolShortNameval = "";
      this.SchoolPhoneNoval = "";
      this.SchoolEmailAddval = "";
      this.SchoolWebAddval = "";
      this.SchoolLogoval = "";
      this.SchoolStatusIdval = "";
      this.SchoolMessageHeaderval = "";
      this.BankIdval = "";
      this.AccountNameval = "";
      this.AccountTypeval = "";
      this.AccountNoval = "";
      this.searchval = "";
      this.file = "";
      this.uploadedimage = "";
      this.openform = true;
      this.process = "N";
      this.inputdisable = false;
    },
    closeform: function () {
      this.SchoolAddressval = "";
      this.SchoolCityval = "";
      this.SchoolStateIdval = 0;
      this.SchoolNameval = "";
      this.SchoolShortNameval = "";
      this.SchoolPhoneNoval = "";
      this.SchoolEmailAddval = "";
      this.SchoolWebAddval = "";
      this.SchoolLogoval = "";
      this.SchoolStatusIdval = "";
      this.SchoolMessageHeaderval = "";
      this.BankIdval = "";
      this.AccountNameval = "";
      this.AccountTypeval = "";
      this.AccountNoval = "";
      this.file = "";
      this.uploadedimage = "";
      this.searchval = "";
      this.openform = false;
      this.process = "N";
      this.inputdisable = false;
    },
    openstaff: function (Id) {
      localStorage.setItem("SchoolId", Id);
      this.$router.push("/schoolstaff");
    },
    edititem: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getschoolbyid", Id)
        .then((resp) => {
          this.Idval = resp.Id;
          this.AddressIdval = resp.AddId;
          this.uploadedimage = resp.Logo;
          this.SchoolAddressval = resp.AddressOne;
          this.SchoolCityval = resp.City;
          this.SchoolStateIdval = resp.StateId;
          this.getlga();
          this.SchoolLGIdval = resp.LGId;
          this.SchoolNameval = resp.Org_Name;
          this.SchoolShortNameval = resp.ShortName;
          this.SchoolPhoneNoval = resp.Phone_No;
          this.SchoolEmailAddval = resp.Email_Add;
          this.SchoolWebAddval = resp.Web_Add;
          this.SchoolLogoval = resp.Logo;
          this.SchoolStatusIdval = resp.StatusId;
          this.SchoolMessageHeaderval = resp.MessageHeader;
          this.BankIdval = resp.BankId;
          this.AccountNameval = resp.AccountName;
          this.AccountTypeval = resp.AccountType;
          this.AccountNoval = resp.AccountNumber;
          this.openform = true;
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    deleteschool: function (Id) {
      if (this.$store.state.auth.user_status_id == 6) {
        this.onloader = "flex";
        this.$store
          .dispatch("deleteschool", Id)
          .then((resp) => {
            this.schoolitems = resp;
            this.searchval = "";
            this.onloader = "none";
            swal({
              title: "Success!",
              text: "Processed successfully",
              icon: "success",
              button: "Ok",
            });
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    activateschool: function (Id) {
      if (this.$store.state.auth.user_status_id == 6) {
        this.onloader = "flex";
        this.$store
          .dispatch("activateschool", Id)
          .then((resp) => {
            this.schoolitems = resp;
            this.searchval = "";
            this.onloader = "none";
            swal({
              title: "Success!",
              text: "Processed successfully",
              icon: "success",
              button: "Ok",
            });
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    submitdata: function () {
      let Id = this.Idval;
      let AddressId = this.AddressIdval;
      let SchoolAddress = this.SchoolAddressval;
      let SchoolCity = this.SchoolCityval;
      let SchoolStateId = this.SchoolStateIdval;
      let SchoolLGId = this.SchoolLGIdval;
      let SchoolName = this.SchoolNameval;
      let SchoolShortName = this.SchoolShortNameval;
      let SchoolPhoneNo = this.SchoolPhoneNoval;
      let SchoolEmailAdd = this.SchoolEmailAddval;
      let SchoolWebAdd = this.SchoolWebAddval;
      let SchoolLogo = this.SchoolLogoval;
      let SchoolStatusId = this.SchoolStatusIdval;
      let SchoolMessageHeader = this.SchoolMessageHeaderval;
      let BankId = this.BankIdval;
      let AccountName = this.AccountNameval;
      let AccountType = this.AccountTypeval;
      let AccountNo = this.AccountNoval;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      this.onloader = "flex";
      this.$store
        .dispatch("processschool", {
          Id,
          SchoolAddress,
          SchoolCity,
          SchoolStateId,
          SchoolName,
          SchoolShortName,
          SchoolPhoneNo,
          SchoolEmailAdd,
          SchoolWebAdd,
          SchoolLogo,
          SchoolStatusId,
          SchoolMessageHeader,
          BankId,
          AccountName,
          AccountType,
          AccountNo,
          AddressId,
          SchoolLGId,
          processop,
          username,
          accesskey,
        })
        .then((resp) => {
          this.schoolitems = resp;
          this.closeform();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getschool: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getschool")
        .then((resp) => {
          this.schoolitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    getlga: function () {
      this.lgaitems = [];
      let StateId = this.SchoolStateIdval;
      if (StateId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getlgas", StateId)
          .then((resp) => {
            this.lgaitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0];
      this.submitFile();
    },
    submitFile: function () {
      this.onloader = "flex";
      var form = new FormData();
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let formalurl = this.Passportval;
      let doctype = "image";
      form.append("username", username);
      form.append("accesskey", accesskey);
      form.append("formalurl", formalurl);
      form.append("doctype", doctype);
      form.append("file", this.file);
      this.$store
        .dispatch("uploadadminfile", form)
        .then((resp) => {
          this.SchoolLogoval = resp;
          this.uploadedimage = resp;
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Uploaded successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (
      this.$store.state.auth.user_status_id == 6 ||
      this.$store.state.auth.user_status_id == 7 ||
      this.$store.state.auth.user_status_id == 9 ||
       this.$store.state.auth.user_status_id == 11
    ) {
      this.getschool();
    } else {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    }
  },
};
</script>

<style>
</style>
