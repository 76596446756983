<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link
      >
      <router-link to="/hostel" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Hostel
      </router-link>
      <span class="text-white"
        ><i class="fa fa-ellipsis-v px-2"></i>Hostel Student Bed</span
      >
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-4">
              <div class="card bg-secondary">
                <h3 class=" card-header">Registration</h3>
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                      <div class="form-group">                      
                        <label>Student</label>
                        <input
                          v-model="searchStudentsval"
                          @keyup="openStudentsItem"
                          class="form-control"
                          placeholder="Search Here..."
                          required
                        />
                        <div
                          class="table-responsive mt-2"
                          v-if="IsStudentsItemOpen"
                        >
                          <table
                            class="table table-bordered table-striped table-hover"
                          >
                            <tbody>
                              <tr
                                v-for="item in searchedStudentResultQuery"
                                :key="item.Id"
                              >
                                <td>
                                  <button
                                    class="text-left btn btn-block"
                                    v-on:click.prevent="SelectStudent(item.Id)"
                                  >
                                    <div class="row">
                                      <div class="col">
                                        <img
                                          v-if="item.Passport"
                                          :src="
                                            $store.state.auth.baseurl +
                                              item.Passport.substr(1)
                                          "
                                          class="rounded-circle"
                                          alt=""
                                          height="30"
                                        />
                                        <img
                                          v-else
                                          src="img/brand/loguser.png"
                                          class=" rounded-circle"
                                          alt=""
                                          height="30"
                                        />
                                      </div>
                                      <div class="col">
                                        {{ item.StudentName }} <br />
                                        ({{ item.AdmNo }})
                                      </div>
                                    </div>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Hostel</label>
                        <select
                          v-model="HostelIdval"
                          @change="GetHostelFloor()"
                          class="form-control"
                        >
                          <option value="0"></option>
                          <option
                            v-for="item in HostelItems"
                            :key="item.Id"
                            v-bind:value="item.Id"
                          >
                            {{ item.HostelName }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group" v-if="HostelIdval">
                        <label>Floor</label>
                        <select
                          v-model="FloorIdval"
                          @change="GetHostelRoom()"
                          class="form-control"
                        >
                          <option value="0"></option>
                          <option
                            v-for="item in HostelFloorItems"
                            :key="item.Id"
                            v-bind:value="item.Id"
                          >
                            {{ item.FloorName }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group" v-if="FloorIdval">
                        <label>Room Number</label>
                        <select
                          v-model="RoomIdval"
                          @change="GetHostelBed()"
                          class="form-control"
                        >
                          <option value="0"></option>
                          <option
                            v-for="item in HostelRoomItems"
                            :key="item.Id"
                            v-bind:value="item.Id"
                          >
                            {{ item.RoomNo }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group" v-if="RoomIdval">
                        <label>Bed No.</label>
                        <select v-model="BedIdval" class="form-control">
                          <option value="0"></option>
                          <option
                            v-for="item in HostelBedItems"
                            :key="item.Id"
                            v-bind:value="item.Id"
                          >
                            {{ item.BedNo }}
                          </option>
                        </select>
                      </div>
                      <div class="text-center card-footer mb-3" v-if="BedIdval">
                        <button type="submit" class="btn btn-primary my-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="justify-content-end form-inline mt-3">
                <base-input
                  class="input-group-alternative"
                  placeholder="Search here..."
                  addon-left-icon="fa fa-search"
                  v-model="searchval"
                >
                </base-input>
              </div>
              <div class="table-responsive mt-2">
                <table
                  class="table table-dark table-bordered table-striped table-hover"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Asdmimission Number</th>
                      <th>Student Name</th>
                      <th>Bed No.</th>
                      <th>Room No.</th>
                      <th>Floor Name</th>
                      <th>Hostel Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in searchedResultQuery"
                      :key="item.Id"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.AdmNo }}</td>
                      <td>{{ item.StudentName }}</td>
                      <td>{{ item.BedNo }}</td>
                      <td>{{ item.RoomNo }}</td>
                      <td>{{ item.FloorName }}</td>
                      <td>{{ item.HostelName }}</td>
                      <td>
                        <button
                          class="btn btn-danger btn-sm"
                          v-on:click="RemoveStudentHostelBed(item.Id)"
                        >
                          <i class="fa fa-times"></i> Remove
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import HostelMixin from "@/mixins/Hostel.mixin";
import HostelStudentBedMixin from "@/mixins/HostelStudentBed.mixin";
import PageSpinner from "@/components/PageSpinner";
export default {
  name: "HostelStudentBed",
  mixins: [HostelMixin, HostelStudentBedMixin],
  components: {
    PageSpinner
  },
  data() {
    return {
      Idval: 1,
      HostelIdval: 0,
      FloorIdval: 0,
      RoomIdval: "",
      BedIdval: 0,
      StudentIdval: 0,
      HostelItems: null,
      HostelFloorItems: null,
      HostelRoomItems: null,
      HostelBedItems: null,
      StudentItems: null,
      HostelStudentBedItems: null,
      IsStudentsItemOpen: false,
      searchStudentsval: "",
      searchval: "",
      process: "N",
      onloader: "none"
    };
  },
  computed: {
    searchedStudentResultQuery() {
      if (this.searchStudentsval) {
        return this.StudentItems.filter(item => {
          return this.searchStudentsval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.StudentName.toLowerCase().includes(v) ||
                item.AdmNo.toLowerCase().includes(v)
            );
        });
      } else {
        return this.StudentItems;
      }
    },
    searchedResultQuery() {
      if (this.searchval) {
        return this.HostelStudentBedItems.filter(item => {
          return this.searchval
            .toLowerCase()
            .split(" ")
            .every(
              v =>
                item.HostelName.toLowerCase().includes(v) ||
                item.FloorName.toLowerCase().includes(v) ||
                item.RoomNo.toLowerCase().includes(v) ||
                item.BedNo.toLowerCase().includes(v) ||
                item.StudentName.toLowerCase().includes(v) ||
                item.AdmNo.toLowerCase().includes(v)
            );
        });
      } else {
        return this.HostelStudentBedItems;
      }
    }
  },
  methods: {
    openStudentsItem() {
      this.IsStudentsItemOpen = false;
      if (this.searchStudentsval) {
        this.IsStudentsItemOpen = true;
      }
    },
    SelectStudent(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("getstudentbyid", Id)
        .then(resp => {         
          this.StudentIdval = Id;
          this.searchStudentsval = resp.StudentName;
          this.IsStudentsItemOpen = false;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    RemoveStudentHostelBed(Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("hostelStudentBed/RemoveStudentHostelBed", Id)
        .then(resp => {
          this.HostelStudentBedItems = resp;
          this.GetStudentsNotInHostel();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Removed Successfully",
            icon: "success",
            button: "Ok"
          });
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata() {
      let Id = this.Idval;
      let StudentId = this.StudentIdval;
      let BedId = this.BedIdval;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        StudentId,
        BedId,
        processop,
        username,
        accesskey
      };
      this.onloader = "flex";
      this.$store
        .dispatch("hostelStudentBed/ProcessHostelStudentBed", userdata)
        .then(resp => {
          this.HostelStudentBedItems = resp;
          this.StudentIdval = 0;
          this.HostelIdval = 0;
          this.HostelFloorItems = null;
          this.HostelRoomItems = null;
          this.HostelBedItems = null;
          this.process = "N";
          this.GetStudentsNotInHostel();
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok"
          });
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    GetHostelFloor() {
      this.HostelBedItems = null;
      this.onloader = "flex";
      this.$store
        .dispatch("hostelFloor/GetHostelFloor", this.HostelIdval)
        .then(resp => {
          this.HostelFloorItems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    GetHostelRoom() {
      this.HostelBedItems = null;
      this.onloader = "flex";
      this.$store
        .dispatch("hostelRoom/GetHostelRoom", this.FloorIdval)
        .then(resp => {
          this.HostelRoomItems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    GetHostelBed() {
      this.onloader = "flex";
      this.$store
        .dispatch("hostelBed/GetHostelBed", this.RoomIdval)
        .then(resp => {
          this.HostelBedItems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    GetStudentsNotInHostel() {
      this.onloader = "flex";
      this.$store
        .dispatch("GetStudentsNotInHostel")
        .then(resp => {          
          this.StudentItems = resp;
          this.onloader = "none";
        })
        .catch(err => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    checkresponse(resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok"
        });
      }
    }
  },
  mounted() {
    if (this.$store.state.auth.user_status_id != 1) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    } else {
      this.GetStudentsNotInHostel();
    }
  }
};
</script>

<style></style>
