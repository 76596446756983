<template>
<div>    
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <!-- Card stats -->
        <div class="pb-6">
            <div class="row">
                <div class="col-md-8">
                    <h4 class="text-white">Welcome {{ userName }}!</h4>
                    <div>
                        <small class="text-white"><i class="fa fa-phone"></i> {{userPhoneNo}} || <i class="fa fa-envelope"></i> {{userEmailAddress}}</small>
                    </div>
                </div>

            </div>

        </div>

    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
        <div class="row">
            <div class="col-xl-8 mb-5 mb-xl-0">
                <card type="default" header-classes="bg-transparent">
                    <Adminmenu> </Adminmenu>
                </card>
            </div>

            <div class="col-xl-4">
                <card header-classes="bg-transparent">
                    <div slot="header" class="row align-items-center">
                        <div class="col">
                            <h6 class="text-uppercase text-muted ls-1 mb-1">Daily News</h6>
                        </div>
                    </div>

                </card>
            </div>
        </div>

        <!-- End charts-->

    </div>

</div>
</template>

<script>
import {

} from 'vuex'
import Adminmenu from '../Dashboardmenu/Adminmenu.vue';
export default {
    components: {
        Adminmenu
    },
   props:{
       userName : String,
       userPhoneNo : String,
       userEmailAddress : String
   },
  
};
</script>

<style></style>
