<template>
<div>
    <div id="loaderoverlay" v-bind:style={display:onloader}>
        <div class="w-100 d-flex justify-content-center align-items-center">
            <div class="loaderspinner"></div>
        </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <router-link to="/dashboard" class="text-white pl-3">
          Dashboard</router-link>
        <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>CBT Exam</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
        <card header-classes="bg-transparent">           
            <div class="row">
                <div class="col-md-4 col">
                    <button class="btn btn-secondary btn-sm my-2" v-on:click="opensubject(2)">
                        <div class="card bg-secondary shrink my-3">
                             <div class=" card-header">
                                 <h3 class="mt-2">JAMB</h3>
                            </div>
                            <div class="card-body text-center">
                                 <img src="img/brand/Jamb.jpg" alt="" class="img-fluid" />
                               
                            </div>
                        </div>
                    </button>
                </div>
                <div class="col-md-4 col">
                    <button class="btn btn-secondary btn-sm my-2" v-on:click="opensubject(1)">
                        <div class="card bg-secondary shrink my-3">
                            <div class=" card-header">
                                 <h3 class="mt-2">WAEC</h3>
                            </div>
                            <div class="card-body text-center">
                                 <img src="img/brand/Waec.jpg" alt="" class="img-fluid" />
                              
                            </div>
                        </div>
                    </button>
                </div>
                <div class="col-md-4 col">
                    <button class="btn btn-secondary btn-sm my-2" v-on:click="opensubject(3)">
                        <div class="card bg-secondary shrink my-3">
                            <div class=" card-header">
                                <h3 class="mt-2">NECO</h3>
                            </div>
                            <div class="card-body text-center">
                                 <img src="img/brand/Neco.jpg" alt="" class="img-fluid" />
                                
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </card>
    </div>

</div>
</template>

<script>
import swal from 'sweetalert'
export default {
    name: 'cbtexam',
    data() {
        return {
            Idval: 1,
            examitems: '',
            searchval: '',
            process: 'N',
            onloader: 'none'
        }
    },
    methods: {

        opensubject: function (Id) {
            localStorage.setItem('ExamId', Id);
            this.$router.push('/studentcbtsubject');
        },
        
       
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }
    },
    mounted() {
        if (this.$store.state.auth.user_status_id != 2) {
             this.$store.dispatch('logout')
            this.$router.push('/login')
        } 
    }
};
</script>

<style></style>
