<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <router-link to="/dashboard" class="text-white pl-3">
        Dashboard</router-link>
      <router-link to="/finance" class="text-white">
        <i class="fa fa-ellipsis-v px-2"></i>Finance
      </router-link>
      <span class="text-white"> <i class="fa fa-ellipsis-v px-2"></i> Outcome Payment Payment</span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="mb-5 mb-xl-0">
        <card header-classes="bg-transparent">
          <div class="row">
            <div class="col-md-6">
              <div class="card bg-secondary border-0 mb-0">
                <div class="card-body">
                  <div class="form-horizontal">
                    <form role="form" @submit.prevent="submitdata">
                      <div class="card bg-gradient-success">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="text-white">Session</label>
                                <select v-model="SessionIdval" class="form-control" @change="getregterm()">
                                  <option value=""></option>
                                  <option v-for="item in Sessionitems" :key="item.Id" v-bind:value="item.Id">
                                    {{ item.SchSession }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="SessionIdval">
                              <div class="form-group">
                                <label class="text-white">Term</label>
                                <select v-model="TermIdval" class="form-control" @change="GetOutcomePayment()">
                                  <option value="0"></option>
                                  <option v-for="item in termitems" :key="item.Id" v-bind:value="item.Id">
                                    {{ item.SchTerm }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="TermIdval">
                              <div class="form-group">
                                <label class="text-white">Amount Paid</label>
                                <input v-model="AmountPaidval" class="form-control text-right" required />
                              </div>
                            </div>
                            <div class="col-md-6" v-if="AmountPaidval">
                              <div class="form-group">
                                <label class="text-white mr-4">Payment By</label>
                                <input v-model="PayToval" class="form-control" required />
                              </div>
                            </div>
                            <div class="col-md-6" v-if="PayToval">
                              <div class="form-group">
                                <label class="text-white mr-4">Payment Date</label>
                                <input v-model="PaymentDateval" class="form-control" type="date" required />
                              </div>
                            </div>
                            <div class="col-md-6" v-if="PaymentDateval">
                              <div class="form-group">
                                <label class="text-white mr-4">Naration</label>
                                <textarea v-model="Narationval" cols="30" rows="10" class="form-control"
                                  required></textarea>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center mb-3" v-if="Narationval">
                        <button type="submit" class="btn btn-primary my-4">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6" v-if="resultItems">
              <div class="table-responsive mt-2">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Amount Paid</th>
                      <th>Date</th>
                      <th>Paymnt By</th>
                      <th>Naration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in resultItems" :key="item.Id">
                      <td>{{ item.AmountPaid }}</td>
                      <td>{{ item.PaymentDate | formatDate }}</td>
                      <td>{{ item.PayTo }}</td>
                      <td>{{ item.Naration }}</td>
                      <td>
                        <button class="btn btn-secondary btn-sm" v-on:click="edititem(item.Id)">
                          <i class="fa fa-edit"></i> Edit
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import swal from "sweetalert"
import PageSpinner from "@/components/PageSpinner"
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin"
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin"
export default {
  name: "OutcomePayment",
  mixins: [CheckApiResponse, CheckStaffUser],
  components: {
    PageSpinner
  },
  data() {
    return {
      Idval: 1,
      SessionIdval: 0,
      TermIdval: 0,
      AmountPaidval: "",
      PaymentDateval: "",
      PayToval: '',
      Narationval: '',
      Sessionitems: null,
      termitems: null,
      resultItems: null,
      process: "N",
      onloader: "none",
    };
  },

  methods: {
    edititem: function (Id) {
      this.onloader = "flex";
      this.$store
        .dispatch("GetOutcomePaymentById", Id)
        .then((resp) => {
          this.Idval = Id;
          this.PayToval = resp.PayTo;
          this.Narationval = resp.Naration;
          this.SessionIdval = resp.SessionId;
          this.getregterm();
          this.TermIdval = resp.TermId;
          this.AmountPaidval = resp.AmountPaid;
          this.PaymentDateval = this.frontEndDateFormat(resp.PaymentDate);
          this.process = "U";
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    submitdata: function () {
      let Id = this.Idval;
      let PayTo = this.PayToval;
      let Naration = this.Narationval;
      let AmountPaid = this.AmountPaidval;
      let PaymentDate = this.PaymentDateval;
      let SessionId = this.SessionIdval;
      let TermId = this.TermIdval;
      let processop = this.process;
      let username = this.$store.state.auth.token;
      let accesskey = this.$store.state.auth.accesskey;
      let userdata = {
        Id,
        PayTo,
        Naration,
        AmountPaid,
        PaymentDate,
        SessionId,
        TermId,
        processop,
        username,
        accesskey,
      };
      this.onloader = "flex";
      this.$store
        .dispatch("ProcessOutcomePayment", userdata)
        .then((resp) => {
          this.resultItems = resp;
          this.AmountPaidval = "";
          this.PaymentDateval = "";
          this.process = "N";
          this.onloader = "none";
          swal({
            title: "Success!",
            text: "Submitted successfully",
            icon: "success",
            button: "Ok",
          });
        })
        .catch((err) => {
          this.onloader = "none";
          this.checkresponse(err.response.data);
        });
    },
    GetOutcomePayment: function () {
      this.resultItems = null;
      if (this.TermIdval) {
        let SessionId = this.SessionIdval;
        let TermId = this.TermIdval;
        let userdata = {
          SessionId,
          TermId,
        };
        this.onloader = "flex";
        this.$store
          .dispatch("GetOutcomePayment", userdata)
          .then((resp) => {
            this.resultItems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    getregsession: function () {
      this.termitems = null;
      this.Sessionitems = null;
      this.SessionIdval = 0;
      this.onloader = "flex";
      this.$store
        .dispatch("getregsession")
        .then((resp) => {
          this.Sessionitems = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
          this.$store.dispatch("logout");
          this.$router.push("/home");
        });
    },
    getregterm: function () {
      this.termitems = null;
      this.TermIdval = 0;
      let SessionId = this.SessionIdval;
      if (SessionId > 0) {
        this.onloader = "flex";
        this.$store
          .dispatch("getregterm", SessionId)
          .then((resp) => {
            this.termitems = resp;
            this.onloader = "none";
          })
          .catch((err) => {
            this.onloader = "none";
            this.checkresponse(err.response.data);
          });
      }
    },
    frontEndDateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    checkresponse: function (resp) {
      if (resp === "Access Expired" || resp === "Invalid Credential") {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        swal({
          title: "Oops!",
          text: resp,
          icon: "error",
          button: "Ok",
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.auth.user_status_id != 1) {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    } else {
      this.getregsession();
    }
  },
};
</script>

<style>
</style>
