<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <card header-classes="bg-transparent">
            <div class="row">
                <div class="col-md-6">
                    <div class="card bg-secondary">
                         <div class="card-header">
                            <h4>Mid Term Record</h4>
                        </div>
                        <div class="card-body">
                                <div class="form-horizontal">
                                    <form role="form" @submit.prevent="submitdata">
                                        <div class="card bg-gradient-success">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Session</label>
                                                            <select v-model="SessionIdval" class="form-control" @change="getregterm()">
                                                                <option value=""></option>
                                                                <option v-for="item in Sessionitems" :key="item.Id" v-bind:value="item.Id">
                                                                    {{item.SchSession}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Term</label>
                                                            <select v-model="TermIdval" class="form-control" @change=" getclassinsubjectassigned()">
                                                                <option value="0"></option>
                                                                <option v-for="item in termitems" :key="item.Id" v-bind:value="item.Id">
                                                                    {{item.SchTerm}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Class</label>
                                                            <select v-model="ClassIdval" class="form-control" @change="getoptioninsubjectassigned()">
                                                                <option value="0"></option>
                                                                <option v-for="item in classitems" :key="item.Id" v-bind:value="item.Id">
                                                                    {{item.Sch_Class}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Option</label>
                                                            <select v-model="OptionIdval" class="form-control" @change="getarminsubjectassigned()">
                                                                <option value="0"></option>
                                                                <option v-for="item in optionitems" :key="item.Id" v-bind:value="item.Id">
                                                                    {{item.ClassOption}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Arm</label>
                                                            <select v-model="ArmIdval" class="form-control" @change="getsubjectinsubjectassigned()">
                                                                <option value="0"></option>
                                                                <option v-for="item in armitems" :key="item.Id" v-bind:value="item.Id">
                                                                    {{item.SectionName}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Subject</label>
                                                            <select v-model="SubjectIdval" class="form-control" @change="getmarkrecord()">
                                                                <option value="0"></option>
                                                                <option v-for="item in subjectitems" :key="item.Id" v-bind:value="item.Id">
                                                                    {{item.SchSubject}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Student</label>
                                                            <select v-model="StudIdval" class="form-control" @change="edititem()">
                                                                <option value="0"></option>
                                                                <option v-for="item in studentitems" :key="item.Id" v-bind:value="item.Id">
                                                                    {{item.StudentName}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Score</label>
                                                            <input type="number" min="0" v-model="Examval" class="form-control">
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center mb-3">
                                            <button type="submit" class="btn btn-primary my-4">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                    </div>
                </div>
                <div class="col-md-6">                       
                        <div class="table-responsive mt-2">
                            <table class="table table-bordered table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Admission Number</th>
                                        <th>Student Name</th>
                                        <th>Score</th>                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in markrecorditem" :key="item.Id">
                                        <td>{{ item.AdmNo }}</td>
                                        <td>{{ item.StudentName }}</td>
                                        <td>{{ item.Score }}</td>                                       
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
            </div>
        </card>
    </div>
</template>
<script>
import moment from 'moment'
import swal from 'sweetalert'
import PageSpinner from "@/components/PageSpinner"
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin"
export default {
    name: 'MarkRecord',
     mixins: [CheckApiResponse],
    components: {
        PageSpinner
    },
    data() {
        return {
            dataid: 1,
            ClassIdval: '',
            OptionIdval: '',
            ArmIdval: '',
            SessionIdval: '',
            TermIdval: '',
            StudIdval: '',
            SubjectIdval: '',
            CAval: '',
            Examval: '',
            Sessionitems: null,
            termitems: null,
            classitems: null,
            optionitems: null,
            armitems: null,
            subjectitems: null,
            studentitems: null,
            markrecorditem: null,
            process: 'N',
            onloader: 'none'
        }
    },
    methods: {
        edititem: function () {
            let Id = 1;
            let ClassId = this.ClassIdval;
            let OptionId = this.OptionIdval;
            let ArmId = this.ArmIdval;
            let StudId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let SubjectId = this.SubjectIdval;
            let processop = 'U';
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            this.Examval = '';
            let userdata = {
                Id,
                ClassId,
                OptionId,
                ArmId,
                SubjectId,
                StudId,
                SessionId,
                TermId,
                processop,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('getmarkrecordmidbyid', userdata)
                .then(resp => {
                    this.Examval = resp.Score;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        submitdata: function () {
            let Id = this.dataid;
            let ClassId = this.ClassIdval;
            let OptionId = this.OptionIdval;
            let ArmId = this.ArmIdval;
            let StudentId = this.StudIdval;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let SubjectId = this.SubjectIdval;
            let Exam = this.Examval;
            let CA = 1;
            let processop = this.process;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                Id,
                ClassId,
                OptionId,
                ArmId,
                SubjectId,
                StudentId,
                SessionId,
                TermId,
                Exam,
                CA,
                Comment,
                processop,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('markrecordmidregistration', userdata)
                .then((resp) => {
                    this.markrecorditem = resp;
                    this.onloader = 'none'
                    swal({
                        title: "Success!",
                        text: 'Submitted successfully',
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getmarkrecord: function () {
            let Id = 1;
            let ClassId = this.ClassIdval;
            let OptionId = this.OptionIdval;
            let ArmId = this.ArmIdval;
            let SubjectId = this.SubjectIdval;
            let StudId = 1;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let processop = this.process;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                Id,
                ClassId,
                OptionId,
                ArmId,
                SubjectId,
                StudId,
                SessionId,
                TermId,
                processop,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('getmarkrecordmid', userdata)
                .then((resp) => {
                    this.markrecorditem = resp;
                    this.getstudentbyarm();
                    this.onloader = 'none'
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getregsession: function () {
            this.studentitems = null;
            this.termitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.Sessionitems = null;
            this.subjectitems = null;
            this.markrecorditem = null;
            this.SessionIdval = 0;
            this.onloader = 'flex'
            this.$store.dispatch('getregsession')
                .then(resp => {
                    this.Sessionitems = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getregterm: function () {
            this.classitems = null;
            this.studentitems = null;
            this.termitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.markrecorditem = null;
            this.TermIdval = 0;
            let SessionId = this.SessionIdval;
            if (SessionId > 0) {
                this.onloader = 'flex'
                this.$store.dispatch('getregterm', SessionId)
                    .then(resp => {
                        this.termitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getclassinsubjectassigned: function () {
            this.classitems = null;
            this.studentitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.subjectitems = null;
            this.markrecorditem = null;
            this.ClassIdval = 0;
            this.onloader = 'flex'
            this.$store.dispatch('getclassinsubjectassigned')
                .then(resp => {
                    this.classitems = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getoptioninsubjectassigned: function () {
            this.studentitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.subjectitems = null;
            this.markrecorditem = null;
            this.OptionIdval = 0;
            this.onloader = 'flex'
            let ClassId = this.ClassIdval;
            if (ClassId > 0) {
                this.$store.dispatch('getoptioninsubjectassigned', ClassId)
                    .then(resp => {
                        this.optionitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getarminsubjectassigned: function () {
            this.studentitems = null;
            this.armitems = null;
            this.subjectitems = null;
            this.markrecorditem = null;
            this.ArmIdval = 0;
            this.onloader = 'flex'
            let OptionId = this.OptionIdval;
            if (OptionId > 0) {
                this.$store.dispatch('getarminsubjectassigned', OptionId)
                    .then(resp => {
                        this.armitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getsubjectinsubjectassigned: function () {
            this.studentitems = null;
            this.subjectitems = null;
            this.markrecorditem = null;
            this.SubjectIdval = 0;
            this.onloader = 'flex'
            let ArmId = this.ArmIdval;
            if (ArmId > 0) {
                this.$store.dispatch('getschsubjectinsubjectassigned', ArmId)
                    .then(resp => {
                        this.subjectitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getstudentbyarm: function () {
            this.studentitems = null;
            this.StudIdval = 0;
            let Id = 1;
            let ClassId = this.ClassIdval;
            let OptionId = this.OptionIdval;
            let ArmId = this.ArmIdval;
            let SubjectId = this.SubjectIdval;
            let StudId = 1;
            let SessionId = this.SessionIdval;
            let TermId = this.TermIdval;
            let processop = this.process;
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            if (ArmId > 0) {
                let userdata = {
                    Id,
                    ClassId,
                    OptionId,
                    ArmId,
                    SubjectId,
                    StudId,
                    SessionId,
                    TermId,
                    processop,
                    username,
                    accesskey
                }
                this.onloader = 'flex'
                this.$store.dispatch('getstudentbyarm', userdata)
                    .then(resp => {
                        this.studentitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        frontEndDateFormat: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
        backEndDateFormat: function (date) {
            return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },       

    },
    mounted() {
         this.getregsession();
    }
};
</script>

<style>
</style>
