<template>
<div>
    <div id="loaderoverlay" v-bind:style={display:onloader}>
        <div class="w-100 d-flex justify-content-center align-items-center">
            <div class="loaderspinner"></div>
        </div>
    </div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <router-link to="/dashboard" class="text-white pl-3">
            <i class="ni ni-tv-2"></i> Dashborad</router-link><i class="ni ni-bold-right text-white"></i>
        <router-link to="/schcbtmenu" class="text-white pl-3">
            <i class="ni ni-laptop"></i> CBT</router-link><i class="ni ni-bold-right text-white"></i>
        <router-link to="/tccbtheader" class="text-white">
            <i class="ni ni-badge"></i> CBT Question Header
        </router-link>
        <router-link to="/tccbtquestion" class="text-white">
            <i class="ni ni-badge"></i> CBT Question
        </router-link>
        <span class="text-white"><i class="ni ni-bold-right"></i>Share CBT Question </span>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
        <div class="mb-5 mb-xl-0">
            <card header-classes="bg-transparent">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card bg-secondary border-0 mb-0">
                            <div class="card-body">
                                <div class="form-horizontal">
                                    <form role="form" @submit.prevent="submitdata">
                                        <div class="card bg-gradient-success">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Session</label>
                                                            <select v-model="SessionIdval" class="form-control" @change="getclassinsubjectassigned()">
                                                                <option value=""></option>
                                                                <option v-for="item in Sessionitems" :key="item.Id" v-bind:value="item.Id">
                                                                    {{item.SchSession}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>                                                    
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Class</label>
                                                            <select v-model="ClassIdval" class="form-control" @change="getoptioninsubjectassigned()">
                                                                <option value="0"></option>
                                                                <option v-for="item in classitems" :key="item.Id" v-bind:value="item.Id">
                                                                    {{item.Sch_Class}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Option</label>
                                                            <select v-model="OptionIdval" class="form-control" @change="getarminsubjectassigned()">
                                                                <option value="0"></option>
                                                                <option v-for="item in optionitems" :key="item.Id" v-bind:value="item.Id">
                                                                    {{item.ClassOption}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Arm</label>
                                                            <select v-model="ArmIdval" class="form-control" @change="getsubjectinsubjectassigned()">
                                                                <option value="0"></option>
                                                                <option v-for="item in armitems" :key="item.Id" v-bind:value="item.Id">
                                                                    {{item.SectionName}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-white">Subject</label>
                                                            <select v-model="SubjectIdval" class="form-control" @change="getschquestionheader()">
                                                                <option value="0"></option>
                                                                <option v-for="item in subjectitems" :key="item.Id" v-bind:value="item.Id">
                                                                    {{item.SchSubject}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>                                       
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div slot="header" class="row align-items-center">
                            <h6 class="text-uppercase text-muted ls-1 mb-1">Registered Question</h6>
                        </div>
                        <div class="table-responsive mt-2">
                            <table class="table table-bordered table-striped table-hover">
                                <thead>
                                    <tr>
                                         <td></td>
                                        <td>Access Code</td>
                                        <td>No of Question to Display</td>
                                        <td>Caution Note</td>
                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in cbtheaderitem" :key="item.Id">
                                         <td><button class="btn btn-secondary btn-sm" v-on:click="submitdata(item.Id)"><i class="fa fa-eye"></i> Select</button>
                                        </td>
                                        <td>{{ item.PassCode }}</td>
                                        <td>{{ item.NoofQuestiontoDisplay }}</td>
                                        <td>
                                            <div v-html="item.CautionNote"></div>
                                        </td>
                                       
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </card>
        </div>

        <!-- End charts-->

    </div>

</div>
</template>

<script>
import moment from 'moment'
import swal from 'sweetalert'
export default {
    name: 'questionheader',
    data() {
        return {
            dataid: 1,
            QuestionIdval: localStorage.getItem('TcQuestionId') || '',
            ClassIdval:'',
            OptionIdval: '',
            ArmIdval: '',
            SessionIdval: '',           
            SubjectIdval: '',
            ExamPeriodval: '',
            CautionNoteval: '',
            NoofQuestiontoDisplayval: '',
            Sessionitems: null,
            termitems: null,
            classitems: null,
            optionitems: null,
            armitems: null,
            subjectitems: null,
            studentitems: null,
            cbtheaderitem: null,
            process: 'N',
            onloader: 'none'
        }
    },
    methods: {
        openquestion: function (Id) {
            localStorage.setItem('TccbtheaderId', Id);
            this.$router.push('/tccbtquestion');
        },   
       
        submitdata: function (Id) {
            let HeaderId = Id;
            let QuestionId = this.QuestionIdval;            
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                HeaderId,
                QuestionId,
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('sharetcquestion', userdata)
                .then(() => {                                    
                    this.onloader = 'none'
                    swal({
                        title: "Success!",
                        text: 'Shared successfully',
                        icon: "success",
                        button: "Ok",
                    });
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getschquestionheader: function () {
            let Id = 1;           
            let SubjectId = this.SubjectIdval;
            let SessionId = this.SessionIdval;                     
            let username = this.$store.state.auth.token;
            let accesskey = this.$store.state.auth.accesskey;
            let userdata = {
                Id,              
                SubjectId,               
                SessionId,                            
                username,
                accesskey
            }
            this.onloader = 'flex'
            this.$store.dispatch('gettcquestionheader', userdata)
                .then((resp) => {
                    this.cbtheaderitem = resp;
                    this.onloader = 'none'
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err.response.data);
                })
        },
        getregsession: function () {           
            this.optionitems = null;
            this.armitems = null;
            this.Sessionitems = null;
            this.subjectitems = null;
            this.cbtheaderitem = null;
            this.SessionIdval = 0;
            this.onloader = 'flex'
            this.$store.dispatch('getregsession')
                .then(resp => {
                    this.Sessionitems = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    console.log(err);

                    this.onloader = 'none'
                    this.$store.dispatch('logout')
                    this.$router.push('/home')
                })
        },
        getclassinsubjectassigned: function () {
            this.classitems = null;
            this.optionitems = null;
            this.armitems = null;
            this.subjectitems = null;
            this.cbtheaderitem = null;
            this.ClassIdval = 0;
            this.onloader = 'flex'
            this.$store.dispatch('getclassinsubjectassigned')
                .then(resp => {
                    this.classitems = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    console.log(err);
                    this.onloader = 'none'
                    this.$store.dispatch('logout')
                    this.$router.push('/home')
                })
        },
        getoptioninsubjectassigned: function () {
            this.optionitems = null;
            this.armitems = null;
            this.subjectitems = null;
            this.cbtheaderitem = null;
            this.OptionIdval = 0;
            this.onloader = 'flex'
            let ClassId =this.ClassIdval;
            if (ClassId > 0) {
                this.$store.dispatch('getoptioninsubjectassigned', ClassId)
                    .then(resp => {
                        this.optionitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getarminsubjectassigned: function () {
            this.armitems = null;
            this.subjectitems = null;
            this.cbtheaderitem = null;
            this.ArmIdval = 0;
            this.onloader = 'flex'
            let OptionId =this.OptionIdval;
            if (OptionId > 0) {
                this.$store.dispatch('getarminsubjectassigned', OptionId)
                    .then(resp => {
                        this.armitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        getsubjectinsubjectassigned: function () {
            this.subjectitems = null;
            this.cbtheaderitem = null;
            this.SubjectIdval = 0;
            this.onloader = 'flex'
            let ArmId =this.ArmIdval;
            if (ArmId > 0) {
                this.$store.dispatch('getschsubjectinsubjectassigned', ArmId)
                    .then(resp => {
                        this.subjectitems = resp;
                        this.onloader = 'none';
                    })
                    .catch(err => {
                        this.onloader = 'none'
                        this.checkresponse(err.response.data);
                    })
            }
        },
        frontEndDateFormat: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
        backEndDateFormat: function (date) {
            return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }

    },
    mounted() {
        if (this.$store.state.auth.user_status_id != 1) {
            this.$store.dispatch('logout')
            this.$router.push('/login')
        } else {
            this.getregsession();
        }

    }
};
</script>

<style></style>
