<template>
    <div>
        <PageSpinner :onloader="onloader" />
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <router-link to="/dashboard" class="text-white pl-3">
                Dashboard</router-link>
            <router-link to="/homework" class="text-white">
                <i class="fa fa-ellipsis-v px-2"></i>Home Work
            </router-link>
            <span class="text-white"><i class="fa fa-ellipsis-v px-2"></i>Home Works Answer</span>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">

            <div class="mb-5 mb-xl-0">
                <card header-classes="bg-transparent">
                    <div class="card bg-secondary border-0 mb-0">
                        <div class="card-body">
                            <div class="table-responsive mt-2">
                                <table class="table table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Student Name</th>
                                            <th>CA Score</th>
                                            <th>Exam Score</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in homeworanswerkitem" :key="item.Id">
                                            <td>{{ item.StudentName }}</td>
                                            <td>{{ item.UploadedDate | formatDate }}</td>
                                            <td><a :href="$store.state.auth.baseurl + item.DocPath.substr(1)"
                                                    target="_blank" class="btn btn-dark btn-sm"><i
                                                        class="fa fa-download"></i> Download </a> </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </card>
            </div>

            <!-- End charts-->

        </div>

    </div>
</template>

<script>
import moment from 'moment'
import swal from 'sweetalert'
import PageSpinner from "@/components/PageSpinner"
import CheckApiResponse from "@/mixins/CheckApiResponse.mixin"
import CheckStaffUser from "@/mixins/CheckStaffUser.mixin"
export default {
    name: 'homeworkAnswer',
    mixins: [CheckApiResponse, CheckStaffUser],
    components: {
        PageSpinner
    },
    data() {
        return {
            HomeworkIdval: localStorage.getItem('HomeworkId') || '',
            homeworanswerkitem: null,
            onloader: 'none'
        }
    },
    methods: {
        gethomeworkanswer: function () {
            this.onloader = 'flex';
            let HomeworkId = this.HomeworkIdval;
            this.$store.dispatch('gethomeworkanswer', HomeworkId)
                .then(resp => {
                    this.homeworanswerkitem = resp;
                    this.onloader = 'none';
                })
                .catch(err => {
                    this.onloader = 'none'
                    this.checkresponse(err);
                })
        },

        frontEndDateFormat: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
        backEndDateFormat: function (date) {
            return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        checkresponse: function (resp) {
            if (resp === "Access Expired" || resp === "Invalid Credential") {
                this.$store.dispatch('logout');
                this.$router.push('/login')
            } else {
                swal({
                    title: "Oops!",
                    text: resp,
                    icon: "error",
                    button: "Ok",
                });
            }
        }

    },
    mounted() {
        this.gethomeworkanswer();
    }
};
</script>

<style>
</style>
