export default {
    methods: {
        GetuserBySchool: function() {
            this.onloader = "flex";
            this.$store
              .dispatch("GetuserBySchool")
              .then(resp => {
                this.SchoolUsersItems = resp;
                this.onloader = "none";
              })
              .catch(err => {
                this.onloader = "none";
                this.checkresponse(err.response.data);
              });
          },
    },
    mounted () {
      this.GetuserBySchool()
    }
  }