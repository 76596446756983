<template>
<div class="flex-column-reverse">

    <div id="loaderoverlay" v-bind:style={display:onloader}>
        <div class="w-100 d-flex justify-content-center align-items-center">
            <div class="loaderspinner"></div>
        </div>
    </div>
    <div class="card bg-secondary shadow border-0">
        <div class="card-body p-3">
            <div class="text-center text-muted my-3">
                <router-link to="/login" class="nav-link"> <img src="img/brand/green.png" height="30" /></router-link>
            </div>
            <div v-html="privacypolicy"></div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapState
} from 'vuex'
export default {
    name: 'privacy',
    data() {
        return {
            onloader: 'none'
        }
    },
    computed: {
        ...mapState({
            privacypolicy: state => state.appdatas.privacypolicy
        })
    },
}
</script>

<style>
</style>
