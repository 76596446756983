<template>
  <div>   
    <div class="row" v-if="news">
        <div class="col-md-3">
             <router-link to="/home" class="btn btn-secondary mt-5">
        <i class="fa fa-arrow-left"></i> Back to Home
      </router-link>
        </div>
      <div class="col-md-3" v-for="item in news" :key="item.Id">
        <button
          class="btn btn-secondary"
          v-on:click="readnews(item.NewsHeading)"
        >
          <div>
            <img
              v-if="item.ImagePath"
              :src="$store.state.auth.baseurl + item.ImagePath.substr(1)"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="text-left">
            <h1>{{ item.NewsHeading }}</h1>
            <div v-html="item.Newspreview"></div>
          </div>
        </button>
      </div>
       <div class="col-md-3">
             <router-link to="/home" class="btn btn-secondary mt-5">
        <i class="fa fa-arrow-left"></i> Back to Home
      </router-link>
        </div>
    </div>
    <!-- Header -->
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      news: null,
    };
  },
  methods: {
    readnews: function (NewsHeading) {
      localStorage.setItem("NewsHeading", NewsHeading);
      this.$router.push("/readnews");
    },
    getnews: function () {
      this.onloader = "flex";
      this.$store
        .dispatch("getnewsforexternal")
        .then((resp) => {
          this.news = resp;
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err.response.data);
        });
    },
  },
  mounted() {
    this.getnews();
  },
};
</script>

<style>
.newsbody {
  height: 1000px;
  overflow: auto;
}
.newsrow {
  border-left: 6px solid white;
}
.module-footer {
  height: 80px;
}
</style>
